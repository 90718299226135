import React from 'react';
import LoadingIndicator from '../loading/loadingIndicator';

export function Loading({ label, ...props }) {
    return (
        <div {...props} className={props.className}>
            <LoadingIndicator />
            {label}
        </div>
    );
}

export const loading =
    ({ check, className, label } = {}) =>
    (target, key, descriptor) => {
        const original = descriptor.value;
        descriptor.value = function () {
            const result = check instanceof Function ? check(this.state, this.props) : check;
            if (!result) {
                return <Loading className={className} label={label} />;
            }

            return original.bind(this)();
        };
        return descriptor;
    };

export const loadingValue = (value, { check = value, className, label } = {}) => {
    const result = check instanceof Function ? check() : check;
    if (!result) {
        return <Loading className={className} label={label} />;
    }
    return value;
};

import T from 'prop-types';
import React, { Component } from 'react';

export class Form extends Component {
    static propTypes = {
        children: T.func.isRequired,
        values: T.object,
        validatons: T.objectOf(T.oneOfType([T.func, T.string, T.bool])),
        validationMessages: T.objectOf(T.oneOfType([T.func, T.node])),
        className: T.string,
        successClassName: T.string,
        action: T.func,
        testId: T.string,
    };
    static defaultProps = {
        values: {},
        validations: {},
        validationMessages: {},
    };

    state = { values: {}, changes: {}, validationErrorOverrides: {}, processing: false, success: undefined };

    _fields = {};
    fields = new Proxy(this, {
        get(target, name) {
            if (!(name in target._fields)) {
                target._fields[name] = {
                    get value() {
                        const value = target.state.values[name];
                        if (value !== undefined) return value;
                        return target.props.values[name] ?? '';
                    },
                    set value(value) {
                        target.setState((state) => ({
                            values: { ...state.values, [name]: value },
                            changes: { ...state.changes, [name]: true },
                            validationErrorOverrides: { ...state.validationErrorOverrides, [name]: undefined },
                        }));
                    },
                    get changed() {
                        return !!target.state.changes[name];
                    },
                    get valid() {
                        const override = target.state.validationErrorOverrides[name];
                        if (override !== undefined) return !!override;
                        return target.validate(name);
                    },
                    get validationError() {
                        const override = target.state.validationErrorOverrides[name];
                        if (override !== undefined) return override;
                        if (!this.changed || this.valid) return undefined;
                        return target.validationMessage(name);
                    },
                    set validationError(override) {
                        target.setState((state) => ({
                            validationErrorOverrides: { ...state.validationErrorOverrides, [name]: override },
                        }));
                    },
                    onChange(value) {
                        target.setState((state) => ({
                            values: { ...state.values, [name]: value },
                            changes: { ...state.changes, [name]: true },
                            validationErrorOverrides: { ...state.validationErrorOverrides, [name]: undefined },
                        }));
                    },
                };
            }

            return target._fields[name];
        },
    });

    validate(name) {
        const validation = this.props.validations[name];
        const { value } = this.fields[name];

        switch (typeof validation) {
            case 'function':
                return !!validation(value, this.fields);
            case 'string':
                return !!value.match(validation);
            default:
                return !validation || !!value;
        }
    }

    validationMessage(name) {
        const validationMessage = this.props.validationMessages[name];
        const { value } = this.fields[name];

        switch (typeof validationMessage) {
            case 'function':
                return validationMessage(value, this.fields);
            default:
                return validationMessage;
        }
    }

    validateAll() {
        return !Object.keys(this.props.validations).find((name) => !this.fields[name].valid);
    }

    checkChanges() {
        for (const name in this.state.values) if (this.state.values[name] !== (this.props.values[name] ?? '')) return true;
        return false;
    }

    reset = () => {
        if (this._isMounted) this.setState({ values: {}, changes: {}, validationErrorOverrides: {}, success: undefined });
    };

    setError = (name, override) => {
        if (this._isMounted) this.fields[name].validationError = override;
    };

    setSuccess = (success) => {
        if (this._isMounted) this.setState({ success });
    };

    submit = async () => {
        try {
            const { action } = this.props;

            this.setState({ processing: true });
            await action?.(this.fields, {
                reset: this.reset,
                setError: this.setError,
                setSuccess: this.setSuccess,
            });
        } catch (e) {
            // ignore
        } finally {
            if (this._isMounted) this.setState({ processing: false });
        }
    };

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();

            if (this.validateAll() && !this.state.processing) this.submit();
        }
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const valid = this.validateAll();
        const changed = this.checkChanges();
        const { className, successClassName, testId } = this.props;
        const { processing, success } = this.state;

        return (
            <form data-testid={testId} className={className} onKeyDown={this.onKeyDown}>
                {success && <div className={successClassName}>{success}</div>}
                {this.props.children(this.fields, {
                    processing,
                    success,
                    valid,
                    changed,
                    submit: this.submit,
                    reset: this.reset,
                    setError: this.setError,
                    setSuccess: this.setSuccess,
                })}
            </form>
        );
    }
}

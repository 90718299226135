import genGuid from '@whiz-cart/node-shared/guid';
import { SYNC_UPDATE_ITEM, SYNC_REMOVE_FROM_QUEUE, SYNC_ADD_TO_QUEUE, SYNC_CLEAR, SYNC_INIT } from './sync';

const INITIAL_STATE = { items: {}, queue: [], deviceId: genGuid(), updatedOn: new Date(0).toISOString(), isInitialized: false };

export const syncReducer =
    (reducerName) =>
    (state = INITIAL_STATE, { type, name, payload }) => {
        if (name !== reducerName) return state;

        const mergeItem = (items, item) => ({ ...items, [item.id]: item.item });
        const mergeUpdatedOn = (updatedOn, item) => new Date(Math.max(new Date(updatedOn), new Date(item.updatedOn || 0))).toISOString();

        switch (type) {
            case SYNC_UPDATE_ITEM:
                return { ...state, items: mergeItem(state.items, payload), updatedOn: mergeUpdatedOn(state.updatedOn, payload) };

            case SYNC_ADD_TO_QUEUE:
                return { ...state, queue: [...state.queue, payload] };

            case SYNC_REMOVE_FROM_QUEUE:
                return { ...state, queue: state.queue.slice(1) };

            case SYNC_CLEAR:
                return INITIAL_STATE;

            case SYNC_INIT:
                return { ...state, isInitialized: true };

            default:
                return state;
        }
    };

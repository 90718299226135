import { z } from 'zod';

export type TargetIncludes = z.infer<typeof TargetIncludes>;
export type TargetExcludes = z.infer<typeof TargetExcludes>;
export type Target = z.infer<typeof Target>;

export const TargetIncludes = z.object({
    ims: z.number().array().nullish(),
    tenants: z.string().array().nullish(),
    branchGuids: z.string().array().nullish(),
    companyGuids: z.string().array().nullish(),
    storeGuids: z.string().array().nullish(),
});

export const TargetExcludes = z.object({
    excludedIms: z.string().array().nullish(),
    excludedTenants: z.string().array().nullish(),
    excludedBranchGuids: z.string().array().nullish(),
    excludedCompanyGuids: z.string().array().nullish(),
    excludedStoreGuids: z.string().array().nullish(),
});

export const Target = z.intersection(TargetIncludes, TargetExcludes);

import { CircularProgress } from '@mui/material';
import React, { HTMLProps, ReactNode } from 'react';
import css from './loadingIndicator.module.less';
import c from 'classnames';
import { getTranslations } from '../translate';

interface LoadingIndicatorProps extends Omit<HTMLProps<HTMLDivElement>, 'label' | 'size'> {
    hideSymbol?: boolean;
    center?: boolean | 'horizontal';
    label?: ReactNode | boolean;
    noMargin?: boolean;
    size?: number | string;
    thickness?: number;
    color?: string;
}

export default function LoadingIndicator({
    className,
    center = true,
    label,
    size,
    noMargin,
    thickness,
    color,
    ...divProps
}: LoadingIndicatorProps) {
    const showMargin = !noMargin;

    return (
        <div
            className={c(className, css.view, {
                [css.centerHorizontal]: center === true || center === 'horizontal',
                [css.centerVertical]: center === true,
                [css.showMargin]: showMargin,
            })}
            {...divProps}
        >
            <CircularProgress
                className={css.spinner}
                size={size ?? (label ? '0.8rem' : '2rem')}
                thickness={thickness ?? (label ? 5 : undefined)}
                style={{ color: `${color ?? ''}` }}
            />
            {label && <span className={css.label}>{label === true ? getTranslations().t('loading') : label}</span>}
        </div>
    );
}

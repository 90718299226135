import deepEquals from 'fast-deep-equal/es6/react';
import { useRef } from 'react';

export function useEqRef(...dependencies: any[]) {
    const depRef = useRef<any[]>();
    if (!depRef.current || !deepEquals(depRef.current, dependencies)) {
        depRef.current = dependencies;
    }

    return depRef.current;
}

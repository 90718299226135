import '@fontsource/fira-sans-condensed/400.css';
import '@fontsource/fira-sans/400.css';
import '@fontsource/fira-sans/500.css';
import '@fontsource/fira-sans/600.css';
import '@fontsource/fira-sans/700.css';
import '@fontsource/fira-sans/800.css';
import '@fontsource/fira-sans/900.css';
import '@formatjs/intl-durationformat/polyfill';
import '@whiz-cart/node-shared/models/helpers';
import { commodityGroupService } from '@whiz-cart/ui-shared/commodityGroups/commodityGroup.service';
import { priceExtension } from '@whiz-cart/ui-shared/extensions/priceExtension';
import { uiSharedConfig } from '@whiz-cart/ui-shared/uiSharedConfig';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import { useSelector } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import fetchCartLoginTokens from './cartLogin/fetchCartLoginTokens';
import checkoutMessageHandler from './checkout/checkout.messageHandler';
import afterLogin from './login/afterLogin';
import afterLogout from './login/afterLogout';
import './mhd/mhdSync';
import './push/push.service';
import { managePushSubscriptions } from './push/push.service';
import updateTitle from './reactions/updateTitle';
import scanner from './scanner/scanner';
import sessionMonitorMessageHandler from './sessionMonitor/sessionMonitor.messageHandler';
import sessionMonitorDetailsMessageHandler from './sessionMonitor/sessionMonitorDetails.messageHandler';
import store from './store';
import storeAutoSelect from './store/storeAutoSelect';
import updateNotification from './update/updateNotification';
import endpoint from './util/endpoint';

smoothscroll.polyfill();

urlService.connectUrlToStore(store);

priceExtension();

commodityGroupService.configure(async (storeGuid) => ({ items: await endpoint('storeManager.getCommodityGroups', { storeGuid }).get() }));

uiSharedConfig.set('storeGuidHook', () => () => useSelector((state: any) => state.url.params.storeGuid));

Object.assign(window, {
    findTestIds(testId: string) {
        document.querySelectorAll(`[data-testid${testId ? `*="${testId}"` : ''}]`).forEach((el) =>
            console.info(
                //
                el.getAttribute('data-testid'),
                el,
                el.textContent,
            ),
        );
    },
});

const disposables = [
    sessionMonitorMessageHandler(),
    sessionMonitorDetailsMessageHandler(),
    checkoutMessageHandler(),
    scanner(),
    storeAutoSelect(),
    afterLogin(),
    afterLogout(),
    updateNotification(),
    updateTitle(),
    fetchCartLoginTokens(),
    managePushSubscriptions(),
];

if (import.meta.hot) {
    import.meta.hot.dispose(() => {
        for (const dispose of disposables.flat()) dispose();
    });
    import.meta.hot.accept();
}

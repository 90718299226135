import _ from 'lodash';
import { Literal, Null, Partial as RPartial, Runtype, Union } from 'runtypes';
import z, { ZodEnum, ZodType, type ZodTypeDef } from 'zod';
import type { OtherString } from './otherString';

export type Optional<T extends { [key: string]: any }> = Partial<{ [K in keyof T]: T[K] | null }>;

export const Optional = <T extends { [key: string]: Runtype<any> }>(properties: T) => {
    const mapped = _.mapValues(properties, (x) => Union(x, Null)) as {
        [K in keyof T]: Union<[T[K], Literal<null>]>;
    };
    return RPartial(mapped);
};

declare module 'zod' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface ZodType<Output = any, Def extends ZodTypeDef = ZodTypeDef, Input = Output> {
        ndefault(defaultValue: NonNullable<Output>): ZodType<NonNullable<Output>>;
    }

    interface ZodEnum<T> {
        loose(): ZodType<T[number] | OtherString>;
    }
}

export function ndefault<T>(type: ZodType<T>, defaultValue: NonNullable<T>): ZodType<NonNullable<T>> {
    return type.nullish().transform((value) => value ?? defaultValue) as any;
}

ZodType.prototype.ndefault = function (this, defaultValue) {
    return ndefault(this, defaultValue);
};

export function loose<T extends [string, ...string[]]>(type: ZodEnum<T>): ZodType<T[number] | OtherString> {
    return type.or(z.string());
}

ZodEnum.prototype.loose = function (this) {
    return loose(this);
};

import { z } from 'zod';

export enum StoreStatus {
    isActive = 0,
    isInactive = 1,
    isCloded = 2,
}

export type Store = z.infer<typeof Store>;

export const Store = z.object({
    storeGuid: z.string(),
    name: z.string(),
    status: z.nativeEnum(StoreStatus),

    storeId: z.string().nullish(),
    externalStoreId: z.string().nullish(),
    storeDbName: z.string().nullish(),
    tenant: z.string().nullish(),
    address: z.string().nullish(),
    inventoryManagementSystem: z.number().nullish(),
    startsOn: z.string().nullish(),
    branch: z.string().nullish(),
    branchId: z.string().nullish(),
    deploymentGroup: z.string().nullish(),
    config: z
        .object({
            JUMPHOST_IP_ADDRESS: z.string().nullish(),
        })
        .nullish(),
    currency: z.string().nullish(),
    enabledClaims: z.string().array().catch([]),
    preferredLanguages: z.string().array().catch([]),

    esPremium: z.boolean().nullish(),
    expressShopping: z.boolean().nullish(),
    esLight: z.boolean().nullish(),
    hasSelfCheckout: z.boolean().nullish(),
    street: z.string().nullish(),
    city: z.string().nullish(),
});

export const UnfinishedStore = Store.omit({ name: true }).extend({
    name: z.string().nullish(),
});
export type UnfinishedStore = z.infer<typeof UnfinishedStore>;

export function getStoreLabel(store: Store): string {
    if (store.name) {
        if (store.externalStoreId) {
            return `${store.name} ${store.externalStoreId}`;
        }

        return store.name;
    }

    return store.storeGuid;
}

import { Connection, ConnectionStatus } from './connection';
import { bus_publish } from './bus_publish';
import { bus_subscribe } from './bus_subscribe';
import { bus_once } from './bus_once';
import { bus_request } from './bus_request';
import { bus_serve } from './bus_serve';
import { bus_subscribeStatus } from './bus_subscribeStatus';

export type BusDebug = (title: string, data?: unknown) => void;

export interface BusOptions {
    timeout: number;
    retry?: number;
    delay?: number;
    backoff?: boolean;
    maxDelay?: number;
    debug?: BusDebug;
    source?: string;
}

export default class Bus {
    static DEFAULT_TIMEOUT = 10000;
    static DEFAULT_DELAY = 10000;
    static DEFAULT_BACKOFF = true;
    static DEFAULT_MAX_DELAY = 60000;

    options: BusOptions = {
        timeout: Bus.DEFAULT_TIMEOUT,
        delay: Bus.DEFAULT_DELAY,
        backoff: Bus.DEFAULT_BACKOFF,
        maxDelay: Bus.DEFAULT_MAX_DELAY,
    };

    /** Published a message on the bus. */
    publish = bus_publish;

    /** Listens for messages on the bus. */
    subscribe = bus_subscribe;

    subscribeStatus = bus_subscribeStatus;

    /** Subscribes to a topic and cancels the subscription after the first message arrives. */
    once = bus_once;

    /** Send an event over the message bus for which you expect a direct response. Returns a promise that will be resolved with the response. */
    request = bus_request;

    /** Listens to events on the message bus that expect a direct response. The handler must take the request data and return some response data or a promise of the response data. */
    serve = bus_serve;

    protected channelIn?: Connection;
    protected channelOut?: Connection;
    protected publishBuffer = new Array<any>();
    protected subscriptions = new Set<{ start: () => void; stop: () => void }>();
    protected statusSubscriptions = new Set<(status: ConnectionStatus) => void>();
    protected myStatusSubscription?: () => void;

    constructor(channelIn?: Connection, channelOut = channelIn) {
        if (channelIn) this.updateChannel(channelIn, channelOut);
    }

    updateChannel(channelIn?: Connection, channelOut = channelIn) {
        if (this.channelIn) this.channelIn.quit();
        if (this.channelOut && this.channelOut !== this.channelIn) this.channelOut.quit();

        // Clean up old channel
        for (const subscription of this.subscriptions) subscription.stop();
        if (this.myStatusSubscription) this.myStatusSubscription();

        this.channelIn = channelIn;
        this.channelOut = channelOut;

        if (this.channelIn) {
            // Reestablish subscriptions
            for (const subscription of this.subscriptions) subscription.start();
            this.myStatusSubscription = this.channelIn.subscribeStatus((status) => {
                for (const callback of this.statusSubscriptions) callback(status);
            });
        }
        if (this.channelOut) {
            for (const message of this.publishBuffer) this.channelOut.publish(message.topic, message.payload);
            this.publishBuffer = [];
        }
    }

    quit() {
        this.updateChannel();
    }
}

import Bus, { BusDebug } from './bus';
import BaseModel from '../baseModel';
import genGuid from '../guid';

const isChunked = (data: any): data is AsyncIterable<any> & { chunksLength?: number } =>
    data && (data as AsyncIterable<any>)[Symbol.asyncIterator] !== undefined;

export function bus_publish(
    this: Bus,
    topic: string,
    data: any | Iterable<any> | AsyncIterable<any> = {},
    options: { debug?: BusDebug; source?: string } & Partial<Omit<BaseModel, 'data' | 'source'>> = {},
): string {
    if (isChunked(data)) {
        const guid = genGuid();
        const correlationGuid = options.correlationGuid || guid;

        (async () => {
            let chunk = 0;
            const chunksLength = data.chunksLength || options.chunksLength;
            for await (const chunkData of data) {
                this.publish(topic, chunkData, {
                    ...options,
                    guid: chunk === 0 ? guid : genGuid(),
                    correlationGuid,
                    isChunked: true,
                    chunk,
                    chunksLength,
                    isLastChunk: chunksLength !== undefined && chunk === chunksLength - 1,
                });
                chunk++;
            }
            if (chunksLength === undefined) {
                this.publish(topic, undefined, { ...options, correlationGuid, isChunked: true, chunk, isLastChunk: true });
            }
        })();

        return guid;
    }

    const {
        guid = genGuid(),
        correlationGuid = guid,
        ts = new Date().toISOString(),
        properties = {},
        debug = this.options.debug,
        source = this.options.source,
        ...rest
    } = options;

    if (source) properties.source = source;

    const message = {
        topic,
        payload: {
            ...rest,
            guid,
            correlationGuid,
            ts,
            data,
            properties: Object.keys(properties).length > 0 ? properties : undefined,
        },
    };

    debug?.(`Sending ${topic}`, {
        ...data,
        get baseModel() {
            return message.payload;
        },
    });
    if (this.channelOut) this.channelOut.publish(topic, message.payload);
    else this.publishBuffer.push(message);

    return guid;
}

export class CancelError extends Error {
    isCanceled = true;

    constructor() {
        super('CPromise was canceled');
    }
}

type Value<T> = T | PromiseLike<T>;
type Resolve<T> = (value: Value<T>) => void;
type Reject = (reason?: any) => void;
type OnCancelHandler = () => void;
type OnCancel = (handler: OnCancelHandler) => () => void;
type Executor<T> = (resolve: Resolve<T>, reject: Reject, onCancel: OnCancel) => void;

export default class CPromise<T> extends Promise<T> {
    state: 'pending' | 'resolved' | 'rejected' | 'canceled' = 'pending';
    resolve: Resolve<T>;
    reject: Reject;
    private onCancelHandlers = new Set<OnCancelHandler>();

    constructor(fn?: Executor<T>);
    constructor(executor?: Executor<T>, resolve?: Resolve<T>, reject?: Reject) {
        super((_resolve, _reject) => {
            resolve = _resolve;
            reject = _reject;
        });

        this.resolve = (value) => {
            if (this.state !== 'pending') return;
            this.state = 'resolved';
            resolve?.(value);
        };
        this.reject = (reason) => {
            if (this.state !== 'pending') return;
            this.state = reason instanceof CancelError ? 'canceled' : 'rejected';
            reject?.(reason);
        };

        if (executor) {
            executor(this.resolve, this.reject, (handler) => {
                this.onCancelHandlers.add(handler);
                return () => this.onCancelHandlers.delete(handler);
            });
        }
    }

    cancel() {
        if (this.state !== 'pending') return;

        for (const handler of this.onCancelHandlers) handler();
        this.reject(new CancelError());
    }

    get isCanceled() {
        return this.state === 'canceled';
    }
}

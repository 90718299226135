import CPromise from '@whiz-cart/node-shared/cPromise';
import { errorToString } from '@whiz-cart/node-shared/errorToString';
import { MaybePromise } from '@whiz-cart/node-shared/maybePromise';
import qs, { stringify } from 'qs';

export type RequestProps<Res, Req = any> = Omit<RequestInit, 'body'> & {
    url: string;
    accessToken?: string | null | false | ((refresh?: boolean) => MaybePromise<string | null | false>);
    key?: string;
    data?: Req;
    params?: Record<string, any>;
    paramsStringifyOptions?: qs.IStringifyOptions;
    headers?: Record<string, string>;
    parser?: 'json' | 'text' | 'formData' | 'blob' | 'arrayBuffer';
    isRetry?: boolean;
    transform?: (response: unknown) => Res;
    timeout?: number;
};

export class RequestError extends Error {
    static formatMessage(request: { method?: string; url: string }, response: Response, data?: unknown) {
        let message = `${request.method ?? 'GET'} ${request.url} failed with status ${response.status}`;

        if (response.headers.get('content-type')?.includes('text/html')) {
            return message;
        }

        if (typeof data === 'string') {
            message += `:\n${data}`;
        }

        if (typeof data === 'object' && data !== null) {
            const details = Object.entries(data)
                .map(([key, value]) => `${key}: ${typeof value === 'object' && value !== null ? JSON.stringify(value) : value}`)
                .join('\n');
            message += `:\n\n${details}\n`;
        }

        return message;
    }

    override name = 'RequestError';

    constructor(
        public readonly request: { method?: string; url: string },
        public readonly response: Response,
        public readonly data?: unknown,
    ) {
        super(RequestError.formatMessage(request, response, data));
    }
}

const rawRequest = async <Res, Req = any>(config: RequestProps<Res, Req>): Promise<Res> => {
    const { accessToken, key, data, params = {}, paramsStringifyOptions, ...otherConfig } = config;

    let url = config.url;
    if (Object.keys(params).length > 0) url += `?${stringify(params, paramsStringifyOptions)}`;

    const headers: Record<string, string> = { ...config.headers };

    const currentAccessToken = accessToken instanceof Function ? await accessToken() : accessToken;
    if (currentAccessToken === null) {
        throw Error('Not logged in!');
    }
    if (currentAccessToken) {
        headers.Authorization = `Bearer ${currentAccessToken}`;
    }

    if (key) headers['x-functions-key'] = key;

    let body;
    if (data !== undefined) {
        if (data instanceof FormData) {
            body = data;
        } else {
            body = JSON.stringify(data);
            headers['Content-Type'] = 'application/json';
        }
    }

    try {
        const response = await fetch(url, { ...otherConfig, headers, body });

        let data;
        try {
            data = await response[config.parser || 'text']();
            data = JSON.parse(data);
        } catch {
            // ignore
        }

        if (response.ok) return data as Res;
        else {
            throw new RequestError({ method: config.method, url: config.url }, response, data);
        }
    } catch (error: any) {
        if (accessToken instanceof Function && error.response?.status === 401 && !config.isRetry) {
            return rawRequest({ ...config, isRetry: true, accessToken: () => accessToken(true) });
        }

        if (error instanceof RequestError) {
            throw error;
        }

        error.message = `${config.method} ${url} failed (${errorToString(error)})`;
        throw error;
    }
};

export const request = <Res, Req = any>({ transform = (x) => x as Res, timeout, ...config }: RequestProps<Res, Req>): CPromise<Res> =>
    new CPromise<Res>((resolve, reject, onCancel) => {
        let timeoutId: ReturnType<typeof setTimeout>;
        const controller = new AbortController();

        onCancel(() => controller.abort());

        rawRequest<Res, Req>({ ...config, signal: controller.signal })
            .then(transform)
            .then(resolve)
            .catch(reject)
            .finally(() => {
                clearTimeout(timeoutId);
            });

        if (timeout) {
            timeoutId = setTimeout(() => controller.abort(), timeout);
        }
    });

request.get = <Res>(url: string, options?: Omit<RequestProps<Res, undefined>, 'url'>) => request({ method: 'GET', url, ...options });
request.post = <Res, Req = any>(url: string, data: Req, options?: Omit<RequestProps<Res, Req>, 'url'>) =>
    request({ method: 'POST', url, data, ...options });

import { Tooltip } from '@mui/material';
import React, { HTMLProps, useEffect, useState } from 'react';
import { getTranslations } from '../translate';

export interface CopyOnClickProps extends Omit<HTMLProps<HTMLDivElement>, 'value'> {
    value?: string;
    confirmTimeout?: number;
    testId?: string;
    showValueTooltip?: boolean;
}

export function CopyOnClick({
    value = '',
    confirmTimeout = 1000,
    testId,
    className,
    showValueTooltip = false,
    ...divProps
}: CopyOnClickProps) {
    const [confirm, setConfirm] = useState(false);
    const [active, setActive] = useState(false);

    async function copy(e: React.MouseEvent) {
        e.stopPropagation();
        e.preventDefault();

        try {
            await navigator.clipboard?.writeText(value);
            setConfirm(true);
        } catch (error) {
            console.error('Failed to copy:', error);
        }
    }

    useEffect(() => {
        const handle = setTimeout(() => setConfirm(false), confirmTimeout);
        return () => {
            clearTimeout(handle);
        };
    }, [confirm]);

    return (
        <Tooltip title={confirm ? (getTranslations().t('copied') ?? '') : value} open={confirm || (showValueTooltip && active)}>
            <div
                data-testid={testId}
                onPointerOver={() => setActive(true)}
                onPointerOut={() => setActive(false)}
                {...divProps}
                onClick={copy}
                className={className}
                css={{ cursor: 'copy' }}
            />
        </Tooltip>
    );
}

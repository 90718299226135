export default {
  add: "Add",
  assets: {
    cart: {
      battery: "Battery",
      defects: {
        active: "Available to customers",
        createDefect: "Create defect",
        deactivate: "Deactivate",
        noDefects: "No defects"
      },
      deployment: {
        debug: "Debug",
        redeploy: "Redeploy"
      },
      lastSeen: "Last seen"
    },
    chargingController: {
      charger: "Charger {index}",
      connected: "Connected",
      disconnected: "Disconnected",
      enabled: "Enabled",
      graph: {
        configure: "Configure",
        disconnected: "Disconnected",
        name: "Charger {name}",
        scheduleOverride: "Schedule override",
        shortCircuit: "Short circuit",
        toggleTimeout: "The charger did not respond in time ({timeout})",
        turnOff: "Turn off",
        turnOn: "Turn on"
      },
      health: "Health",
      off: "Off",
      on: "On",
      schedule: {
        edit: "Edit schedule",
        nextChange: "Next change: {value} at {time}",
        title: "Schedule"
      },
      shortCircuit: "Short circuit"
    },
    common: {
      lastSeen: "Last seen",
      openMonitoring: "Open monitoring"
    },
    details: {
      title: "Asset details for {name}"
    },
    noConnection: "No connection",
    propertiesTitle: "Properties",
    state: {
      error: "Error",
      notOk: "Not OK",
      ok: "OK",
      warning: "Warning"
    },
    stateTitle: "State",
    title: "Assets",
    type: {
      all: "All",
      cart: "Cart",
      chargingController: "Charging Controller",
      chargingOption: "Charging Option"
    }
  },
  autoCartSetup: {
    addNew: "Add new",
    cartId: "Cart ID",
    framenumber: "Framenumber",
    modifiedBy: "Modified By",
    modifiedOn: "Modified On",
    showOld: "Show outdated",
    title: "Scheduled Setups",
    validFrom: "Valid From",
    validTo: "Valid To"
  },
  back: "Back",
  barcode: {
    barcodeContent: "Barcode Content",
    barcodeType: "Barcode Type",
    bonSequence: "Bon Sequence",
    date: "Date",
    "else": "else",
    length: "Length",
    pfandbon: "Bottle Receipt",
    pfandbonType: "Bottle Receipt Type",
    reset: "Reset",
    storeId: "StoreID",
    workstationId: "WorkstationId"
  },
  barcodeClassification: {
    actions: {
      copyAndEdit: "Create copy and edit",
      "delete": "Delete",
      edit: "Edit",
      includeDeleted: "Include deleted"
    },
    addNew: "Add new",
    descriptions: {
      prefixChar: "some description text...",
      regex: "some description text...",
      type: "some description text..."
    },
    edit: {
      add: "Add {field}",
      addVerification: "Add verification",
      copyInfo: "You are editing a barcode classification from another scope. You can create a copy if you want to make an override for this store only",
      error: {
        greaterZero: "Must be greater than zero",
        invalidRegexp: "Invalid regular expression",
        required: "Required",
        targetNeedsToBeChanged: "Target needs to be changed"
      },
      regexpDebugger: {
        matches: "Matches",
        noMatches: "No matches",
        regexp: "RegExp",
        testString: "Test string",
        title: "RegExp debugger"
      },
      remove: "Remove {field}",
      removeVerification: "Remove verification",
      targetNeedsToBeChanged: "Target needs to be changed",
      title: "Edit Barcode Classification",
      titleNew: "New Barcode Classification"
    },
    exclude: "Exclude",
    fields: {
      createdBy: "Created by",
      createdOn: "Created on",
      deletedBy: "Deleted by",
      deletedOn: "Deleted on",
      description: "Description",
      flags: "Flags",
      id: "ID",
      length: "Length",
      modifiedBy: "Modified by",
      modifiedOn: "Modified on",
      prefixChar: "Prefix",
      regex: "Regex",
      relevance: "Relevance",
      target: "Target",
      type: "Type",
      validFrom: "Valid from",
      validTo: "Valid to",
      validity: "Validity",
      verification: {
        excludeFromPos: "Exclude from POS",
        localizationKey: "Localization Key",
        message: "Message",
        options: "Options",
        title: "Verification"
      }
    },
    flags: {
      allowOneScan: "Allow One Scan",
      disableAdd: "Disabled Add"
    },
    include: "Include",
    scanner: {
      classificationFailed: "Classification failed",
      debugMode: "Debug mode",
      debugModeEnabled: "Debug mode enabled",
      lastScannedCode: "Last scanned code",
      lastScannedFormat: "Last scanned format",
      noStoreGuid: "Please select a store before scanning - barcode classification requires a store association",
      productNoStoreGuid: "Please select a store to view product details."
    },
    target: {
      branchGuids: "Retail format",
      companyGuids: "Corporation",
      excludedStoreGuids: "Excluded Store",
      global: "Global",
      ims: "IMS",
      storeGuids: "Store",
      tenants: "Tenant"
    },
    tester: {
      classification: "Classification",
      code: "Barcode",
      description: "Enter a barcode to test the classifications",
      result: "Result",
      title: "Barcode Classification Tester"
    },
    types: {
      coupon: "Coupon",
      customerCard: "Customer Card",
      deposit: "Deposit",
      discountAbsolute: "Discount Absolute",
      discountPercentage: "Discount Percentage",
      employeeCard: "Employee Card",
      product: "Product"
    },
    verification: {
      options: {
        Barcode: "Barcode",
        Message: "Message",
        Product: "Product",
        Silent: "Silent"
      }
    }
  },
  barcodeScanner: {
    chromeResetCode: "Close Store Manager and rescan code found at \"Stage Now\". Please ensure you have your login data ready."
  },
  battery: {
    batteryLevel: {
      FULL: "Full",
      LOW: "Low",
      NORMAL: "Normal",
      UNKNOWN: "Unknown"
    },
    isChargerConnected: {
      "false": "Battery operation",
      "true": "Charger connected",
      unknown: "Unknown"
    },
    label: "Battery level",
    unknown: "Last known: ",
    unknownExplained: "Last data too old"
  },
  bottle: "Bottle",
  brandEditor: {
    copy: "Copy brand colors",
    createTitle: "Brand Editor: New brand",
    customerCards: {
      id: "Id",
      makePrimary: "Make primary",
      name: "Name",
      pointsText: "Points Text",
      pointsTextHelpMessage: "This defines the points text shown on the cart. Use '{points'} to insert the points amount.\nFor example: \"+'{points'} P\" will show as \"+100 P\" if the customer has 100 points.",
      pointsTextHelpTitle: "Points Text Help",
      primary: "Primary",
      primaryTooltip: "Primary card will be shown in areas where the customer is not signed in. E.g. on the welcome screen.",
      title: "Customer Cards"
    },
    editTitle: "Brand Editor: Edit {brand}",
    error: {
      duplicateId: "Id is already taken"
    },
    fontFamilyAlt: {
      "default": "Default",
      title: "Alternative Fonts"
    },
    general: {
      borderRadius: "Border radius (e.g. 5, 5px, 1rem, 1em, 10%)",
      title: "General settings"
    },
    headerDisplayTotal: {
      title: "Display total price in header"
    },
    headerUsePrimaryColor: {
      title: "Use primary color for header background"
    },
    icons: {
      arrowBack: "Arrow Back",
      arrowLeftTutorial: "Tutorial: Left Arrow",
      arrowRightTutorial: "Tutorial: Right Arrow",
      cart: "Cart",
      cartPlus: "Cart PLUS",
      door: "Exit",
      edit: "Edit",
      exit: "Exit",
      location: "Location",
      lockActive: "Lock Active",
      lockInactive: "Lock Inactive",
      navigate: "Navigate",
      navigation: "Navigation",
      payAtRegister: "Pay at Register",
      promotion: "Promotion",
      recipes: "Recipes",
      search: "Search",
      sosActive: "SOS Active",
      sosInactive: "SOS Inactive",
      startPayment: "Start Payment",
      startShopping: "Start Shopping",
      title: "Icons",
      user: "User"
    },
    id: {
      hint: "This id is used to reference a brand in config",
      title: "Id"
    },
    images: {
      background: "Background",
      cartBackground: "Background",
      loginTutorial: "Login Tutorial",
      loginTutorial01: "Login",
      loginTutorial02: "Add item",
      loginTutorial03: "Pay",
      logoBlack: "Black",
      logoColor: "Color",
      logoCompany: "Corporation",
      logoCustomerCard: "Customer Card",
      logoWhite: "White",
      logos: "Logos",
      none: "No image",
      title: "Images"
    },
    palette: {
      cartBackground: "Cart background",
      contrastText: "Contrast Text",
      customerCard: "Customer Card",
      dark: "Dark",
      discount: "Discount",
      free: "Free",
      light: "Light",
      main: "Main",
      navigationBackground: "Navigation background",
      primary: "Primary",
      secondary: "Secondary",
      title: "Color Palette"
    },
    paste: "Paste brand colors",
    preview: {
      title: "Preview"
    },
    priceLayoutAlt: {
      title: "Alternative price layout"
    },
    promotionsLayoutAlt: {
      title: "Alternative promotions layout"
    },
    table: {
      addNew: "Add new",
      confirmDelete: "Are you sure you want to delete the brand \"{brand}\"?",
      id: "Id",
      name: "Name",
      palette: "Color Palette"
    },
    title: "Brand Editor",
    validations: {
      borderRadiusPattern: "Border radius must be a number with an optional unit (px, rem, em, %)",
      borderRadiusPositive: "Border radius must be positive",
      duplicateCustomerCardId: "Customer card ID is already taken"
    }
  },
  cancel: "Cancel",
  capacity: "{capacity}s",
  cartGenerations: {
    addCartGenerations: "Add cart generation",
    columns: {
      actions: "Actions",
      productNumber: "Product number",
      rampWheels: "Ramp wheels",
      version: "Version"
    },
    title: "Cart generations"
  },
  cartList: {
    active: "Active",
    battery: "Battery",
    batteryLevel: "Battery level",
    lastOnline: "Last online",
    loading: "Charging",
    notReachable: "Not reachable",
    notReachableDate: "Date not reachable"
  },
  cartMap: {
    highlightCart: "Highlight Cart: ",
    mapNotAvailable: "Map not available",
    showInactive: "Show Inactive Carts",
    useNewData: "Use new data source"
  },
  cartRemoteSetup: {
    deploy: "Deploy",
    reboot: "Reboot",
    redeploy: "redeploy",
    regular: "regular",
    replace: "replace",
    submit: "Submit"
  },
  cartReplacements: {
    cartId: "Cart ID",
    framenumber: "Framenumber",
    market: "Store",
    repairDate: "Repair date",
    replacementFramenumber: "Replacement framenumber",
    ticketGuid: "Ticket Guid",
    ticketId: "Ticket ID"
  },
  cartRow: {
    exchange: "Swap",
    gpsDisabled: "GPS disabled",
    pending: "Appointment pending",
    pickUp: "Pick up",
    remote: "Remote maintenance",
    repairDate: "Repair",
    repairWeek: "Appointment follows",
    reported: "Reported",
    returnDate: "Delivery",
    returnWeek: "Appointment follows",
    scheduled: "Appointment set"
  },
  carts: {
    active: "Active",
    backToOverview: "Return to overview",
    battery: "Battery",
    batteryOperated: "Battery operated",
    batteryStatus: "Battery Status",
    cart: "Cart {cartId}",
    cartNumber: "All",
    carts: "Carts",
    charging: "Charging",
    confirm: "Delete Cart {cartId}. Are you sure?",
    "delete": "Delete Cart",
    deleted: "Cart {cartId} successfully deleted",
    empty: "No carts set up yet",
    enablePrettyLogs: "Enable pretty logs",
    inactive: "Inactive",
    jumpToGlobalCarts: "Go to global cart view",
    lastSeen: "Reachable",
    loading: "Loading carts...",
    maintenance: "Maintenance",
    notFound: "Cart not found",
    notReachable: "Not reachable",
    onlyOutsideASession: " ONLY outside a session",
    outOfService: "Out of Service",
    plannedFor: "planned from ",
    repaired: "Repaired",
    sort: "Change sorting",
    store: "Store",
    unknown: "Unknown"
  },
  cartsDefect: {
    CartTicketStatus: {
      Closed: "Closed",
      Delivered: "Delivered",
      Deployed: "Deployed",
      Dispatched: "Dispatched",
      Open: "Open",
      Pending: "Pending",
      PickupScheduled: "Pickup Scheduled",
      Processing: "Processing",
      Reminder: "Reminder",
      RepairInProgress: "Repair in Progress",
      RepairScheduled: "Repair Scheduled",
      Resolved: "Resolved",
      ReturnScheduled: "Return Scheduled",
      Returned: "Returned",
      WaitingOnCustomer: "Waiting on Customer",
      WaitingOnEdeka: "Waiting on Edeka",
      WaitingOnFaytech: "Waiting on Faytech",
      WaitingOnThirdParty: "WaitingOnThirdParty",
      WaitingOnTransport: "Waiting on Transport"
    },
    addReport: "Report",
    closeTicket: "Cancel defect",
    closeTicketDialog: "Do you really want to close the ticket?",
    comment: "Comment:",
    deactivate: "Deactivate?",
    error: {
      "50001": "Freshdesk ticket could not be created"
    },
    noDefects: "None yet",
    op1: "Charging status checked",
    op2: "Cart restarted",
    op3: "Magnetic plug / socket cleaned",
    op4: "Barcode scanner cleaned",
    openDefectExists: "A defect has already been reported for this cart.",
    openDefects: "Open defects",
    problemLocation: "Where's the problem?",
    reason: "Reason:",
    report: "Report defect:",
    report2: "Reported defects",
    reset: "Reset",
    resolvedDefects: "Fixed defects",
    title: "Have the following steps been taken?",
    topCategories: {
      "0": {
        subcategories: [
          "won't turn on",
          "automatically turns off",
          "no response during session",
          "only displays \"Charging...\"-screen",
          "only displays white icons on black background",
          "no items found in search",
          "sessions don't appear at checkout-phone",
          "cannot finalize payment transaction"
        ],
        title: "Cart/Einkauf"
      },
      "1": {
        subcategories: [
          "no touch-recognition in some areas",
          "no touch-recognition at all",
          "stays off even though scanner turns on",
          "white",
          "dark/clouded visibility",
          "flickers",
          "white frame/stripes",
          "lights up in colors",
          "cracked",
          "mount loose"
        ],
        title: "Display"
      },
      "2": {
        subcategories: [
          "light off/turns off",
          "damaged case",
          "beeps multiple times without reaction",
          "cannot scan products",
          "cannot log in"
        ],
        title: "Scanner"
      },
      "3": {
        subcategories: [
          "loose",
          "broken",
          "Plexiglas dented/missing",
          "wrong LED color"
        ],
        title: "Antenna"
      },
      "4": {
        subcategories: [
          "cart has a sideward pull",
          "damaged wheel",
          "loose/missing wheel",
          "black insertion aid damaged",
          "childseat loose/broken",
          "Front shield loose/broken",
          "top packing-area loose/broken",
          "bottom packing-area loose/broken"
        ],
        title: "Mechanics"
      },
      "5": {
        subcategories: [
          "charging port loose/broken",
          "on/off button loose/broken/missing",
          "cart won't charge/shows 'Battery empty'",
          "top camera broken/no pictures",
          "bottom camera broken/no pictures"
        ],
        title: "Electronics"
      },
      "6": {
        subcategories: [
          "front shield decal coming off/missing",
          "side decal coming off/missing",
          "packing-area decal coming off/missing",
          "paint damage/scratches"
        ],
        title: "Appearance"
      },
      antenna: {
        subcategories: {
          broken: "Broken",
          loose: "Loose",
          plexiglasDetendMissing: "Plexiglas dented/missing",
          wrongLedColor: "Wrong LED color"
        },
        title: "Antenna"
      },
      appearance: {
        subcategories: {
          cameraSymbolDamaged: "Camera/Barcode scanner symbol damaged",
          cartPaintDamaged: "Cart paintwork damaged/scratched",
          frontPanelStickerIssue: "Front panel sticker comes off/is missing",
          numberStickerIssue: "Number sticker (antenna) comes off/is missing",
          numberStickerMissing: "Number sticker is missing",
          sidePanelStickerIssue: "Side panel sticker comes off/is missing",
          storageSurfaceStickerIssue: "Storage surface sticker comes off/is missing"
        },
        title: "Appearance"
      },
      cart: {
        subcategories: {
          doesNotReactWhileShopping: "Does not react while shopping",
          noProductsFound: "Search doesn't find any products",
          notStarting: "Doesn't start",
          purchaseCantBeStopped: "Session cannot be stopped",
          sessionNotShownOnEasyPhone: "Session does not appear on the EASY Phone",
          showsLoadingScreen: "Only displays 'Loading...'-screen",
          showsWhiteSignsOnBlackBackground: "Only displays white signs on black background",
          turnsOff: "Turns off by itself"
        },
        title: "Cart/Purchase"
      },
      display: {
        subcategories: {
          bracketLoose: "Bracket is loose",
          crackedBroken: "Cracked/broken",
          darkPoorlyVisible: "Dark/poorly visible",
          flickers: "Flickers",
          shinesColorful: "Shines colorful",
          staysOffScannerWorks: "Stays off even though scanner works",
          touchNotWorking: "Without touch function",
          touchPartiallyNotWorking: "Touch does not work in some places",
          white: "White",
          whiteLines: "Has white frame/stripes"
        },
        title: "Display"
      },
      electronics: {
        subcategories: {
          ChargingPinsBroken: "Charging pins broken off/pressed in",
          LowerCameraDefect: "Lower camera defect/no pictures",
          UpperCameraDefect: "Upper camera defect/no pictures",
          cameraDefect: "Camera defect/no pictures",
          cartDisplaysBatteryEmpty: "Cart displays 'Battery empty'",
          cartNotCharging: "Cart does not charge",
          chargingSocketLooseDefect: "Charging socket loose/defect",
          onOffSwitchLooseDefectMissing: "On/off switch loose/defect/missing"
        },
        title: "Electronics"
      },
      mechanics: {
        subcategories: {
          LowerStorageAreaLooseDefect: "Lower storage area loose/defect",
          UpperStorageSurfaceLooseDefect: "Upper storage surface loose/defect",
          cartPullsToSide: "Cart pulls to one side",
          caseDamaged: "Case damaged",
          childSeatLooseDefect: "Child seat loose/defect",
          frontPlateLooseDefect: "Front plate loose/defect",
          handleDamaged: "Handle damaged",
          insertionAidDamaged: "Black insertion aid damaged",
          reelDamaged: "Reel damaged",
          reelLooseMissing: "Reel loose/missing",
          wanzlAttatchementDefect: "Attatchement to Wanzl Cart defect",
          wanzlAttatchmentDefect: "Attatchment to Wanzl Cart defect",
          wanzlCartDefect: "Wanzl Cart defect"
        },
        title: "Mechanics"
      },
      other: {
        title: "Other"
      },
      scanner: {
        subcategories: {
          beepsNoResponse: "Beeps several times without response",
          cannotScan: "Products cannot be scanned",
          caseDamaged: "Case damaged",
          loginNotPossible: "Login not possible",
          scannerOff: "Scanner does not light up"
        },
        title: "Scanner"
      }
    }
  },
  cartsInSetup: {
    availableForSetup: "Available for setup (ALL environments of this store)",
    columns: {
      battery: "Battery",
      currentClientVersion: "Client version",
      error: "Last error",
      errorTs: "Last error timestamp",
      frameNumber: "Frame number",
      ip: "IP",
      lastSeen: "Last seen",
      productNumber: "Product number",
      status: "Status",
      wifiSignalLevel: "Wifi signal"
    },
    error: {
      SETUP_ERROR_COULD_NOT_PARSE_HARDWARE_REVISION: "Could not parse hardware revision",
      SETUP_ERROR_COULD_NOT_TALK_TO_BOARD: "Could not talk to board",
      SETUP_ERROR_MISSING_FRAME_NUMBER: "Frame number missing",
      SETUP_ERROR_MISSING_PRODUCT_NUMBER: "Product number missing"
    },
    frameNumberMissing: "Frame number missing",
    log: {
      goToDeployment: "Go to deployment",
      isSetup: "Cart has been set up successfully!",
      title: "Cart Setup Logs"
    },
    notReadyForSetup: "Not ready for setup",
    productNumberMissing: "Product number missing",
    readyForSetup: "Ready for setup",
    revertInProgress: "Revert in progress",
    revertTitle: "Revert",
    step: {
      prepare: "Prepare",
      revert: "Revert",
      setup: "Setup"
    },
    title: "Carts in Setup"
  },
  cgLocalKey: {
    babykost: "Baby Food",
    brot_kuchen: "Bakery and Bread",
    brotaufstrich: "Spreads",
    diverse_nonfood: "Other",
    drogerieartikel: "Beauty and Wellness",
    feinkost: "Delicatessen",
    fette_eier: "Fats / Eggs",
    fisch: "Fish",
    freizeit: "Toys and Recreation",
    garten: "Garden",
    genussmittel: "Drink and Tobacco",
    getraenke: "Beverages",
    haushalt: "Household",
    hygiene: "Hygiene Products",
    kaese: "Cheese",
    kaffee_tee_kakao: "Coffee / Tea / Cocoa",
    kleidung: "Clothing",
    knabbereien: "Snacks",
    konserven: "Canned Goods",
    mehl_salz_zucker: "Flour / Salt / Sugar",
    molkereiprodukte: "Dairy",
    nahrungsmittel: "Foodstuff",
    obst_und_gemuese: "Produce",
    reinigungsmittel: "Detergents",
    speisezutaten: "Condiments",
    suesswaren: "Candy",
    tiefkuehlkost: "Frozen Foods",
    tierbedarf: "Pet Supplies",
    wuerzmittel: "Spices",
    wurst_fleisch: "Meats and Sausages"
  },
  checkout: {
    actions: {
      addBookmark: "Bookmark session",
      camera: "Camera",
      checkSessionVideo: "Request video URL/check upload status",
      checkVideoStatus: "Check video status",
      demoMode: "Demo mode",
      downloadSessionNotPossible: "No video available",
      downloadSessionVideo: "Download video",
      downloadSessionVideoRequest: "Request video url",
      emergencyPayment: "Emergency payment",
      emergencyPaymentMessage: "Please scan this code at the cart scanner to activate emergency payment",
      help: "Help",
      helpAtCart: "Self-checkout",
      helpAtRegister: "Pay at checkout",
      helpExpress: "Express shopping",
      loginCode: "Admin Code",
      markSuspicious: "Mark as suspicious",
      moreOptions: "More options",
      randomCheck: "Open Random Checks",
      removeBookmark: "Remove bookmark from session",
      removeBookmarkDialog: {
        message: "Removing this bookmark will result in the session video being deleted from the cloud. If the session is recent, it could still be present in a local store. Do you want to continue and potentially permanently delete the video?",
        no: "No, keep",
        title: "Remove bookmark? Video may be deleted",
        yes: "Yes, remove"
      },
      scanner: "Adjust Handscanner",
      scannerInfoText: "Scan each code in the right order to reconfigure your handheld scanner.",
      showTaken: "Show all",
      videoUploadStatus: {
        action: "Session video status",
        error: {
          noVideoFound: "No video found",
          noVideoWasRequested: "No video upload requested yet",
          videoDeleted: "Video was deleted"
        },
        request: "Request video upload",
        status: {
          failed: "Failed",
          noVideoFound: "No video found",
          noVideoWasRequested: "No video upload requested yet",
          pending: "Pending",
          success: "Success",
          videoDeleted: "Video was deleted"
        },
        title: "Video upload status"
      }
    },
    historic: {
      back: "Return to overview",
      captureDate: "Debit date",
      captureStatus: "Capture",
      cashier: "Cashier",
      creditCard: "Credit Card",
      debitDate: "Debit date",
      directDebit: "Direct debit",
      loginType: "Login type",
      mpos: {
        MPOS: "V1",
        MPOSv2: "V2",
        title: "MPOS",
        v1: "MPOS V1",
        v2: "MPOS V2"
      },
      noPayments: "No payments available",
      notFound: "Session not found",
      payment: "Payment",
      postProcessingBy: "Post-processed by",
      receiptNr: "Receipt no.",
      receiptNumber: "Receipt no.",
      title: "Previous payment"
    },
    history: {
      bookmark: "Bookmark",
      bookmarked: "Bookmarked",
      cart: "Cart Number",
      creditCard: "CreditCard",
      customerGuid: "Customer GUID",
      date: "Date",
      edekaAppPayment: "App-Payment: EDEKA",
      endTime: "Until",
      filter: "Filter",
      maxTotal: "Max",
      minTotal: "Min",
      noPayment: "Sessions without payment",
      parkingEan: "Parking EAN",
      paymentCredit: "App-Payment: CreditCard",
      paymentDebit: "App-Payment: DebitCard",
      qrPayments: "QR-Payments",
      receiptNo: "Receipt Number",
      reload: "Reload",
      resolveCustomerNotAllowed: "This feature is not active",
      resolveCustomerNotFound: "Customer not found",
      resolveSessionNotFound: "Session not found",
      resolvingScan: "Processing scan...",
      resolvingUnknownCode: "Unknown code",
      sessionGuid: "Session GUID",
      showGuids: "Show Guids",
      showPaymentCodes: "Show Payment Codes",
      startTime: "From",
      title: "Session history",
      unknownError: "Unknown Error: {error}"
    },
    overview: {
      noPayments: "No open payments",
      openOfflinePayments: "App Payment available",
      openOfflinePaymentsOld: "Offline EC-Payment",
      showFallback: "A payment does not appear?",
      showTaken: " Claimed payments are shown"
    },
    payment: {
      WeightWithoutScan: {
        beforeImage: "Before",
        currentImage: "Current",
        description: "Frequent weight changes without scan",
        reasons: {
          barcodeArticleAdded: "Barcode Article",
          customerNegated: "Customer Negated",
          other: "Other",
          personalItemAdded: "Personal Item",
          pluArticleAdded: "EASY Shopper Nr."
        },
        title: "Weight without scan"
      },
      alreadyCanceled: "Payment process canceled.",
      alreadyCompleted: "Payment process complete.",
      appPayment: "App Payment",
      backToOverview: "Return to overview",
      bookmarkedSession: "Bookmarked session",
      complete: "Finalize",
      completeCheck: "Complete Check",
      completePayment: "Carry out payment",
      completeVerifications: "The customer may now pay via app",
      creditCard: "Credit card",
      customerCard: "Customer reward card",
      customerCheckFailed: "Check failed",
      debitCard: "Debit card",
      details: "Details",
      doesNotExist: "Payment process not available.",
      easyCreditCard: "EASY Credit Card",
      easyCreditCardButtonHasBeenPressed: "EASY Credit Card pressed",
      easyDirectDebit: "EASY Direct Debit",
      easyDirectDebitButtonHasBeenPressed: "EASY Debit Card pressed",
      ecOfflineButtonHasBeenPressed: "Offline-EC pressed",
      employeeCard: "Employee discount card",
      error: {
        "0": "ok",
        "1": "Input {value} is not valid",
        "2": "Item {value} has already been scanned",
        "3": "Store was not found",
        "4": "Session was not found",
        "5": "Payment request was not found",
        "6": "Verification item was not found",
        "7": "Scanned code is not a product {value}",
        "8": "The item was processed by a different request"
      },
      express: "Express register",
      markedAsSuspicious: "Marked as suspicious",
      menu: {
        back: "Return to previous step",
        cancel: "Cancel payment",
        cancelFailedRandomCheck: "Continue payment and re-scan items at this checkout",
        cancelRandomCheck: "Cancel Random Check",
        details: "Details",
        moveDevice: "QR Code for 2. device",
        moveDeviceHide: "Hide QR Code for 2. device",
        moveDeviceShow: "Show QR Code for 2. device",
        release: "Release cart",
        setupScanner: "Setup scanner",
        switchToEAN: "Switch to restoration EAN",
        switchToQR: "Switch to QR codes"
      },
      noExpress: "Register not available",
      offlineAutoCloseHint: "After processing at the register the payment will be closed automatically",
      offlineCreditVerification: "This payment must be booked with \"EASY Credit-payment\" at the register(!)",
      offlineDebitVerification: "This payment must be booked with \"EASY Debit-payment\" at the register(!)",
      offlineEc: "Offline-EC",
      offlineEcVerification: "This payment must be booked with \"Offline EC-Payment\" at the register(!)",
      paymentCodes: "Payment Codes",
      posProcessing: "Register processes",
      processes: "Processes",
      products: "Products: {total}",
      scanAll: "Please scan all items in the cart and end the session through the admin menu.",
      scanEAN: "Scan barcode",
      scanQR: "Scan QR",
      sessionCode: "Session code",
      title: "Register",
      verification: {
        ImageDetectionUnscannedProduct: {
          message: "\"{className}\" recognized but not scanned. Please double-check.",
          title: "Unscanned product"
        },
        ageCheck: {
          details: "Age restricted item(s) {age, number} years:",
          message: "Does the customer meet the required minimum age?",
          title: "Minimum age"
        },
        bottleBundleCheck: {
          message: "{checkItemCount, plural, one {Please make sure the following product isn't a bundle:} other {Please make sure the following # products aren't bundles:}}",
          title: "Bottle/bundle check"
        },
        checkAgeRestrictionMagazine: {
          message: "Age-restricted content: Please check magazine(s)! "
        },
        checkForEmptyCrate: {
          message: "The customer has multiple of the following bottles but stated to have no crate. Please check for a crate.",
          title: "Check for crate"
        },
        collect: {
          message: "Please remember items for pick up: {itemNames}",
          title: "Item(s) for pick up"
        },
        collectCoupon: {
          message: "Collect coupon with EAN {couponEan}!",
          title: "Collect coupon"
        },
        collectCoupons: {
          message: "{count, plural, one {Collect one coupon} other {Collect # coupons}}"
        },
        collectLoyaltyCard: {
          message: "Collect loyalty points!"
        },
        deposit: {
          message: "Collect deposit coupon(s): ",
          title: "Deposit coupon"
        },
        details: "Details",
        discount: {
          message: "{count, number} Discount Scans",
          saved: "Customer saving: {total}",
          title: "Discount Scans"
        },
        employeeCardDisabled: {
          message: "Please scan the employee card at the register",
          title: "Employee card"
        },
        explosives: {
          message: "Explosives:",
          title: "Products for inspection"
        },
        express: {
          loading: "Loading pictures",
          message: "Select an express register to check basket pictures.",
          noPictures: "Disabled",
          title: "Select express register"
        },
        loyalty: {
          message: "Coupon booklets: {itemNames}",
          title: "Coupon booklets"
        },
        more: {
          title: "More"
        },
        multipleCodesToScan: {
          continueAtRegister: "Continue at register",
          continueAtSCO: "Continue at self checkout",
          message: "Multiple codes to scan!\n\nPlease make sure that all displayed codes are scanned.",
          title: "Multiple codes to scan"
        },
        notImplemented: "Unknown type of verification: {type}",
        oneCodeToScan: {
          message: "One code to scan!\n\nPlease make sure that the displayed code is scanned.",
          title: "One code to scan"
        },
        paymentExists: {
          bookAgain: "Book purchase again",
          closeSession: "Close session",
          label: "Payment Exists",
          title: "Payment has already been successfully booked"
        },
        posProcessing: {
          employeeCard: "Employee discount card",
          "mpos-unknown-product": "MPOS unknown item: {itemName} {price} (item id {rootItemId}, product category {commodityGroupId})",
          printPinForPhoneCardOrVoucher: "Print PIN for phone card or voucher",
          title: "Register processes"
        },
        randomCheck: {
          cameraOff: "Camera off",
          cameraOn: "Scan with camera",
          canceledMessage: "Random Check cancelled",
          cashFailure: "Cash Failure",
          choseReason: "Choose a reason: ",
          completeCheck: "Complete Check",
          completeCheckDescription: "When this feature is enabled, the payment process will skip directy to a message alerting the cashier to scan all the products by hand and close the session manually through the admin menu.",
          completeMessage: "Random Check complete",
          continueHere: "Continue on this device",
          customerFailed: "Random Check failed",
          customerFailedDescription: "At least one random check item was not found in the customer's virtual basket",
          customerFailedPartTwo: "Please send the customer to a regular checkout.",
          customerFailedSendToCheckout: "Please send the customer to a regular checkout",
          customerMark: "Should the customer's next purchase be checked?",
          customerSelectedHomogenous: "Customer has scanned non-homogenous!",
          customerSelectedHomogenous2: "Please correct the article selection at the register.",
          customerTraffic: "High customer traffic",
          eanPlu: "EAN/PLU",
          enableForExpressShopping: "Random check for express shopping",
          enableForPaymentAtCart: "Random check for pay-by-app",
          finished: "Finished! You may now go back to the checkout process.",
          ignored: "Ignored: ",
          ignoring: "“{ignoredScan}”",
          imageDetections: {
            doYouRecognizeClass: [
              "Do you recognize:",
              "{classificationClassName}",
              "in this image?"
            ]
          },
          message: "{count, plural, one {Please take # randomly chosen product from the Cart and scan it.} other {Please take # randomly chosen products from the Cart and scan them.}}",
          messagePlaceholder: "Please take a randomly chosen product from the Cart and scan it.",
          messageWithDetections: "{count, plural, one {Please take # product from the Cart and scan it. \nPay special attention to:} other {Please take # products from the Cart and scan them. \nPay special attention to:}}",
          modification: "Modification",
          noScanDevice: "No EASY Phone Scanner",
          scan: "Scan",
          scanAnItem: "Please scan an item...",
          scanQr: "Scan this QR Code with an EASY Phone to continue the random check on another device",
          scannedEnough: "You already scanned enough products.",
          showReplaced: "Not humogenous",
          siblings: "Customer's items with the same product category:",
          startMessage: "Start Random Check",
          technicalDifficulties: "Technical Difficulties",
          title: "Random Check",
          typeBarcode: "EAN/PLU",
          typeBarcodeMsg: "Please enter the EAN or PLU in the field.",
          typeIn: "EAN/PLU"
        },
        selfCheckoutOption: {
          message: "Please select the type of checkout",
          title: "Self-checkout/Cashier"
        },
        suspicious: {
          message: "Items removed before payment:",
          title: "Items removed"
        },
        unknownBarcode: {
          message: "{count, plural, one {Unknown Barcode} other {Unknown Barcodes}}",
          title: "Unknown Barcode"
        }
      }
    },
    tabs: {
      atCart: "App payment",
      atCartOld: "OFFLINE - EC (App)",
      atRegister: "At checkout",
      history: "History"
    },
    title: "Checkout"
  },
  clear: "Clear",
  clicktoCopy: "Click to copy",
  close: "Close",
  collapse: "Show less",
  config: {
    cart: "Cart",
    context: "Context",
    filters: "Filters",
    group: "Group",
    inventorySystem: "EBUS/RWWS",
    key: "Key",
    layer: "Layer",
    levels: {
      "0": "Cart",
      "1": "Store",
      "2": "Tenant",
      "3": "Global",
      "5": "Default value"
    },
    saveConfirmation: "Sure that the following changes should be saved?",
    service: "Service",
    store: "Store",
    target: "Target",
    tenant: "Tenant",
    toReset: "To reset: ",
    toUpdate: "To update: ",
    tooltipClear: "Remove config value",
    tooltipTarget: "Sync targets: {targets}",
    tooltipType: "Type: {type}",
    type: "Type",
    validation: {
      mustNotBeEmpty: "Must not be empty",
      numberNotInteger: "Must be integer",
      numberNotValid: "Must be valid number",
      numberTooLarge: "Must be at most {max}",
      numberTooSmall: "Must be at least {min}",
      stringDoesntMatchRegex: "Must match regex: {regex}",
      stringTooLong: "Must be at most {maxLength} characters long",
      stringTooShort: "Must be at least {minLength} characters long"
    }
  },
  confirm: "Confirm",
  "continue": "Continue",
  coordinateSelector: {
    pleaseSelect: "Please select a location",
    search: "Searching location"
  },
  copied: "Copied",
  customValue: "Custom value:",
  customer: {
    appVersion: "App Version",
    changeFlags: "Change Flags",
    customerHistory: {
      add: "added the label on",
      create: "created the label on",
      "delete": "deleted the label on",
      update: "updated the label on"
    },
    customerInfo: "Customer Information",
    customerNr: "Customer Number",
    device: "Device",
    infoLogin: "Type Login Hash",
    infoScan: "Scan user QR Code",
    lastSession: "Last Session",
    noPayment: "No payment methods available",
    notFound: "Could not find a customer with the Login Hash {hash}",
    or: "or",
    os: "OS",
    paidSessions: "Paid Sessions",
    payment: "Payment Methods",
    paymentCompletedOn: "Last Payment",
    search: "Search for a customer",
    userGuid: "UserGuid"
  },
  customerDetails: {
    customer: "Customer"
  },
  day: {
    friday: "Friday",
    monday: "Monday",
    saturday: "Saturday",
    sunday: "Sunday",
    thursday: "Thursday",
    tuesday: "Tuesday",
    wednesday: "Wednesday"
  },
  days: {
    friday: "Friday",
    monday: "Monday",
    saturday: "Saturday",
    "short": {
      friday: "Fr",
      monday: "Mo",
      saturday: "Sa",
      sunday: "Su",
      thursday: "Th",
      tuesday: "Tu",
      wednesday: "We"
    },
    sunday: "Sunday",
    thursday: "Thursday",
    tuesday: "Tuesday",
    wednesday: "Wednesday"
  },
  deals: {
    automatic: "The coupon is redeemed automatically when you purchase the product with the EASY Shopper.",
    noValidDeals: "No valid deals at the moment",
    notCombinable: "The coupon can be redeemed several times, but cannot be combined with other discount campaigns.",
    notCombinable2: "On sale only in common household quantities.",
    paybackDisclaimer: "PAYBACK Extra° points received through products will only be displayed on the receipt.",
    remainingDays: "{days, plural, one {# day left} other {# days left}}",
    remainingHours: "{hours, plural, one {# hour left} other {# hours left}}",
    valid: "Valid until {endDate}",
    validToday: "Only valid today"
  },
  defects: {
    columns: {
      cartId: "Cart ID",
      changedBy: "Changed By",
      createdOn: "Created On",
      extCreatedOn: "Ext. created On",
      extModifiedOn: "Ext. modified On",
      framenumber: "Framenumber",
      modifiedOn: "Modified On",
      note: "Note",
      priority: "Priority",
      reasons: "Reasons",
      repairDate: "Repair Date",
      returnDate: "Return Date",
      status: "Status"
    }
  },
  "delete": "Delete",
  deployment: {
    alreadyDeploymentGroupDefault: "{count, plural, one {Deployment Package is default for \"{deploymentGroup}\" } other {Deployment Package is default for \"{deploymentGroup}\" (and others)}}",
    alreadyGlobalDefault: "Deployment Package is Global default",
    alreadyStoreDefault: "Deployment Package is Store default",
    assignDeployment: "Assign deployment:",
    cartCreate: {
      advanced: "Advanced options",
      auto: "Auto",
      cartId: "Cart ID",
      deploy: "Deploy",
      fakeUwbTagVersion: "Fake UWB Tag Version",
      "for": "For",
      message: "Scan the Aztec code on the cart to carry out the setup:",
      reboot: "Reboot",
      setupType: "Setup Type",
      skipBatteryCheck: "Skip battery check",
      skipHardwareCheck: "Skip hardware check",
      skipSelfUpdate: "Skip self update",
      success: "Cart logged in"
    },
    columns: {
      cartGuid: "Cart GUID",
      cartIp: "Cart IP",
      cartMac: "Cart MAC",
      currentPackage: "Installed Package",
      deploymentAssigner: "Deployment Assigner",
      deploymentServiceVersion: "Deployment Service Version",
      desiredPackage: "Assigned Package",
      frameNumber: "Frame number",
      lastSeen: "Last contact",
      link: "Link",
      name: "Store/Cart/Group",
      status: "Status"
    },
    create: {
      askSelectBase: "Select another package as base?",
      basedOn: "Based on: {name}",
      createDeploymentPackage: "Create deployment package",
      dev: "DEV",
      failed: "An error has occurred:",
      promotedVersion: "Promoted version",
      selectBase: "Select another package as base",
      setAllTo: "Set all versions to {version}",
      setAsDeplyomentGroupDefault: "Set deployment package as default for a Deployment Group",
      setAsGlobalDefault: "Set deployment package as global default",
      setAsStoreDefault: "Set deployment package as store default",
      test: "TEST",
      title: "New deployment package",
      value: "Value",
      variable: "Variable"
    },
    createCart: "Create cart",
    createdPackages: "Created",
    deploy: {
      afterOpeningHours: "Closing time",
      assign: "Assign deployment: ",
      later: "Later",
      now: "Now",
      reset: "Are you sure you want to reset the Store default package?",
      resetButton: "Reset the Store default package",
      selectATime: "Select a time...",
      withoutSession: "Only outside a session"
    },
    deploymentGroupDefaultPackage: "{count, plural, one {Default package for deployment group: {deploymentGroups}} other {Default package for deployment groups: {deploymentGroups}}}",
    details: {
      copy: "New package base on this one",
      distribuition: "Distribuition: ",
      doesNotExist: "This deployment does not exist"
    },
    errors: "{errorCount, plural, one {# Error!} other {# Errors!}}",
    globalDefaultPackage: "Global default package",
    groups: {
      newGroup: "New Group",
      title: "Deployment Groups"
    },
    isDefaultPackage: "Default package",
    list: {
      create: "Create",
      name: "Name",
      noPackages: "No Deployment Packages yet.",
      template: "Template",
      title: "Deployment Packages"
    },
    loading: "Loading...",
    log: {
      cancelDeploy: "Cancel Redeployment",
      debug: "Debug",
      deploymentTime: "Set deployment times: ",
      noDeploymentAssigned: "No deployment assigned",
      rolledOut: "rolled out: {deploymentPackage}, requested: {desiredPackage}"
    },
    noGroup: "No Group",
    overview: {
      title: "Deployment Overview",
      titleStore: "{storeName} - Deployment Overview"
    },
    promoted: {
      create: {
        required: "Required",
        title: "Promote version"
      },
      deletedBy: "Deleted by",
      deletedOn: "Deleted on",
      details: {
        notPromoted: "This version is not promoted.",
        title: "Promoted version {version}"
      },
      list: {
        "delete": "Remove ",
        includeDeleted: "Include deleted?",
        title: "Promoted versions"
      },
      name: "Service",
      promotedBy: "Promoted by",
      promotedFor: "Environment",
      promotedOn: "Promoted on",
      promotedVersionGuid: "Guid",
      promotionReason: "Reason",
      validTo: "Valid until",
      version: "Version"
    },
    reDeploy: {
      onlyRepeatFailed: "Repeat only failed",
      redeploy: "Redeploy"
    },
    row: {
      deploymentCreated: "Deployment created",
      noContactSinceAssignment: "No contact since assignment"
    },
    setDefaultPackageTooltip: "Change default package",
    setGroupDialog: {
      group: "Deployment Group",
      submit: "Set as default",
      text: "The current deployment package will be used as the default for the following Deployment Group",
      title: "Choose Deployment Group"
    },
    setupType: {
      redeploy: "redeploy",
      regular: "regular",
      replace: "replace",
      testCart: "testCart"
    },
    storeDefaultPackage: "Store default package",
    technicalCodes: {
      openEasytest: "Open Easytest",
      revert: "Revert/Factory state",
      selfUpdate: "Self update",
      storeProxyIp: "Store Proxy IP",
      title: "Codes for setup service",
      wifiChange: "Wifi change"
    },
    tooltip: {
      allCarts: "All Carts",
      allFailedCarts: "All Failed Carts",
      deploy: "Deploy",
      redeploy: "Redeploy",
      schedule: "Schedule",
      transfer: "Transfer",
      transferDisabled: "Need to select individual carts"
    },
    validation: {
      storeProxyIp: "Please enter a valid IP address"
    }
  },
  deposit: "Deposit",
  dev: "DEV",
  deviceType: {
    cart: "Cart",
    express: "Express"
  },
  devops: {
    availableApt: "Available APT",
    availableDocker: "Available Docker",
    buildMonitor: "Build Monitor",
    cartDashboard: "Cart Dashboard",
    carts: "Carts",
    configs: "Configs",
    configsCompare: "ConfigsCompare",
    git: "Git",
    latestApt: "Latest APT",
    latestDocker: "Latest Docker",
    stores: "Stores",
    storesEmptySearch: "No store matched your search",
    table: {
      expectedVersion: "Expected",
      outdated: "Outdated",
      serviceName: "Service",
      serviceVersion: "Version",
      store: "Store",
      upToDate: "Up-to-date"
    }
  },
  disruptors: {
    actions: {
      "delete": "Delete disruptor",
      deleteConfirmation: "Are you sure you want to delete disruptor {id}?",
      editCorners: "Edit corners",
      editData: "Edit data",
      setImage: "Set image",
      waitForCorners: "Corners need to be set up first",
      waitForImage: "Image needs to be set up first"
    },
    configureDisruptors: "Configure disruptors",
    imageEditor: {
      title: "Set image for disruptor {id}"
    },
    title: "Disruptor Editor"
  },
  download: "Download",
  dsi: {
    actions: {
      additionalInfo: "Additional Information",
      annotating: "Annotating",
      exportPdf: "Export Report PDF",
      labelling: "Labelling",
      showImageClassifications: "Show image classifications",
      showImageDetections: "Highlight objects"
    },
    actualWeight: "Actual Weight",
    amount: "Amount",
    articles: "Articles",
    basketBundles: "Crates in basket",
    basketCigarettes: "Cigarettes in basket",
    basketFreshFoodCounter: "Fresh Food Counter product in basket",
    basketGenericCoffee: "Generic coffee in basket",
    basketJackDaniels: "Jack Daniels in basket",
    basketRedbulls: "Redbulls in basket",
    basketToffifee: "Toffifee in basket",
    bottleBundleCheck: {
      basketItemGuid: "Basket item GUID",
      bundleWeight: "Bundle weight",
      capacity: "Capacity",
      createdOn: "Created On",
      customerApproved: "Customer Approved",
      customerApprovedOn: "Customer Approved On",
      customerChanged: "User confirmed that the bundle is in the basket",
      customerChangedToBundle: "Customer Changed Basket to Potential Bundle",
      customerDidNotChange: "User confirmed that the bottle is in the basket",
      customerSelectedItemId: "Customer Selected Item ID",
      guid: "GUID",
      itemId: "Item ID",
      measuredWeight: "Measured Weight",
      notAvailable: "Not Available",
      potentialBundleDetails: "Potential Bundle Details",
      productWeight: "Product weight",
      title: "Bottle bundle check",
      weight: "Weight",
      weightChange: "Weight change"
    },
    bottleBundleCheckCount: "Bottle bundle checks",
    bottleBundleCheckDetails: "Bottle Bundle Check Details",
    bottomImagesCount: "# Bottom Images",
    bundle: "Has bundle probability",
    cart: "Cart",
    cartId: "Cart Id",
    cigarettes: "Cigarettes Probability",
    classificationDetectionThreshold: "Classification threshold",
    classificationsCount: "# Classifications",
    compareReceipt: {
      diff: "Difference receipt - EASY Shopper",
      diffAccountedFor: "Accounted for",
      diffRemaining: "Remaining",
      mark: "Possibly scanned a register",
      noDiff: "No difference between EASY Shopper and register",
      title: "Compare with receipt"
    },
    completeAnalysis: "Complete Analysis",
    customerGuid: "Customer Guid",
    demo: "Demo",
    diffProduct: "Different product",
    dsiAnalysis: "DSI Analysis",
    duration: "Duration",
    expectedWeight: "Expected Weight",
    filterPresets: {
      bundleIrregularity: "Irregularity Crate",
      button: "Filter presets",
      "default": "Default",
      label: "Label images"
    },
    filtersActive: "Active filters:",
    finish: {
      OK: "Ok",
      abandoned: "Abandoned Purchase",
      blackPicture: "Black picture / camera covered",
      bottomCam: "Bottom camera",
      brokenLogs: "Broken session logs",
      chooseOptionText: "Please choose one of the following options",
      comment: "Comment",
      coveredByBag: "Products covered by bag",
      coveredByChild: "Products covered by child",
      inquiry: "Inquiry",
      irregularity: "Irregularity",
      locked: "Locked",
      misaligned: "Camera angle misaligned",
      missingLogs: "Missing session logs",
      noPics: "No pictures",
      notEdited: "Not edited",
      notPlacedOnCart: "Products not placed on cart",
      notPlacedOnScale: "Products not placed on scale",
      notReviewableContent: "Not reviewable (contentwise)",
      notReviewableContentReason: {
        abandoned: "Abandoned Purchase",
        coveredByBag: "Products covered by bag",
        coveredByChild: "Products covered by child",
        notPlacedOnCart: "Products not placed on cart",
        notPlacedOnScale: "Products not placed on scale",
        testSession: "Test Session"
      },
      notReviewableTech: "Not reviewable (technically)",
      notReviewableTechReason: {
        blackPicture: "Black picture / camera covered",
        bottomCam: "Bottom camera",
        brokenLogs: "Broken session logs",
        misaligned: "Camera angle misaligned",
        missingLogs: "Missing session logs",
        noPics: "No pictures",
        quality: "Bad picture quality",
        reversed: "Reversed cameras (top - bottom)",
        topCam: "Top camera"
      },
      ok: "Ok",
      quality: "Bad picture quality",
      reversed: "Reversed cameras (top - bottom)",
      testSession: "Test Session",
      topCam: "Top camera"
    },
    freshFoodCounter: "Fresh Food Counter probability",
    genericCoffee: "Generic coffee probability",
    hasManualAnnotations: "Manual Annotations",
    help: {
      annotatingKeys: "Annotating keys",
      annotatingKeysWithoutLabelling: "Annotating keys (with labelling disabled)",
      classificationEmpty: "Set classification to Empty",
      classificationLoaded: "Set classification to Loaded",
      classificationUnsure: "Set classification to Unsure",
      detectionsAcceptAllRemaining: "Accept all remaining detections",
      editBundles: "Start editing bundle sizes",
      enter: [
        "During labelling: Cycle through bundle inputs",
        "In Finish screen: Go to next session"
      ],
      first: "Go to first event",
      firstSession: "Go to first session",
      generalKeys: "General keys",
      labellingKeys: "Labelling keys",
      last: "Go to last event",
      lastSession: "Go to last session",
      next: "Go to next event",
      nextSession: "Go to next session",
      previous: "Go to previous event",
      previousSession: "Go to previous session",
      ratingDetectionCorrect: "Rate detection as Correct",
      ratingDetectionIncorrect: "Rate detection as Incorrect",
      ratingFaulty: "Rate image as Faulty",
      ratingOk: "Rate image as OK",
      ratingUnsure: "Rate image as Unsure",
      selectNthClass: [
        "While no annotation selected: Add annotation with nth class",
        "While annotation selected: Change annotation class to nth class"
      ],
      spaceBar: "Space bar",
      title: "Hotkeys",
      toggleFaulty: "Faulty on/off"
    },
    imageClassBasketCounts: "Basket Counts",
    imageClassDetectionProbabilities: "Detection Probabilities",
    imagesCount: "# Images",
    inspect: {
      confirm: {
        other: "Other",
        productMissing: "Product is missing",
        productNotMissing: "Product is not missing",
        productScannedAtCashier: "Product was scanned at cashier"
      },
      detectionIsCorrect: "Detection is correct",
      detectionIsIncorrect: "Detection is incorrect",
      faultyImage: "Faulty image",
      inspectAndConfirmForClassName: "Inspect & confirm suspicious sessions for \"{classificationClassName}\"",
      inspectForClassName: "Inspect suspicious sessions for \"{classificationClassName}\"",
      inspection: "DSI Inspection",
      isOneHighlightedItemOfClass: "Is at least one of the highlighted items a \"{classificationClassName}\"?",
      notFound: "Session not found"
    },
    inspection: "Inspection",
    inspectionConfirmation: "Insp. Confirmation",
    isFullyAnnotated: "Fully Annotated",
    jackDaniels: "Jack Daniels probability",
    labelCount: "Label Count",
    labelling: {
      addClassifier: "Add classifier",
      additionalAnnotations: "Additional Annotations",
      amount: "Amount",
      annotations: {
        annotationDelete: "Delete wrong annotation",
        annotationEdit: "Edit annotation position",
        annotationEditCancel: "Cancel editing",
        annotationEditConfirm: "Confirm new position",
        copyPrevious: "Copy previous annotations",
        copyPreviousDisabledBecauseAnnotationsExist: "Annotations can't be copied while additional annotations have already been created",
        copyPreviousDisabledBecausePreviousHasNone: "Annotations can't be copied if the previous step has none",
        detectionDelete: "Delete wrong detection",
        detectionEdit: "Edit detection position",
        detectionEditCancel: "Cancel editing",
        detectionEditConfirm: "Confirm new position",
        detectionIsCorrect: "Detection is correct",
        downloadManual: "Download DSI Annotation manual (PDF)",
        sectionAnnotationsDisabledExplanation: "All recognized classifiers have to be processed first (either accepted, modified or deleted)",
        sectionDetectionsDisabledExplanation: "The image has to be rated first (upper right)",
        sectionDisabledBecauseOfImageRatingExplanation: "The image wasn't rated as OK"
      },
      applyToNext: "Apply values for next",
      articles: "Articles",
      articlesPerBundle: "Articles per bundle",
      bundles: "Bundles",
      classification: {
        empty: "Empty",
        loaded: "Loaded",
        unsure: "Unsure"
      },
      empty: "Empty",
      faulty: "Faulty",
      faultyTooltip: "The photo wasn't taken correctly (e.g. camera was blocked, camera is broken)",
      general: "General",
      labellingBundles: {
        bundle: "Items per bundle",
        noBundle: "No bundle",
        unsure: "Unsure"
      },
      labellingLoaded: {
        empty: "Empty",
        faulty: "Faulty",
        loaded: "Loaded",
        unsure: "Unsure"
      },
      noAdditionalAnnotations: "No additional annotations added",
      noClassifiersDetected: "No classifiers detected",
      okTooltip: "The photo was taken correctly and you're not unsure about any object classes",
      recognizedClassifiers: "Recognized Classifiers",
      reset: "Reset this image classification",
      resetAreYouSure: "Are you sure you want to reset this image classification?",
      settings: "Settings",
      unsure: "Unsure",
      unsureTooltip: "You are unsure about one or more of the object classes in the photo"
    },
    live: {
      classifier: "Classifier",
      detectionWasConfirmedBy: "Detection was confirmed by",
      enableCardSwipe: "Enable card swipe",
      enableManualCheckForCustomer: "Do you want to enable manual check for this customer?",
      isThisItemOfClass: [
        "Is this a",
        "{classificationClassName}",
        "?"
      ],
      lastDetectionTimestamp: "Last detection timestamp",
      lastUpdatedMinutesAgo: "{minutes} minutes ago",
      loadNewSnapshot: "Load new snapshot",
      newerDetectionReceivedForClass: [
        "Newer detection received for:",
        "{classificationClassName}",
        ""
      ],
      noSessionData: "No session data available - either all detections have associated products in basket, or session was closed",
      noSessions: "Currently no session matches the selected filters.",
      noUnconfirmedDetectionsLeftForSession: "No unconfirmed detections left for this session",
      notifications: {
        subscribe: "Subscribe",
        title: "New detection in store: {store}",
        unsubscribe: "Unsubscribe"
      },
      openInSessionHistory: "Try to open in session history",
      returnToLiveSessions: "Return to live session overview",
      sessionAlreadyClosed: "Session is already closed or does not exist",
      subscriptions: {
        explanation: "Notify me for suspicious sessions in the following stores",
        title: "Store Subscriptions"
      }
    },
    loaded: "Is loaded probability",
    lock: {
      aquiring: "Aquiring lock...",
      forceLock: "Override lock",
      forceUnlock: "Force unlock",
      locked: "Locked"
    },
    market: "Store",
    maxTimeExpired: "Query took too long. Please apply stricter filters.",
    moreSessions: "There are more sessions. Try using more specific filters...",
    nextSession: "Next Session",
    noSessionData: "No session data available",
    noSessions: "No session matches the selected filters.",
    notScanned: "Product not scanned",
    numberOfCigarettes: "# Cigarettes",
    optionSections: {
      bottomCartlabeling: "Labelling: Bottom Cart",
      topCartAnnotating: "Annotating: Top Cart"
    },
    payment: "Payment",
    paymentMethod: "Payment Method",
    price: "Price",
    productSearch: {
      free: "Free text",
      groups: "Product categories",
      products: "Products",
      select: "Select a product category or start typing to search..."
    },
    redbull: "Redbull Probability",
    removed: "Subsequently removed",
    saveAndClose: "Save & Close",
    saveAndNext: "Save & Next",
    sessionGuid: "Session Guid",
    startEnd: "Start/ End",
    status: "Status",
    switchBundle: "Bundle switch",
    toffifee: "Toffifee probability",
    weightDeviation: "Weight Deviation",
    wrongQuantity: "Wrong quantity"
  },
  edit: "Edit",
  erp: {
    assets: {
      title: "Assets"
    },
    edit: "Edit",
    newEntryFilters: "Entry for new Carts or Charging Options is not visible when filters are set",
    plan: {
      confirmChangePast: {
        "delete": {
          message: "Be aware that this could impact past invoices!",
          title: "Delete entry in the past?"
        },
        edit: {
          message: "Be aware that this could impact past invoices!",
          title: "Edit entry in the past?"
        },
        restore: {
          message: "Be aware that this could impact past invoices!",
          title: "Restore entry in the past?"
        }
      },
      deletedItem: "Item is marked for deletion",
      fieldValues: {
        action: {
          add: "Add",
          fromStorage: "From Storage",
          remove: "Remove",
          toStorage: "To Storage"
        },
        type: {
          cart: "Cart",
          chargingOption: "Charging Option"
        }
      },
      fields: {
        action: "Action",
        amount: "Amount",
        change: "Change",
        comment: "Comment",
        effectiveOn: "Effective on",
        id: "Id",
        invoicedFrom: "Invoiced from",
        storeGuid: "Storeguid",
        storeName: "Store",
        subType: "Sub Type",
        tariff_capacity: "Tariff/Capacity",
        type: "Type",
        version_connector: "Version/Connector"
      },
      filters: {
        cart: "Carts",
        chargingOption: "Charging Options",
        deleted: "Show deleted entries"
      },
      navigation: "Planner",
      noMatchingItems: "No matching items found.",
      rowActions: {
        history: "History",
        snapshot: "View Snapshot"
      },
      title: "Planner for {store}",
      titleGlobal: "Planner for all stores"
    },
    planSummary: {
      location: {
        storage: "In Storage",
        store: "In Store"
      },
      title: {
        cart: "Carts",
        chargingOption: "Charging Options"
      }
    },
    properties: {
      amount: {
        title: "Amount"
      },
      cart_tariff: {
        title: "Tariff"
      },
      cart_type: {
        title: "Type",
        values: {
          light: "Light",
          premium: "Premium"
        }
      },
      cart_version: {
        title: "Version"
      },
      chargingOption_capacity: {
        title: "Capacity"
      },
      chargingOption_connector: {
        title: "Charging connector",
        values: {
          plugLight: "ES Light Plug",
          plugPremium: "ES Premium Plug",
          rail: "Charging rail"
        }
      },
      chargingOption_type: {
        title: "Type",
        values: {
          chargingBox: "Charging box (Outside)",
          chargingStation: "Charging station (Inside)",
          mobileChargingStation: "Mobile charging station",
          mobileChargingUnit: "Mobile charging unit"
        }
      },
      location: {
        title: "Location"
      }
    },
    props: {
      confirmDelete: {
        message: "Are you sure you want to delete the \"{property}\" property?",
        title: "Delete Property?"
      },
      edit: {
        condition: "Condition",
        conditionKey: "Key",
        conditionValue: "Value",
        title: "Edit Property",
        unique: "That value is already used",
        valueRequired: "At least one value is required"
      },
      fields: {
        description: "Description",
        id: "Id",
        values: "Values"
      },
      title: "Properties"
    },
    title: "Resource Planner"
  },
  error: {
    backToStart: "Back to homepage",
    code: "Code {code} could not be rendered",
    reload: "Reload page",
    showDetails: "Show details",
    title: "Error",
    uncaught: "An error has occurred."
  },
  execute: "Execute",
  expand: "Show more",
  failed: "Failed",
  fields: {
    target: "Target",
    validity: "Validity"
  },
  global: "Global",
  globalCartTable: {
    columns: {
      cartGuid: "cartGuid",
      cartId: "cartId",
      cartType: "type",
      changedBy: "changedBy",
      createdOn: "createdOn",
      currentEnvironment: "currentEnv.",
      details: "details",
      framenumber: "framenumber",
      hostname: "hostname",
      isDisabled: "isDisabled",
      isGpsDisabled: "isGpsDisabled",
      modifiedOn: "modifiedOn",
      productNumber: "productNumber",
      storeName: "storeName"
    },
    details: {
      linkToStore: "View cart store details",
      title: "Global Cart Details"
    },
    title: "Global Carts"
  },
  go: "Go",
  history: {
    action: "Action",
    added: "Added",
    changed: "Changed",
    changes: "Changes",
    group: "Group",
    modifiedBy: "User",
    modifiedOn: "Time",
    title: "History"
  },
  imageClassification: {
    cigarettes: "Cigarettes",
    fresh_food_counter: "Fresh Food Counter product",
    generic_coffee: "Generic coffee",
    jack_daniels: "Jack Daniels",
    redbull: "Red Bull",
    toffifee: "Toffifee"
  },
  information: {
    title: "Information"
  },
  input: {
    checkAgainstCurrent: "Always checks against current dev/test/latest!",
    noOptionsMessage: "No options",
    select: "Select.."
  },
  integrityChecks: {
    canceled: "canceled",
    completed: "completed",
    failed: "failed",
    noResults: "No pending Random Checks.",
    pending: "pending"
  },
  inventoryManagementSystem: {
    "1": "EBUS",
    "2": "RWWS",
    "5": "REDDI"
  },
  keys: {
    alt: "Alt",
    control: "Control",
    "delete": "Delete",
    enter: "Enter",
    escape: "Escape",
    left: "Left click",
    leftright: "Left/right click",
    middle: "Mouse wheel",
    numberKeys: "Number keys",
    pageDown: "Page down",
    pageUp: "Page up",
    right: "Right click",
    shift: "Shift"
  },
  layerSelection: {
    affectedStores: "Affected Stores",
    allBranches: "All retail formats",
    allCompanies: "All companies",
    allIms: "All Inventory Management Systems",
    allStores: "All stores",
    allTenants: "All tenants",
    exclude: {
      branches: "Not in these retail formats",
      companies: "Not in these companies",
      fallback: "exclude",
      ims: "Not in these Inventory Management Systems",
      stores: "Not in these stores",
      tenants: "Not in these tenants"
    },
    include: {
      branches: "Only in these retail formats",
      companies: "Only in these companies",
      fallback: "include",
      ims: "Only in these Inventory Management Systems",
      stores: "Only in these stores",
      tenants: "Only in these tenants"
    },
    layer: "Layer"
  },
  loading: "Loading...",
  loadingModule: "Loading module...",
  loginType: {
    anonymous: "Anonymous Login",
    appLogin: "App Login",
    customerCardLogin: "Customer Card Login",
    edekaApp: "EDEKA App Login",
    externalApp: "External App Login",
    hwo: "HWO Demo Login"
  },
  maintenanceModes: {
    active: "Active",
    inactive: "Inactive",
    maintenance: "Maintenance",
    repaired: "Repaired"
  },
  mapEditor: {
    actions: {
      "delete": "Delete map",
      editData: "Edit data",
      editMap: "Setup map",
      editObjects: "Edit map areas/points",
      editShelves: "Measure shelves",
      editTiles: "Tune navigation",
      waitForMap: "Map needs to be set up first"
    },
    add: "Add new map",
    areas: "Areas",
    chooseFile: "Choose a file",
    configureLevels: "Configure store maps",
    data: {
      title: "Edit map data ({level})",
      titleNew: "Create new map"
    },
    editObjects: "Edit objects",
    editOffset: "Edit offset",
    editTiles: "Edit tiles",
    errors: {
      invalidGrouping: "Id does not exist",
      minLessMax: "min must be less than max",
      required: "Required",
      systemIdTaken: "Id Already taken"
    },
    groupWithSystemId: "Grouped Id",
    groupWithSystemIdHelp: "(Optional) Reference another system Id to render both maps in the same plane. This only works if they exist in the same coordinate system but have to use multiple navigation systems for some reason.",
    mapBounds: "Map Bounds",
    mapFile: "Map File",
    mapSetup: {
      addAnchor: "Add anchor",
      anchors: "Anchors",
      coordinate: "Real world coordinate (m)",
      name: "Name",
      noAnchors: "There are no anchors yet, so a default scale will be used. This is fine for stores that don't have navigation. For stores with navigation anchors have to be set!",
      quickHelp: [
        "Place anchors on the map image and enter their real world coordinates. The map bounds will be calculated automatically.",
        "You should add at least 2 anchors that differ significantly in x and y direction. More are better to minimize errors."
      ],
      showGrid: "Show grid",
      showTiles: "Show tiles",
      title: "Setup map ({level})"
    },
    name: "Name",
    objects: {
      addAreaPolygon: "Add area (polygon)",
      addAreaRect: "Add area (rectangle)",
      addPoi: "Add point of interest",
      addPortal: "Add portal",
      areas: "Store areas",
      distance: "Distance",
      editTextBounds: "Edit text bounds",
      flags: {
        checkout: "Is Checkout"
      },
      flagsSection: "Properties",
      help: {
        editObjects: "Edit objects by dragging points, lines and areas",
        invertSnap: "Invert snap setting",
        pan: "Move map",
        splitLines: "Adds a point to split a line or removes a point to merge two lines",
        title: "Help"
      },
      icon: "Icon",
      inaccessible: "Inaccessible",
      name: "Name",
      poi: "Points Of Interest",
      portals: "Transitions",
      portalsTo: "Transitions to",
      quickHelp: [
        "Define store area, points of interest and transitions between maps.",
        "They will be displayed on the cart map while using the product navigation."
      ],
      snap: "Snap to points and lines",
      title: "Edit store objects ({level})"
    },
    orDropIt: "Or drop it",
    pleaseSelect: "Choose a file, paste it or drag it here",
    poi: "POI",
    portals: "Transitions",
    removeConfirmation: "Are you sure you want to delete map {level}?",
    settings: {
      options: {
        isSpecialityMarketEnabled: "Speciality store enabled",
        isSpecialityMarketEnabledTooltip: "If enabled, a range of shelves can be marked as being in a speciality store. When choosing an item in such a shelf for navigation, a messag will be displayed to the user.",
        sosMapEnabled: "SOS map enabled",
        sosMapEnabledTooltip: "Enables the fallback store map for customers in the help dialog. If enabled and the cart has no position (no navigation or broken navigation), the map will be shown so that the customers can pick their location. If disabled, the sos map is never shown.",
        specialityMarketShelfNoLowerEnd: "From shelf number",
        specialityMarketShelfNoUpperEnd: "To shelf number",
        subTitle: "Tweak the navigation experience on the cart.",
        title: "Navigation options"
      },
      status: {
        active1: "On",
        active2: "Available for customers",
        internal1: "Internal",
        internal2: "Services are installed but feature is hidden. Enable for testing in the EASY Shopper's admin menu.",
        off1: "Off",
        off2: "No navigation installed",
        on1: "On",
        on2: "Available for customers",
        subTitle: "Choose whether navigation should be enabled on the carts. When switching to or from \"Off\", changes are applied only after redeployment.",
        title: "Navigation status"
      }
    },
    systemId: "System Id",
    systemIdHelp: "Has to be the same Id that the navigation system emits on that level.",
    tiles: {
      cart: "Place cart for route calculation",
      "delete": "Delete all manual tiles",
      free: "Freehand drawing tool",
      help: {
        allow: "Allow tile",
        allowed: "Manually allowed",
        auto: "Calculated from carts",
        block: "Block tile",
        blocked: "Manually blocked",
        control: "Control",
        left: "Left click",
        leftright: "Left/right click",
        middle: "Mouse wheel",
        pan: "Move map",
        rect: "Draw rectangles",
        redo: "Redo",
        revert: "Revert tile to automatic value",
        right: "Right click",
        shift: "Shift",
        title: "Help",
        undo: "Undo"
      },
      missingBounds: "Missing map bounds",
      missingFile: "Missing map file",
      missingLevel: "Missing map data",
      noRouteFound: "No route found",
      noTilesYet: "No tiles have been calculated yet. Please wait until carts start sending data...",
      rect: "Rectangle drawing tool",
      redo: "Redo",
      reset: "Reset all overrides",
      save: "Save",
      showFinal: "Show final result",
      showGrid: "Show grid",
      showHeatmap: "Show heatmap",
      showRaw: "Show raw data",
      target: "Place target for route calculation",
      threshold: "Threshold",
      title: "Edit accessible tiles ({level})",
      undo: "Undo"
    }
  },
  marketing: {
    addProspect: "Add Prospect",
    cancelled: "Cancelled",
    comment: "Comment",
    company: "Corporation",
    createdBy: "Created by",
    createdOn: "Created On",
    deleteProspectDialog: {
      message: "{count, plural, one { The selected prospect will be deleted. This can be undone. } other { The # selected prospects will be deleted. This can be undone. }}",
      title: "{count, plural, one { Delete selected prospect? } other { Delete # selected prospects?}}"
    },
    deleteSelection: "Delete selection",
    editProspect: "Edit Prospect",
    email: "E-Mail",
    forename: "First Name",
    modifiedOn: "Modified On",
    notInterested: "Not interested",
    paid: "Paid",
    pending: "Pending",
    phone: "Phone",
    status: "Status",
    statusOptions: {
      "no-interest": "No interest",
      paid: "Paid",
      pending: "Pending"
    },
    store: "Store",
    surname: "Last Name",
    undoDelete: "Undo delete"
  },
  masterData: {
    allBranches: "All retail formats",
    allTenants: "All tenants",
    branches: "Retail formats",
    companies: "Companies",
    confirmDialog: {
      text: "Are you sure you want to delete the {itemType} \"{item}\"?",
      title: "Delete {itemType} \"{item}\"?"
    },
    error: {
      "18003": {
        message: "It is not allowed to edit or delete Inventory Management Systems.",
        title: "Not allowed"
      },
      "18004": {
        message: "The retail format or corporation is still assigned to stores and therefore cannot be deleted.",
        title: "Still in use"
      }
    },
    inventorySystems: "IMS",
    tenant: "Tenant",
    title: "Master Data",
    type: {
      branch: "Retail format",
      company: "Corporation",
      inventoryManagementSystem: "Inventory Management System",
      tenant: "Tenant"
    }
  },
  message: {
    success: "Success"
  },
  mhd: {
    delicatessen: "Gourmet food",
    expired: "Already expired!",
    grocery: "Food items",
    later: "Later",
    today: "Today",
    tomorrow: "{date} (Tomorrow)"
  },
  navigation: {
    NotFoundProducts: "Not Found",
    account: "Account",
    administration: "Administration",
    barcodeClassification: "Barcode Classifications",
    barcodeGenerator: "Barcode Tool",
    buildMonitor: "Build Monitor",
    cartReplacements: "Freshdesk replacements",
    carts: "Carts",
    cartsInSetup: "In Setup",
    check: "Random Customer Check",
    checkout: "Checkout",
    customers: "Customers",
    defects: "Defects",
    deployments: "Deployments",
    details: "Details",
    devops: "DevOps",
    dsi: "DSI",
    dsiAnalyze: "Session: {sessionGuid}",
    dsiLiveCheck: "Live check",
    dsiLong: "Theft Interface",
    easyDealsDetail: "EASY Deal",
    info: "Information",
    infrastructure: "Infrastructure",
    loading: "Loading stores..",
    logAnalyzer: "Log Analyzer",
    loginCode: "Admin Code",
    logout: "Logout",
    mapEditor: "Map Editor",
    marketing: "Marketing",
    masterData: "Master Data",
    mhd: "BBD",
    noBarcode: "No Barcode",
    notAssigned: "Please contact your supervisor since you have not been enrolled to any stores.",
    notAvailable: "Store not available",
    productBrowser: "Product Browser",
    productLabels: "Product Labels",
    products: "Items",
    promotions: "Promotions",
    releaseNotes: "Release Notes",
    releases: "Releases",
    reports: "Reporting",
    sessionHistory: "Session history",
    sessionMonitor: "In the store",
    settings: "Settings",
    setup: "Cart setups",
    shelves: "Shelf locations",
    storeSettings: "Store Settings",
    stores: "Stores",
    surveys: "Surveys",
    users: "Users",
    usersEmptySearch: "No user matched your search",
    version: "Version: {version}",
    waitingTime: "Waiting time"
  },
  newEntry: "New entry",
  no: "No",
  noBarcode: {
    bakery: "Bakery",
    beverages: "Beverages",
    confirm: "Are you sure?",
    deleteProduct: "Delete added product",
    empty: "No Results",
    fruitsAndVegetables: "Fruits&Veggies",
    gtinOrId: "GTIN or item ID",
    hasGtin: "Other gtin: ",
    info: {
      active: "Active",
      auto: "Last sale < 30 days",
      manualActive: "Added Manually",
      manualInactive: "Manually set to inactive",
      notActive: "Deactivated Manually",
      title: "Information"
    },
    input: {
      active: "Active",
      automatic: "Auto",
      inactive: "Inactive"
    },
    notAdded: "Item not added",
    notFound: "No items found! ",
    notPossibleToEdit: "Editing items not possible",
    others: "Other",
    productAlreadyExists: "This product is already in the list and cannot be added again.",
    search: "Search Gtin",
    searchNotFound: "No Results",
    sort: {
      active: "Active",
      brand: "Brand",
      gtin: "Gtin",
      name: "Name",
      price: "Price",
      removable: "Delete",
      title: "Sort by: "
    },
    title: "No Barcode"
  },
  noItems: "No items",
  notFound: "Not found",
  nutrition: {
    carb: "carbs per 100g",
    fat: "fat per 100g",
    protein: "protein per 100g"
  },
  ok: "Ok",
  old: "Older",
  paymentMethod: {
    apple_google_pay: "Apple/Google Pay",
    cashier: "At the checkout",
    creditcard: "Via credit card",
    direct_debit: "Direct debit",
    "edeka-app": "EDEKA App",
    edeka_app_payment: "EDEKA App"
  },
  paymentRequestGuid: "Payment Request Guid",
  paymentStatus: {
    Completed: "Completed",
    Created: "Ready",
    CustomerCancelled: "Canceled by customer",
    EmployeeCancelled: "Canceled by cashier",
    EmployeeReset: "Reset by cashier",
    NewPaymentRequestCreated: "Restarted",
    PaymentProviderDecline: "Denied by payment provider",
    PendingVerification: "Verifications",
    SystemCancelled: "Canceled by system"
  },
  pluForm: {
    createBargain: "Create price bargain",
    editBargain: "Edit price bargain",
    fillInThisField: "Please fill in this field",
    selectAtLeastOne: "Please select at least one result",
    selectValidPeriodFrom: "Please select a valid period (from)",
    selectValidPeriodUntil: "Please select a valid period (until)"
  },
  priceForm: {
    createPriceOverride: "Create price override",
    editePriceOverride: "Edit price override"
  },
  priceTypes: {
    action: "Sale",
    discount: "Discount",
    free: "Free"
  },
  print: "Print",
  prod: "PROD",
  product: {
    active: "Active",
    barcodeScanner: "Barcode Scanner",
    bbd: "BBD Group...",
    bbdGroup: "BBD Group...",
    bbdSelect: "Select all",
    brand: "Brand",
    changePicture: "Change picture",
    changeProductName: "Change product name",
    confirmDeletion: "Are you sure?",
    contents: "Contents/Quantity",
    deleteProducts: "Delete Products",
    forceActive: "Product status",
    heavy: "Heavy",
    "import": "Import",
    inactive: "Inactive",
    insertaProductName: "Insert a product name",
    itemDetails: "Item Details",
    light: "Light",
    loadingProduct: "Product is loading...",
    name: "Name",
    nameValidation: "The name should contain at least 3 letters. ",
    noHistory: "The product has no field changes",
    notFoundProducts: "Not Found Products",
    notHeavy: "Not Heavy",
    notLight: "Not light",
    notPossibleToUpload: "No picture can be uploaded",
    productDetails: "Product Details",
    productHistory: "Product History",
    productNameLoading: "Product name is being loading",
    productNotFound: "Product not found",
    productSelection: "{count, number} Products will be deleted. ",
    selectAll: "Select all",
    statistics: "Wichtige Statistik",
    unitOfMeasure: "Unit of measure",
    unknown: "Unknown product",
    uploadFailed: "Upload not possible"
  },
  productBrowser: {
    actionPrice: "Sale Price",
    active: "Active",
    ageRestriction: "Age Restriction: {age, number} years old",
    allProductGroups: "All product categories",
    allSubCommGroups: "All sub product categories",
    assignLabelsPlaceholder: "Assign labels to this product",
    assignSource: {
      cgLongId: "auto",
      direct: "manual"
    },
    assignedLabels: "Assigned labels",
    assignedLabelsDescription: "Labels assigned to this product either directly or via a product category",
    assigningLabel: "Assigning label...",
    brand: "Product brand",
    brandShort: "Brand",
    capacity: "Capacity",
    changeImage: "Change Image",
    changesNeedTimeDeleteMessage: "Current image has been removed successfully and the previously used image will be restored. It can take a few minutes until this change is visible.",
    changesNeedTimeTitle: "Changes need time until visible",
    changesNeedTimeUploadMessage: "New image has been uploaded successfully, but it can take a few minutes until this change is visible.",
    commodityGroup: "Product Category",
    contents: "Unit",
    deletedOn: "Deleted on: ",
    details: "Product details",
    didYouMean: "Did you mean",
    disabled: "Disabled",
    discountable: "Discountable",
    display: "Display",
    doNotExist: "This product does not exist in this store.",
    excludedLabels: "Excluded labels",
    excludedLabelsDescription: "Labels that are manually excluded from this product",
    filename: "Filename: ",
    filenameRwws: "RWWS filename: ",
    fillOutComment: "Please fill in the comment field",
    filterActive: "Active products",
    filterAll: "All products",
    filterInactive: "Inactive products",
    filterProductLabels: "Products to be labelled",
    flag: "Flag: {flag}",
    flags: "Flags",
    foodFlags: "Product Info",
    force: "(force)",
    from: "From: ",
    fuzzy: "Search for similar terms",
    fuzzyResults: "Results for similar terms",
    gtin: "GTIN",
    hasActionPrice: "Has Action Price",
    hasSalesLimitQty: "Has Sales Limit",
    hasShelfNumbers: "Has Shelf Numbers",
    id: "Item ID",
    isWeight: "Weight product",
    labels: "Labels",
    lastImport: "Last import: ",
    legend: "Legend",
    modifiedOn: "Last import: ",
    modifiedRwwsImportOn: "RWWS last import: ",
    name: "Product name",
    nameSearch: "Name",
    noFlag: "Any flags",
    noResults: "No matches",
    notOnSearch: "Not on Search",
    nutrition: "Nutrition",
    pos: {
      title: "POS Status"
    },
    price: "Price",
    purchaseDate: "Last purchase date ",
    recentItems: "Recent Items",
    relatedProducts: "{count, plural, one { Other Option: } other { Other Options: }}",
    reloadLabelList: "Clear labelled products",
    removeTag: "Remove tag",
    removedTag: "Removed tag",
    resolvedLabels: "Resolved labels",
    resolvedLabelsDescription: "Final calculated list of labels for this product. Includes assigned labels and their ancestors, but excludes excluded labels. This is what will be used by search/recipes/etc.",
    rwws: {
      title: "RWWS Status"
    },
    salesLimitQty: "Sales Limit",
    search: "Search",
    searchInfo: "Only active products can be found by name, brand, flags, action price and shelf numbers.",
    selectRiscOrTag: "Please select a risk level or tag",
    shelf: {
      add: "Add shelf",
      title: "Shelf"
    },
    shelves: {
      add: "Add shelf",
      title: "Shelf"
    },
    showingRecents: "You can search or narrow down the selection to find items",
    sortByDate: "Sort by date",
    specialFeatures: "Special features",
    status: "Status",
    tags: "Tags",
    title: "Product Browser",
    to: "To: ",
    weight: "Weight"
  },
  productImport: {
    action: "Download Import Template",
    confirmImport: "Start the import?",
    confirmImportText: "Products found in the file {fileName} will be imported",
    download: "Download as XML",
    error: "Could not import products: {message}",
    "import": "Import to Database",
    infoText: "{countProducts, plural, one {# product} other {# products}} recognized with {countBundle, plural, one { # bundle } other { # bundles }}",
    infoTextBundle: "{count, plural, one { with # bundle } other { with # bundles }}",
    instructions: "Create products from template",
    title: "Import Products"
  },
  productLabel: {
    addConnection: "link or create label",
    addLabelToProduct: "Add product",
    createDialog: {
      title: "Create new label \"{count}\"?"
    },
    createLabel: "Create label: ",
    delayHint: "Changes may take a few minutes to be visible.",
    "delete": {
      text: "The label will be removed if it does not have any children.",
      title: "Delete the product label “{ labelName }”?"
    },
    deleteLabel: "Delete label",
    errors: {
      cannotDeleteWithChildNodes: "Product label cannot be deleted as long as it has child nodes",
      labelAlreadyExists: "Product label already exists"
    },
    imageClassifierNetworks: "Image classifier networks",
    imageClassifierNetworksDescription: "Select the network(s) that are used to classify product belonging to this label.",
    label: "Product label",
    labelTitle: "Label name",
    noLabelFound: "No Label Found",
    noProductsFound: "No products with the current label found",
    notExhaustive: "There are likely more products that are not displayed here. Adjust filters to find the right one.",
    products: "Products",
    productsWithLabel: "{labelName} - Products",
    removeLabel: "Remove connection to current label",
    removeLabelFromSelection: "Remove label from selection",
    revertChanges: "Revert changes to current product label",
    showProducts: "Show products",
    startTypingToFindProducts: "Type a product name to see options",
    success: {
      addMany: "{productCount} products successfully added to {labelName}",
      addOne: "{productName} successfully added to {labelName}",
      remove: "{productCount, plural, one {# product successfully removed from {labelName}} other {# products successfully removed from {labelName}}}"
    },
    synonyms: "Synonym",
    table: {
      brand: "Brand",
      cgLongId: "Sub commodity group",
      labelDistance: "Label Distance",
      name: "Name"
    },
    title: "Product label...",
    toBeDeleted: "To be deleted",
    undoAllDelete: "To undo all press the key `Esc`"
  },
  profile: {
    activation: {
      invalidToken: "Your token is invalid. Please request a new activation e-mail. ",
      newUser: "Set a password to complete the registration.",
      passwordReset: "Set a new password.",
      submit: "Set password"
    },
    addScope: "Add scope...",
    addStore: "Add store...",
    addTenant: "Add tenant...",
    changePassword: "Change password",
    confirmDeletion: "Are you sure you want to delete the user?",
    createUser: "Create User",
    "delete": "Delete",
    deleteUser: "Delete user",
    editUser: "Edit user",
    email: "E-mail address",
    error: "Failed to create user:",
    errors: {
      "0": "Ok",
      "1": "Unknown error",
      "10": "Welcome email could not be sent: \"{contextValue}\"",
      "2": "Permission for {contextValue} was denied",
      "3": "Permission is invalid (roles: {contextValue})",
      "4": "{contextValue} is already in use",
      "5": "Account does not exist (accountGuid: {contextValue})",
      "6": "Input is invalid: {contextValue}",
      "7": "Store not found (storeGuid: {contextValue})",
      "8": "No tenant was selected for the account {contextValue}",
      "9": "No store was selected for the account (storeGuid: {contextValue})"
    },
    firstName: "First name",
    forgotPassword: {
      link: "I forgot my password",
      submit: "Send e-mail",
      title: [
        "In order to reset your password, please enter the e-mail address attached to your account.",
        "You will then receive an e-mail with further instructions."
      ]
    },
    global: "Global",
    language: "Language",
    lastName: "Last name",
    login: {
      invalidStoreToken: "Invalid store token",
      login: "Login",
      minimumLength: "Minimum length: {currentLength}/8 characters",
      newPassword: "New password",
      noPassword: "No Password",
      noStoreToken: "You need your store's wifi access in order to login.",
      noUserName: "No Username",
      oldPassword: "Old password",
      password: "Password",
      repeatNewPassword: "Repeat new password",
      requiredField: "Required",
      title: "Welcome!",
      unknownError: "Unknown error",
      username: "Username",
      wrongPassword: "Wrong password",
      wrongPasswordOrUser: "Wrong username / password",
      wrongStoreToken: "You are not authorized to login to this store."
    },
    newPassword: "New password",
    newPasswordRepeat: "Confirm new password",
    oldPassword: "Old password",
    password: "Password",
    permissions: "Permissions",
    personalData: "Personal data",
    resetPassword: "Reset Password",
    roles: {
      admin: "Admin",
      auditor: "Auditor",
      cashier: "Cashier",
      customerLabelling: "Customer Labelling",
      demo: "Demo",
      detective: "Detective",
      devops: "DevOps",
      dsi: "DSI",
      foodAcademy: "Food Academy",
      labelling: "Product Label",
      maintenance: "Maintenance",
      mhd: "BBD",
      pentland: "Pentland",
      peopleCounter: "People Counter",
      rollout: "Rollout",
      service: "Service",
      storeManager: "Store Manager",
      technician: "Technician"
    },
    scopeType: {
      store: "Stores",
      tenant: "Tenants"
    },
    selectRoles: "Select roles...",
    tilte: "Your Profile",
    type: {
      "0": "Global user",
      "1": "Store user"
    },
    typeSelection: "Type of user",
    unknownUser: "Unknown user",
    updated: "Profile was updated successfully",
    userAlreadyUsed: "{userName} is already in use",
    userAlreadyUsedFallback: "This user name is already in use",
    userName: "User name",
    usersAdministration: "Users Administration"
  },
  promotions: {
    active: "Currently Active",
    addCoupon: "Add coupon",
    addKddr: "Add KDDR",
    addVoucherPromotion: "Add progress bar promotion",
    amount: "Amount",
    amountTooSmall: "Must be greater than 0",
    back: "Overview",
    barcodeError: "Invalid barcode",
    cannotEdit: {
      excludedStores: "You do not have access to all non-excluded stores that this promotion is assigned to",
      outOfScope: "You do not have access to the target that this promotion is assigned to",
      stores: "You do not have access to all stores that this promotion is assigned to",
      tenants: "You do not have access to all tenants that this promotion is assigned to",
      title: "Insufficient editing rights"
    },
    changedProperties: "Changed Properties",
    changesHistory: "History of Changes",
    confirmMessage: "Are you sure?",
    coupons: "Coupons",
    created: "Created",
    dateTo: "to",
    dealTemplate: "EASY Deal Template",
    "delete": "Delete promotion",
    deleteItems: {
      confirm: "This cannot be undone",
      coupon: "Delete KDDR promotion?",
      deleteAllStores: "Delete for all stores",
      deleteOnlyStore: "Delete only for this store",
      easyDeal: "Delete EASY deal?",
      easyDealCollection: "{count, plural, one {Delete  one EASY deal?} other {Delete # EASY deals?}}",
      exit: "Exit",
      loyaltyPass: "Delete loyalty pass?",
      remainingDays: "{count, plural, one {# day left} other {# days left}}",
      sureExit: "Are you sure you want to exit?",
      unsavedChanges: "Any unsaved changes will be lost",
      voucherPromotion: "Delete progress bar promotion?"
    },
    deleted: "Deleted",
    discount: "Coupon Discount",
    duplicate: "Duplicate",
    easyDeal: "EDEKA Coupon",
    easyDeals: "EASY Deals ({count})",
    emptyField: "Empty field",
    end: "End date",
    error: "Not possible to update",
    filter: {
      coupon: "Coupons",
      easyDeal: "EASY Deals",
      kddr: "KDDR",
      loyalty: "Loyalty Rewards",
      voucherPromotion: "Progress bar promotions"
    },
    importEasyDeals: "Import EASY deals",
    inactive: "Currently not active",
    invalidEntry: "Invalid entry",
    kddr: "{percentage}% Stick Your Own Discount",
    loyaltyRewards: {
      activatable: "Coupon can be activated",
      add: "Add loyalty rewards",
      customerInfo: "Customer info",
      discount: "Discount",
      discountShortDescription: "Short discount description internal",
      discountTitle: "Discount description for customers",
      discountValidity: "Cash in time frame",
      gtinPlu: "Deal GTIN/PLU",
      image: {
        "default": "Default image",
        defaultInfo: "Fallback image will be used",
        imageType: "Image Type",
        imageUrl: "Image URL",
        noImage: "No image",
        title: "Image",
        upload: "Upload image"
      },
      isActive: "Active",
      minSessionAmount: "Required amount of shopping sessions",
      minSessionTotal: "Min. amount in basket",
      minSessionTotalToRedeem: "Min. amount to cash in",
      promotion: "Promotion",
      showProgress: "Promotion Pop-Up after login",
      subtitle: "Subtitle",
      text: {
        dragAndDrop: "Drag and Drop Discount Image here",
        selectImage: "Select Discount Image"
      },
      title: "Title",
      titlebarTitle: "Loyalty Rewards",
      validity: "Time frame",
      validityErrorMessage: "Discount cash-in date has to start after the validity start and end after the validity end"
    },
    name: "Discount Name",
    noChangesInPromotion: "No Changes in Promotion",
    noChangesInStores: "No Changes in Affected Stores",
    period: "Period:",
    responsability: {
      furtherDevelopment: "Further Development",
      indoorService: "Indoor Service",
      rolloutPreparation: "Rollout Preparation",
      support: "Support",
      title: "Responsibility"
    },
    responsibility: {
      furtherDevelopment: "Further Development",
      indoorService: "Indoor Service",
      responsibleParty: "Responsible Party",
      rolloutPreparation: "Rollout Preparation",
      support: "Support",
      title: "Responsibility"
    },
    start: "Start date",
    title: "Promotions",
    today: "Today",
    totalBranches: "Total number of affected retail formats",
    totalExcludedStores: "Total number of excluded stores",
    totalIncludedStores: "Total number of included stores",
    tryAgain: "Try again",
    updateCouponPromotion: "Edit KDDR",
    updateVoucherPromotion: "Edit Progressbar Promotion",
    upload: "Successfully uploaded!",
    uploadCouponPromotion: "Add KDDR",
    uploadEasyDealsError: {
      "0": "Unknown error in column {column} row {row}",
      "1": "Discount is ambigious (column {column}, row {row})",
      "2": "Data type is invalid (column {column}, row {row})",
      "3": "Required value is missing (column {column}, row {row})",
      "4": "Unknown StoreId (column {column}, row {row})",
      "5": "Invalid file format",
      "6": "Missing column ({column})"
    },
    uploadVoucherPromotion: "Add Progressbar Promotion"
  },
  pushNotification: {
    noNavi: "No navi"
  },
  pushTopics: {
    imageDetection: {
      message: "Image Detection"
    },
    required: "Unsubscribed",
    sessionMonitor_admin: {
      message: "Admin",
      section: "In the Store"
    },
    sessionMonitor_detective: {
      message: "Detective",
      section: "In the Store"
    },
    sessionMonitor_service: {
      message: "Service",
      section: "In the Store"
    }
  },
  redo: "Redo",
  releaseNotes: {
    author: "Author",
    collapse: "Show less",
    create: "Create Release Notes",
    current: "Current",
    deletedUser: "Deleted User",
    display: {
      "delete": "Delete Release Notes",
      deleteConfirmation: "Do you really want to delete these release notes?",
      edit: "Edit Release Notes",
      print: "Print",
      publish: "Publish now",
      title: "Version {title}",
      unpublish: "Undo publication"
    },
    draft: "Draft",
    edit: {
      saveDraft: "Save draft",
      titleCreate: "Create Release Notes",
      titleEdit: "Edit version: {title}",
      update: "Update version",
      updateDraft: "Update draft"
    },
    emptySearch: "No release note matched your search",
    expand: "Show more",
    name: "Release name",
    placeholder: "Release description",
    printPreview: "Print - Format document",
    published: "Published",
    publishedAutomaticallyOn: "Published automatically on",
    publishedOn: "Release Date",
    publishedOnPlaceholder: "no date selected",
    title: "Release Notes"
  },
  reload: "Reload",
  remainingDays: "{count, plural, one {# day left} other {# days left}}",
  rename: "Rename",
  reports: {
    noReports: "No reports available",
    select: "Select report",
    title: "Reporting"
  },
  reset: "Reset",
  resetChanges: "Reset changes",
  restore: "Restore",
  results: "Results ({count})",
  save: "Save",
  search: "Search",
  send: "Send",
  sessionGuid: "Session Guid",
  sessionHistory: {
    WeightWithoutScan: "Weight without scan: ",
    deviation: "Deviation",
    expected: "Expected: ",
    expectedDelta: "Expected delta: ",
    loginWithApp: "Login with app",
    loginWithCard: "Login with card",
    measured: "Measured: ",
    okayAgain: " okay again",
    paymentCanceled: "Payment canceled",
    paymentCompleted: "Payment completed",
    paymentMethod: "Payment method: {method}",
    paymentStarted: "Payment started",
    productWeight: "Product weight: ",
    purchaseFinished: "Purchase finished",
    purchaseStarted: "Purchase started",
    reason: "Reason: ",
    scannedCoupon: "Scanned coupon",
    sessionTransfer: "Session Transfer",
    silent: "Silent",
    weight: "Weight ",
    weightAdjusted: "Weight adjusted:"
  },
  sessionMonitor: {
    claimTicket: "Claim Ticket",
    confirmCustomerMark: "Do you really want to mark this customer?",
    confirmationManualCheckDisable: "Do you really want to disable manual check?",
    confirmationManualCheckEnable: "Do you really want to enable manual check?",
    currentWeight: "Actual: ",
    customerMark: "Mark customer",
    disableManualCheck: "Disable Manual Check",
    disableManualCheckImageView: "Manual Check",
    emptyShoppingCart: "The shopping cart is empty",
    enableManualCheck: "Enable Manual Check",
    enableManualCheckImageView: "Manual Check",
    expectedWeight: "Expected: ",
    express: "Express",
    historyDetails: "Details",
    loadingDevices: "Loading devices",
    loginCode: "Admin Code",
    manualCheck: "Manual Check",
    map: "Map",
    openTickets: "Open tickets: {count, number}",
    ownTicket: "Me",
    releaseTicket: "Release Ticket",
    removeCustomerMark: "Remove customer mark",
    resetTara: "Reset scales deviation?",
    sessionLoading: "Session is loading",
    shopper: "Shopper",
    showDetails: "Show Details",
    sortCartNumber: "Sort by cart number",
    sortDeltaWeight: "Sort by delta weight",
    ticketClosed: "Ticket Closed",
    ticketNotAssigned: "Ticket could not be assigned!",
    ticketNotClosed: "Ticket could not be closed!",
    ticketNotReleased: "Ticket could not be released!"
  },
  shelf: {
    backgroundMapOpacity: "Background Map opacity: {opacity}%",
    barcode: "Type in the barcode",
    "delete": "Delete",
    deleteShelves: "Delete shelves",
    easyShopperPosition: "Position the EASY Shopper so that its antenna is as close as possible to the shelf barcode and scan the barcode.",
    failed: "Failed to save shelf: ",
    message: "{count, plural, one { Are you sure that you want to delete this shelf? } other { Are you sure that you want to delete # shelves? }}",
    noNavigationData: "No navigation data",
    notSaved: "Couldn't save",
    oldNavigationData: "Outdated navigation data",
    pickACart: "Choose a cart: ",
    restore: "Restore",
    restoreShelf: "Please choose the shelf that you want to restore",
    save: "Save shelf",
    scan: "Enter a code: ",
    scanShelf: "Scan shelf",
    shelfScan: "Shelf scan with Cart {cartId}",
    title: "Current shelf spaces",
    undoLastShelf: "Undo last shelf",
    undoLastShelfMsg: "Are you sure that you want to delete shelf {shelfNumber}?",
    upload: {
      pleaseSelect: "Choose a file:",
      title: "CSV Upload"
    },
    wifiMsg: "Please make sure that the cart is connected to Wi-Fi and try again."
  },
  showMore: "+{count} more",
  single: "Single",
  status: "Status",
  store: {
    address: {
      city: "City",
      country: "Country",
      street: "Street",
      zipCode: "Zip Code"
    },
    chargingOptions: {
      addChargingOption: "Add another charging option",
      cartCount: "Number of carts",
      chargerCount: "Chargers",
      chargingController: "Charging controller {name}",
      chargingControllers: "Charging controllers",
      connector: "Charging connector",
      connectors: {
        plugLight: "ES Light Plug",
        plugPremium: "ES Premium Plug",
        plugPremiumRail: "ES Premium Plug + Charging Rail",
        rail: "Charging rail"
      },
      description: "Please add all planned charging options. Multiple charging options can be added for each charging type.",
      incompleteError: "Charging option {i} is incomplete. Please fill all required fields.",
      requiredError: "At least one charging option is required.",
      switchCabinetCount: "Number of switch cabinets",
      type: "Charging type",
      types: {
        chargingBox: "Charging box (Outside)",
        chargingStation: "Charging station (Inside)",
        mobileChargingStation: "Mobile charging station",
        mobileChargingUnit: "Mobile charging unit"
      }
    },
    create: {
      message: "Please enter foundational information to start",
      title: "Create store"
    },
    golive: "Go-live",
    gps: {
      lat: "Latitude (N/S)",
      lon: "Longitude (E/W)"
    },
    "import": {
      confirmationText: "Are you sure you want to import the store?",
      confirmationTitle: "Import store",
      description: "Enter at least one of the following search terms to find a store.",
      detailsTitle: "Store details",
      fields: {
        city: "City",
        gln: "GLN",
        name: "Name",
        zipCode: "Zip Code"
      },
      "import": "Import",
      moreInfo: "More info",
      title: "Import Store",
      yesImport: "Yes, import"
    },
    lessInfos: "Show less information",
    milestone: {
      activateProductsAction: {
        title: "Product Activation"
      },
      configBootstrapAction: {
        title: "Copy Configurations"
      },
      createStoreDatabaseAction: {
        title: "Setup Cloud"
      },
      storeCartCheck: {
        title: "Carts Set Up"
      },
      storeCreationBasicInput: {
        field: {
          address: {
            title: "Address"
          },
          area: {
            title: "Sales Area in m²"
          },
          branch: {
            title: "Retail format"
          },
          company: {
            title: "Corporation"
          },
          costCenter: {
            title: "Cost Center"
          },
          currency: {
            title: "Currency"
          },
          enableCreditCardPayment: {
            title: "Enable Credit Card Payment"
          },
          enableDirectDebitPayment: {
            title: "Enable Direct Debit Payment"
          },
          enablePaymentAtCart: {
            title: "Enable payment via app"
          },
          enablePaymentAtCartShort: {
            title: "Payment via app"
          },
          enablePosPromotions: {
            title: "Enable checkout promotions"
          },
          fallbackStore: {
            title: "Fallback Store"
          },
          floorArea: {
            title: "Sales Area (m²)"
          },
          globalLookupNumber: {
            title: "Global Lookup Number (GLN)"
          },
          inventoryManagementSystem: {
            title: "WWS"
          },
          location: {
            title: "GPS of Entrance"
          },
          mposIpAddress: {
            title: "MPOS IP"
          },
          name: {
            title: "Name"
          },
          preferredLanguages: {
            title: "Preferred Languages"
          },
          rwwsMigrationDate: {
            title: "RWWS Migration"
          },
          startsOn: {
            title: "Go Live Date"
          },
          storeGuid: {
            title: "StoreGuid"
          },
          storeId: {
            title: "StoreID"
          },
          storeNumber: {
            title: "Store Nº"
          },
          tenant: {
            title: "Tenant"
          }
        },
        title: "General Information"
      },
      storeCreationFiBuInput: {
        field: {
          company: {
            title: "Corporation"
          },
          costCenter: {
            title: "Cost Center (If deviating from Store Nº)"
          },
          customerNumberFiBu: {
            title: "Customer Accounting Number (If deviating from StoreID)"
          },
          operatingMode: {
            title: "Operating Form"
          },
          storeNameFiBu: {
            title: "Store Accounting Name (If deviating from Name)"
          }
        },
        title: "Deviating Accounting Data"
      },
      storeCreationImprintInput: {
        field: {
          contacts: {
            add: "Add:",
            sendEmail: "Email Store Manager and other contacts",
            title: "Additional Email Addresses"
          },
          dealsFlyerUrl: {
            title: "Advertising brochure URL"
          },
          openingHours: {
            title: "Opening Hours"
          },
          storeManagerEmail: {
            title: "Store Manager e-Mail"
          }
        },
        title: "Imprint"
      },
      storeCreationRolloutInput: {
        field: {
          cartDeliveryDate: {
            title: "Cart Delivery Date"
          },
          esLight: {
            title: "EASY Shopper Light"
          },
          esPremium: {
            title: "EASY Shopper Premium"
          },
          expressShopping: {
            title: "Express Shopping"
          },
          hasNavigation: {
            title: "Indoor Navigation",
            tooltip: "This setting is read-only here and can be edited in the map editor."
          },
          hasRamps: {
            options: {
              "false": "No",
              "true": "Yes"
            },
            title: "Has Ramps"
          },
          hasSelfCheckout: {
            options: {
              "false": "No",
              "true": "Yes"
            },
            title: "Has Self-Checkout"
          },
          numberOfCarts: {
            title: "Number of Carts"
          },
          phoneCount: {
            title: "Number of phones for checkout"
          },
          sosPhoneCount: {
            title: "Number of phones for support"
          },
          subcontractor: {
            title: "Supported By"
          },
          voucherPromotions: {
            noPromotions: "No progressbar promotions",
            title: "Progressbar Promotions"
          }
        },
        title: "Rollout Information"
      },
      storeCreationTechnicalInput: {
        field: {
          chargingBoxCount: {
            title: "Number of charging boxes (outside)"
          },
          chargingMobileChargersCount: {
            title: "Number of mobile chargers"
          },
          chargingOptions: {
            title: "Charging Options"
          },
          chargingRailCount: {
            title: "Number of charging stations (inside)"
          },
          chargingStationCount: {
            title: "Number of charging stations (inside)"
          },
          chargingSwitchCabinetCount: {
            title: "Number of switch cabinets"
          },
          jumpHostIpAddress: {
            title: "Jump Host IP"
          },
          mobileChargingStationCount: {
            title: "Number of mobile chargers"
          },
          mposIpAddress: {
            title: "MPOS IP"
          },
          navigationAnchorCount: {
            title: "Navigation anchor count"
          },
          plug: {
            title: "Has Plug"
          },
          rail: {
            title: "Has Rail"
          },
          switchCount: {
            title: "Switch Count"
          },
          wifiProfile: {
            options: {
              custom: "Custom (store-specific)"
            },
            title: "Wi-Fi Profile"
          }
        },
        title: "Tech Input"
      },
      storeDatawarehouseCheck: {
        title: "Datawarehouse Import"
      },
      storeMigrationInitStage: {
        title: "Init"
      },
      storeMigrationMigrationStage: {
        title: "Migration"
      },
      storeMigrationPrepareStage: {
        title: "Prepare"
      },
      storeMposCheck: {
        title: "MPOS"
      },
      storePaymentCheck: {
        title: "Payment Provider"
      },
      storeProductImportCheck: {
        title: "Product Import"
      },
      storeProxyCheck: {
        title: "Store Proxy"
      },
      storeServiceBusAction: {
        title: "Service Bus"
      },
      storeServiceFreshDesk: {
        title: "Freshdesk"
      }
    },
    milestoneGroup: {
      infrastructure: "Infrastructure",
      storeInfo: "Information",
      storeMigration: "Store Migration"
    },
    milestoneHistory: {
      changedBy: "Changed by",
      error: "Error",
      message: "Message",
      metaDataDiff: "Metadata Diff",
      metadata: "metadata",
      modifiedOn: "Modified on",
      runType: "Run Type",
      status: "Status",
      title: "History"
    },
    milestoneInfo: "last changed: {date} | {user}",
    milestoneStatus: {
      NOK: "Not successful",
      OK: "Successful",
      RUNNING: "Running",
      SCHEDULED: "Scheduled"
    },
    milestoneType: {
      check: {
        past: "and {count, number} past ones",
        pastDialog: "Past checks:",
        rerun: "Rerun check",
        run: "Check"
      }
    },
    moreInfos: "Show further information",
    noMilestoneHistory: "No milestone history found",
    openingHours: {
      closes: "To",
      opens: "From"
    },
    pleaseSelect: "Please select",
    runAllMilestones: "Run all checks again",
    showDetails: "Show details",
    validations: {
      eanCheckDigit: "Checksum does not equal a valid EAN Nº. (Calculated: {calculated})",
      eanLength: "EAN Nº not valid.",
      eanNoNumber: "EAN Nº not valid.",
      emails: "Invalid email address(es): {emails}",
      max: "Max. Value: {message}",
      maxLength: "Max. Length: {message}",
      min: "Min. Value: {message}",
      minLength: "Min. Length: {message}",
      pattern: "Input does not meet required format: {message}",
      required: "Required Field"
    },
    visibility: {
      hidden: "Store is hidden",
      hideMessage: "Do you want to hide this store? Customers won't be able to see it anymore.",
      hideTitle: "Hide store",
      showMessage: "Do you want to make this store visible? Customers will be able to see it.",
      showTitle: "Show store",
      visible: "Store is visible"
    },
    waitingForDependency: "Requirement not met",
    weeks: {
      later: "Later",
      next: "Next Week",
      past: "Past",
      "this": "This Week"
    },
    wifiProfiles: {
      cart: {
        password: "Cart Wi-Fi password",
        ssid: "Cart Wi-Fi SSID"
      },
      description: "Store-specific credentials can be defined using the \"custom\" profile.",
      market: {
        password: "Market Wi-Fi password",
        ssid: "Market Wi-Fi SSID"
      },
      password: "Wi-Fi password",
      profile: "Profile",
      setup: {
        password: "Setup Wi-Fi password",
        ssid: "Setup Wi-Fi SSID"
      },
      ssid: "Wi-Fi SSID"
    }
  },
  storeGuid: "Store Guid",
  storeMetadata: {
    address: "Address",
    branch: "Retail format",
    cartDeliveryDate: "Cart Delivery",
    city: "City",
    company: "Corporation",
    country: "Country Code",
    create: "Create store",
    editStore: "Edit store",
    email: "Store Manager e-Mail",
    failed: "Failed to update/create store",
    from: "From",
    inventoryManagementSystem: "Inventory System",
    location: "GPS Entrance",
    name: "Name",
    newStore: "New store",
    numberOfCarts: "Number of Carts",
    openingHours: "Opening Hours",
    pleaseSelect: "Please select",
    rolloutForm: "Rollout view",
    startsOn: "GoLive",
    storeId: "StoreId",
    storeNumber: "Store Number",
    street: "Street",
    tableForm: "Table view",
    to: "To",
    zipCode: "ZIP Code"
  },
  storeSettings: {
    addOrEdit: "Add or edit multiple settings",
    adminArea: "Admin area",
    allStores: "All stores",
    allTenants: "All tenants",
    allowAnonymousLogin: "Allow anonymous login",
    days: {
      articles: "Articles",
      days: "Days",
      rate: "Rate",
      time: "Time",
      wholeWeek: "Whole week"
    },
    differingSettings: "Differing Settings",
    disruptors: {
      always: "Always",
      commodityGroups: {
        allCommodityGroups: "All commodity groups",
        excludeCommodityGroups: "Not in these commodity groups",
        excluded: "Excluded",
        includeCommodityGroups: "Only for these commodity groups",
        included: "Included",
        title: "Commodity groups"
      },
      configs: {
        actions: {
          addNew: "Add new disruptor config",
          "delete": "Delete disruptor",
          deleteConfirmation: "Are you sure you want to delete disruptor {id}?",
          editData: "Edit data"
        },
        editor: {
          editTitle: "Edit config for disruptor {id}",
          header: "Disruptor config",
          newTitle: "Create new disruptor"
        },
        endsOn: "Ends on",
        loading: "Loading disruptor configs...",
        none: "none",
        startsOn: "Starts on",
        title: "Disruptor Settings"
      },
      images: {
        actions: {
          addNew: "Add new disruptor image",
          "delete": "Delete image",
          deleteConfirmation: "Are you sure you want to delete disruptor image {id}?",
          editCorners: "Edit corners",
          editGroups: "Edit groups",
          setImage: "Set image",
          waitForCorners: "Corners need to be set first",
          waitForImage: "Image needs to be set first"
        },
        commodityGroupsEditor: {
          allCommodityGroups: "All commodity groups",
          excludeCommodityGroups: "Not in these commodity groups",
          includeCommodityGroups: "Only for these commodity groups",
          title: "Edit commodity groups for disruptor image {id}"
        },
        cornersEditor: {
          title: "Edit corners for disruptor image {id}"
        },
        image: "Image",
        imageEditor: {
          editTitle: "Set image for disruptor image {id}",
          newTitle: "Create new disruptor image",
          pleaseSelectImage: "Please select an image",
          selectNewImage: "Select new image...",
          selectedAreaSize: "Selected area:",
          selectedAreaTooSmall: "The selected area is smaller than the minimum recommended size:"
        },
        loading: "Loading disruptor images...",
        title: "Disruptor Image Settings"
      },
      settings: "Disruptor Settings",
      targets: {
        branches: "Target retail formats",
        stores: "Target stores",
        tenants: "Target tenants"
      }
    },
    help: {
      triggerStrategy: {
        detectionCorrect: "Random Check is triggered if Live DSI confirmed at least one; cashier isn't asked for confirmation",
        detectionCorrectOrNotConfirmed: "Random Check is triggered if Live DSI or cashier confirms at least one",
        detectionExists: "Random Check is triggered if cashier confirms at least one; Live DSI is ignored when negative"
      }
    },
    imageDetections: {
      thresholdSettings: "Image Detection Threshold Settings",
      triggerStrategy: "Image Detection Trigger Strategy"
    },
    itemsToCheckLargeBasket: "# check items large purchase",
    itemsToCheckSmallBasket: "# check items small purchase",
    layer: "Layer",
    multiple: "multiple",
    noSettingsFound: "No store settings were found for {storeName}",
    piecesShortForm: "pc.",
    quota: "Quota",
    randomCheck: "Random Check Settings",
    randomCheckAtCart: "Random check at cart",
    randomCheckQuota: "Random check quota",
    settingsRandomCheck: "Settings random check",
    stores: "Stores",
    submitForEverythingDialog: {
      confirm: "Override all settings",
      message: "This will override all settings in all stores you have access to.",
      title: "Override all settings for all stores?"
    },
    tenants: "Tenants",
    thresholdLargeBasket: "Considered a large purchase at",
    timeError: "End time has to be grater than start time",
    validations: {
      smallCheckItemsCannotExceedItemsToCheckLargeBasket: "has to be smaller than the # check items large purchase",
      smallCheckItemsCannotExceedLargeBasketThreshold: "has to be smaller than the large basket threshold"
    },
    withUser: "With Login",
    withoutUser: "Without Login"
  },
  stores: "Stores",
  success: "Success",
  supportInfo: {
    addPicture: "Add picture",
    dropPicture: "Drop picture here",
    generalNotes: "General notes",
    noImage: "The selected file is not an image",
    noSections: "There are no sections yet. Create some to get started.",
    pasteDropPicture: "Or Paste/Drop a picture",
    pictureNotes: "Notes regarding the picture",
    removePicture: "Remove picture",
    sections: {
      errors: {
        mustBeUnique: "Section names must be unique",
        required: "Required"
      },
      link: "Configure Sections",
      title: "Configure Sections"
    },
    selectPicture: "Select a picture",
    title: "Support Information"
  },
  surveys: {
    add: "Add survey",
    "delete": "Delete selected surveys",
    edit: "Edit survey",
    "export": {
      answer: "Answer",
      end: "End",
      generalInfo: "General Information",
      icon: "Excel Export",
      id: "ID",
      optionRemoved: "[This option has been removed]",
      options: "Options",
      smileyOptions: {
        dissatisfied: "Dissatisfied",
        neutral: "Neutral",
        satisfied: "Satisfied"
      },
      start: "Start",
      timestamp: "Timestamp",
      type: "Type"
    },
    hideLayer: "Hide layer selection",
    newOptionPlaceholder: "New Option",
    question: "Question",
    select: {
      checkbox: "Checkbox",
      radio: "Radio",
      smiley: "Smiley",
      text: "Text",
      title: "Select Question Type"
    },
    settings: "Settings",
    showLayer: "Show layer selection",
    slots: {
      during_payment: "During and after Payment"
    },
    survey: {
      active: "Active",
      askCustomer: "Ask Customer for participation",
      cart: "Cart",
      description: "Description",
      hideComments: "Hide responses",
      interval: "Days",
      mobile: "Mobile App",
      mobileApp: "Mobile App",
      name: "Survey Name",
      noResults: "No Results Available Yet",
      optional: "Ask Customer for participation",
      repeat: "Repeat",
      repeatAfter: "After",
      showAllComments: "Show all responses",
      showToAnonymous: "Show to anonymous users",
      slot: "Slot",
      title: "Title",
      validity: "Validity"
    },
    surveyQuestions: "Survey Questions",
    table: {
      active: "Active",
      askCustomer: "Ask Customer",
      cart: "Cart",
      endsOn: "End Date",
      mobileApp: "Mobile App",
      name: "Name",
      optional: "Optional",
      questionCount: "Questions",
      slot: "Slot",
      startsOn: "Start Date",
      validFrom: "From",
      validTo: "To"
    },
    textAnswer: "Text Answer"
  },
  table: {
    calendarWeekShort: "CW",
    clearFilters: "Clear filters",
    deselectAll: "Deselect all",
    exportCopy: "To clipboard",
    exportDownload: "Download",
    exportTitle: "Export",
    hideAllColumns: "Hide all",
    lastSevenDays: "Last 7 days",
    lastThirtyDays: "Last 30 days",
    loading: "Loading",
    noResults: "No results",
    rangeMax: "Max",
    rangeMin: "Min",
    reset: "Reset",
    resetAll: "Reset table settings",
    selectColumns: "Select visible columns",
    selected: "{count, number} selected",
    showAllColumns: "Show all",
    thisMonth: "This month",
    thisWeek: "This week",
    thisYear: "This year",
    today: "Today"
  },
  test: "TEST",
  tickets: {
    anotherReason: {
      label: "Another reason"
    },
    cashierRequest: {
      label: "Open additional checkout"
    },
    changeItemCount: {
      label: "Could not change item amount"
    },
    closed: " closed",
    expensiveProductRemoved: {
      label: "Expensive item removed"
    },
    itemsRemovedBeforePayment: {
      label: "Items removed"
    },
    multiSession: {
      label: "This customer has already made several purchases with the EASY Shopper today."
    },
    operationUnclear: {
      label: "Operation EASY Shopper unclear"
    },
    priceDifference: {
      label: "Price difference"
    },
    productAdvice: {
      label: "Product advice required"
    },
    productNotFound: {
      label: "Product not found"
    },
    productsRemoved: {
      label: "Removed Products",
      message: "Products removed from the basket: ",
      quantity: "Quantity: {quantity}",
      totalAmount: "Total: {totalAmount} "
    },
    scanWithoutRunningSession: {
      label: "Scan without session"
    },
    scannerProblems: {
      label: "Problems with the scanner"
    },
    sos: {
      label: "SOS"
    },
    taggedCustomer: {
      label: "Customer Mark",
      message: "Marked customer logged in"
    },
    tooManyDiscountedProducts: {
      label: "Too many discounted items"
    },
    unknownBarcode: {
      label: "Unknown barcode"
    },
    unknownBarcodeSOS: {
      label: "Help Request for unknown barcode"
    },
    weightDeviation: {
      label: "Weight deviation"
    },
    weightOnCartWithoutSession: {
      label: "Weight on cart without session"
    }
  },
  timeFrame: "Time frame",
  today: "Today",
  trainingMaterial: {
    app: "EASY Shopper - App",
    express: "EASY Shopper - Express",
    lightMaintenance: "EASY Light Maintenance - Cleaning, Charging & Defect Report",
    maintenance: "EASY Shopper Maintenance - Cleaning, Charging & Defect Report",
    offers: "EASY Shopper - Offers, recipes, help & FAQ",
    payment: "EASY Shopper - Payment, promotions & navigation",
    quickGuide: "EASY Shopper - Quick guide & registration",
    receiptHelpFAQ: "EASY Shopper - Recipes, Help & FAQ",
    resetChrome: "EASY Handy - Reset Chrome",
    resetChromeByScan: "EASY Shopper - Reset Chrome via scan",
    shoppingList: "EASY Shopper - Shopping list, cart & product search",
    shoppingListCart: "EASY Shopper - Shopping List & Shopping Cart",
    storeManagerCustomers: "Store Manager - Customers",
    storeManagerItems: "Store Manager - Items & Technology",
    storeManagerManagement: "Store Manager - Management & Account"
  },
  undo: "Undo",
  undoAll: "Undo all",
  unknown: "Unknown",
  unknownError: "Unknown Error: {error}",
  unsavedConfirmation: "Changes you made may not be saved.",
  updateAvailable: {
    cancel: "Not now",
    message: "Reload the page to use the newest version",
    reload: "Reload page now",
    title: "Update available!"
  },
  upload: "Upload",
  uploadFiles: {
    browse: "Browse file",
    deleteActiveImage: "Delete active Image",
    dragAndDrop: "Drag and drop files here",
    error: {
      "415": "Only .png files are allowed"
    },
    fileTooLarge: "{maxMbSize, plural, other {File size cannot exceed #MB}}",
    noFileFound: "{fileCount, plural, one {No file found} other {No files found}}",
    or: "or",
    reallyDelete: "Delete this image? The previous image will be restored if available.",
    title: "Upload"
  },
  userGuid: "User Guid",
  users: {
    attributes: {
      connectedStores: "Connected Stores",
      firstName: "First Name",
      lastActiveOn: "Last Active On",
      lastName: "Last Name",
      notifications: "Notifications",
      onlineStatus: "Online Status",
      roles: "Roles",
      rolesAndNotifications: "Roles & ",
      userDetails: "User Details",
      userType: {
        global: "Global",
        store: "Store"
      },
      username: "Username"
    },
    dektopViewAction: "Switch to desktop view",
    loadMore: "Load More",
    mobileViewAction: "Switch to mobile view",
    moreConnectedStores: "more"
  },
  validation: {
    email: "No valid e-mail address",
    length: "Minimum length: {length, number}/8 characters",
    mandatory: "Mandatory field",
    passwordFailure: "Failed to update password: ",
    passwordRepeat: "Must match new password",
    passwordSaved: "Password saved successfully",
    unknownError: "Unknown error",
    userAlreadyUsed: "This username is already taken",
    userName: "Must be between {min, plural, other {#}} and {max, plural, other {# characters}}. Spaces are not allowed.",
    wrongPassword: "Wrong password"
  },
  validations: {
    exactLength: "{count, plural, one {Has to be exactly # digit long} other {Has to be exactly # digits long}}",
    exactLength2: "Has to be exactly {count1} or {count2} digits long",
    invalidRegExp: "Not a valid RegExp",
    invalidType: "Invalid type",
    max: "Has to be less than or equal to {max, number}",
    maxGreaterThanMin: "Must be greater than the minimum",
    min: "Has to be greater than or equal to {min, number}",
    minLength: "{count, plural, one {Has to be at least # character long} other {Has to be at least # characters long}}",
    required: "Required"
  },
  versionConflict: "Another user has changed the data. Please reload the page and try again.",
  versions: {
    checking: "Checking for update",
    inProgress: "Update in progress",
    lookForUpdate: "Look for update",
    scheduled: "Update ready, page reload scheduled",
    title: "Versions",
    ui: "Store Manager UI"
  },
  voucherPromotion: {
    always: "Always",
    broadcast: "Broadcast",
    color: "Progress bar color",
    colorTitle: "Color",
    custom: "Custom",
    customColor: "Custom color",
    customerNote: "Customer note",
    date: "Date",
    "default": "Default",
    description: "Customer info",
    disclaimer: "Please note that discounts, vouchers and coupons and loyalty passes, as well as EDEKA app promotions can reduce the final amount at the checkout. To receive a €5 voucher, the final total at the checkout must be €75.",
    isActive: "Currently active",
    minBasketAmount: "Minimum basket amount",
    permanent: "Permanent",
    preview: "Preview:",
    promotion: "Promotion",
    subtitle: "Subtitle",
    subtitleHelper: "Use '{minAmount}' and '{voucherAmount}' as placeholders for the promotion values defined below",
    templateSource: "The following progress bar promotion info is derived from the store setup template and will be saved in the store soon",
    title: "Title",
    voucherAmount: "Voucher amount",
    vouchers: "Vouchers"
  },
  yes: "Yes"
} as const;

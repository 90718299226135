import { z } from 'zod';

export type HistoryItemChange = z.infer<typeof HistoryItemChange>;
export type HistoryItem = z.infer<typeof HistoryItem>;

export const HistoryItemChange = z.object({
    path: z.string(),
    oldValue: z.unknown().nullish(),
    newValue: z.unknown().nullish(),
});

export const HistoryItem = z.object({
    action: z.enum(['added', 'changed', 'removed', 'restored']),
    modifiedBy: z.string(),
    modifiedOn: z.coerce.date(),
    changes: HistoryItemChange.array(),
});

import { v4 as guid } from 'uuid';
import CPromise from './cPromise';
import { isPromise } from './isPromise';
import sleep from './sleep';

export interface QueueActionOptions<TTag = unknown> {
    name?: string;
    tag?: TTag;
    retries: number;
    delay: number;
    backoff: boolean;
    priority: number;
    requeueOnRetry?: boolean;
    onRetry?: (error: unknown, name: string | undefined, attempt: number) => void;
    onError?: (error: unknown, name: string | undefined) => void;
}

export interface QueueOptions<TTag = unknown> extends QueueActionOptions<TTag> {
    parallel: number;
}

export interface QueueEntry<TValue, TTag = unknown> extends CPromise<TValue>, QueueActionOptions<TTag> {
    id: string;
    action(options: QueueActionOptions<TTag>): TValue | PromiseLike<TValue>;
    resolve(value?: TValue | PromiseLike<TValue> | undefined): void;
    reject(reason?: unknown): void;
}

export default class Queue<TValue = unknown, TTag = unknown> {
    readonly options: QueueOptions<TTag>;
    private queue: QueueEntry<TValue, TTag>[] = [];
    private workers = 0;

    constructor(options: Partial<QueueOptions<TTag>> = {}) {
        this.options = {
            retries: options.retries ?? 0,
            delay: options.delay ?? 0,
            backoff: options.backoff ?? false,
            priority: options.priority ?? 0,
            parallel: options.parallel ?? 1,
            ...options,
        };
    }

    schedule<TActionValue extends TValue>(
        action: (options: QueueActionOptions<TTag>) => TActionValue | PromiseLike<TActionValue>,
        {
            name = action.name,
            tag,
            retries = this.options.retries,
            delay = this.options.delay,
            backoff = this.options.backoff,
            priority = this.options.priority,
            requeueOnRetry = this.options.requeueOnRetry,
            onRetry = this.options.onRetry,
            onError = this.options.onError,
        }: Partial<QueueActionOptions<TTag>> = {},
    ): QueueEntry<TActionValue, TTag> {
        const id = guid();
        let resolve, reject;
        const promise = new CPromise<TActionValue>((_resolve, _reject, onCancel) => {
            [resolve, reject] = [_resolve, _reject];
            onCancel(() => this.cancel((entry) => entry.id === id));
        });

        const entry: QueueEntry<TActionValue, TTag> = Object.assign(promise, {
            id,
            action,
            resolve: resolve!,
            reject: reject!,
            name,
            tag,
            retries,
            delay,
            backoff,
            priority,
            requeueOnRetry,
            onRetry,
            onError,
        });

        if (priority === Infinity) this.queue.push(entry);
        else {
            let index = this.queue.findIndex((entry) => entry.priority > priority);
            if (index === -1) index = this.queue.length;
            this.queue.splice(index, 0, entry);
        }

        setTimeout(() => this._worker());
        return entry;
    }

    get last(): QueueEntry<TValue, TTag> | undefined {
        return this.queue[this.queue.length - 1];
    }

    get size(): number {
        return this.queue.length + this.workers;
    }

    clear(resolve?: TValue): void {
        for (const item of this.queue) {
            if (resolve !== undefined) item.resolve(resolve);
            else item.reject(Object.assign(Error('Canceled'), { canceled: true }));
        }
        this.queue = [];
    }

    cancel(predicate: (entry: QueueEntry<TValue, TTag>) => boolean): void {
        this.queue = this.queue.filter((entry) => {
            if (predicate(entry)) {
                entry.cancel();
                return false;
            }
            return true;
        });
    }

    hasTag(tag: TTag): boolean {
        return this.queue.some((entry) => entry.tag === tag);
    }

    cancelTag(tag: TTag): void {
        this.cancel((entry) => entry.tag === tag);
    }

    async replace<TActionValue extends TValue>(
        action: (options: QueueActionOptions<TTag>) => TActionValue,
        {
            name = action.name,
            tag,
            retries = this.options.retries,
            delay = this.options.delay,
            backoff = this.options.backoff,
            priority = this.options.priority,
            requeueOnRetry = this.options.requeueOnRetry,
            onRetry = this.options.onRetry,
            onError = this.options.onError,
        }: Partial<QueueActionOptions<TTag>> = {},
    ): Promise<TActionValue> {
        const queue = this.queue;
        this.queue = [];
        const result = await this.schedule(action, { name, tag, retries, delay, backoff, priority, requeueOnRetry, onRetry, onError });
        for (const item of queue) item.resolve(result);
        return result;
    }

    get entries(): QueueEntry<TValue, TTag>[] {
        return [...this.queue];
    }

    private async _worker(start = performance.now()) {
        let entry: QueueEntry<TValue, TTag> | undefined;

        if (this.workers >= this.options.parallel || this.queue[0]?.priority === Infinity || !(entry = this.queue.shift())) {
            return;
        }

        this.workers++;
        const { action, resolve, reject, name, backoff, requeueOnRetry, onRetry, onError, priority, ...item } = entry;
        let { retries, delay } = item;

        try {
            for (let attempt = 0; ; attempt++) {
                try {
                    let result = action({ ...entry, retries, delay });
                    if (isPromise(result)) result = await result;
                    resolve(result);
                    break;
                } catch (e) {
                    if (retries > 0) {
                        onRetry?.(e, name, attempt);

                        const currentDelay = delay;
                        retries--;
                        if (backoff) delay *= 2;

                        if (requeueOnRetry) {
                            entry.retries = retries;
                            entry.delay = delay;
                            entry.priority = Infinity;
                            this.queue.push(entry);

                            setTimeout(() => {
                                entry.priority = priority;
                                const index = this.queue.indexOf(entry);
                                const newIndex = this.queue.findIndex((entry) => entry.priority > entry.priority);
                                if (index != -1 && newIndex != -1 && index !== newIndex) {
                                    this.queue.splice(index, 1);
                                    this.queue.splice(newIndex, 0, entry);
                                }
                                this._worker();
                            }, currentDelay);

                            return;
                        }

                        await sleep(currentDelay);
                    } else {
                        if (onError) onError(e, name);
                        else throw e;
                    }
                }
            }
        } catch (e) {
            reject(e);
        } finally {
            this.workers--;
            if (performance.now() - start < 10) this._worker(start);
            else setTimeout(() => this._worker());
        }
    }
}

import type { FlattenDict, ReactCreateTranslatorResult } from 'schummar-translate/react';

type Dict = FlattenDict<{
    ok: string;
    cancel: string;
    undo: string;
    deposit: string;
    capacity: `${any}{capacity}${any}`;
    single: string;
    bottle: string;
    'cgLocalKey.babykost': string;
    'cgLocalKey.brot_kuchen': string;
    'cgLocalKey.brotaufstrich': string;
    'cgLocalKey.drogerieartikel': string;
    'cgLocalKey.fette_eier': string;
    'cgLocalKey.fisch': string;
    'cgLocalKey.freizeit': string;
    'cgLocalKey.garten': string;
    'cgLocalKey.genussmittel': string;
    'cgLocalKey.getraenke': string;
    'cgLocalKey.diverse_nonfood': string;
    'cgLocalKey.haushalt': string;
    'cgLocalKey.hygiene': string;
    'cgLocalKey.kaffee_tee_kakao': string;
    'cgLocalKey.kaese': string;
    'cgLocalKey.kleidung': string;
    'cgLocalKey.knabbereien': string;
    'cgLocalKey.konserven': string;
    'cgLocalKey.mehl_salz_zucker': string;
    'cgLocalKey.molkereiprodukte': string;
    'cgLocalKey.nahrungsmittel': string;
    'cgLocalKey.obst_und_gemuese': string;
    'cgLocalKey.reinigungsmittel': string;
    'cgLocalKey.speisezutaten': string;
    'cgLocalKey.suesswaren': string;
    'cgLocalKey.tiefkuehlkost': string;
    'cgLocalKey.tierbedarf': string;
    'cgLocalKey.wurst_fleisch': string;
    'cgLocalKey.feinkost': string;
    'input.noOptionsMessage': string;
    'input.select': string;
    'priceTypes.discount': string;
    'priceTypes.action': string;
    'priceTypes.free': string;
    'coordinateSelector.pleaseSelect': string;
    'coordinateSelector.search': string;
    'error.code': `${any}{code}${any}ok`;
    copied: string;
    'navigation.youAreHere': string;
    'deals.remainingDays': `${any}{days}${any}`;
    'deals.remainingHours': `${any}{hours}${any}`;
    'recommendation.labels.crossSelling': string;
    'recommendation.labels.newProductListings': string;
    'recommendation.labels.specialOffers': string;
    'deals.notCombinable': string;
    'deals.notCombinable2': string;
    'deals.paybackDisclaimer': string;
    'deals.automatic': string;
    loading: string;
    'disruptor.allDeals': string;
    'disruptor.addToList': string;
    'disruptor.title': string;
    nutrition: Record<string, string>;
    'amountConfig.regularPrice': string;
    'amountConfig.reducedPrice': string;
}>;

let _instance: ReactCreateTranslatorResult<Dict> | undefined;
export const configureTranslations = (instance: ReactCreateTranslatorResult<any>) => {
    _instance = instance;
};

export const getTranslations = () => {
    if (!_instance) throw Error('configureTranslations must be called before using any translations in uiShared!');
    return _instance;
};

import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import css from './passwordField.module.less';

type Props = { reveal?: boolean } & React.HTMLProps<HTMLInputElement>;

export function PasswordField({ reveal, ...rest }: Props) {
    const [revealed, setRevealed] = useState(false);

    return (
        <div className={css.container}>
            <input autoComplete="password" {...rest} type={revealed ? 'text' : (rest.type ?? 'password')} />
            {reveal && (
                <FontAwesomeIcon
                    className={css.revealButton}
                    fixedWidth
                    icon={revealed ? faEyeSlash : faEye}
                    onClick={() => setRevealed(!revealed)}
                />
            )}
        </div>
    );
}

import { HubConnectionBuilder, LogLevel, IHttpConnectionOptions, HubConnection, RetryContext } from '@microsoft/signalr';

import { Connection, ConnectionStatus } from './connection';

export type SignalRConnectionOptions = IHttpConnectionOptions & { debug?: (...messages: any[]) => void };

export default class SignalRConnection extends Connection {
    protected connection: HubConnection;
    protected stopped = false;

    constructor(url: string, options: SignalRConnectionOptions = {}) {
        super();

        const retryPolicy = {
            nextRetryDelayInMilliseconds({ previousRetryCount, retryReason }: RetryContext) {
                const delay = Math.min(1000 * 2 ** previousRetryCount, 60000);
                if (options.debug) options.debug(`SignalR connection lost. Retrying in ${(delay / 1000).toFixed(1)}s`, retryReason);
                return delay;
            },
        };

        this.connection = new HubConnectionBuilder()
            .withUrl(url, options)
            .withAutomaticReconnect(retryPolicy)
            .configureLogging({
                log(logLevel, message) {
                    if (logLevel >= LogLevel.Error) options.debug?.(message);
                },
            })
            .build();

        this.notifyStatus(ConnectionStatus.Connecting);
        this.connection.start().then(() => this.notifyStatus(ConnectionStatus.Connected));
        this.connection.onreconnecting(() => this.notifyStatus(ConnectionStatus.Connecting));
        this.connection.onreconnected(() => this.notifyStatus(ConnectionStatus.Connected));
        this.connection.onclose(() => this.notifyStatus(ConnectionStatus.Disconnected));
    }

    publish(topic: string, data: any) {
        this.connection.invoke(topic, data);
    }

    enableTopic(topic: string) {
        this.connection.on(topic, (payload: any) => this.notify(topic, topic, payload));
    }

    disableTopic(topic: string) {
        this.connection.off(topic);
    }

    quit() {
        this.stopped = true;
        this.connection.stop();
    }
}

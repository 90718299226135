const processEnv = typeof globalThis.process !== 'undefined' ? (globalThis.process.env ?? {}) : {};

export function parseString(value?: string): string | undefined {
    if (typeof value !== 'string' || value.toLowerCase() === '#none') return undefined;
    return value;
}

export function parseBoolean(value?: string): boolean | undefined {
    const b = parseString(value);
    if (!b) return undefined;
    return !b.match(/^(false|no|0)$/i);
}

export function parseNumber(value?: string): number | undefined {
    const n = parseString(value);
    if (!n || isNaN(Number(n))) return undefined;
    return Number(n);
}

export function parseJson<T>(value?: string): T | undefined {
    const s = parseString(value);
    if (!s) return undefined;
    try {
        return JSON.parse(s);
    } catch {
        return undefined;
    }
}

function envString(key: string): string | undefined;
function envString(key: string, def: string): string;
function envString(key: string, def?: string) {
    return parseString(processEnv[key]) ?? def;
}

function envBoolean(key: string): boolean | undefined;
function envBoolean(key: string, def: boolean): boolean;
function envBoolean(key: string, def?: boolean) {
    return parseBoolean(processEnv[key]) ?? def;
}

function envNumber(key: string): number | undefined;
function envNumber(key: string, def: number): number;
function envNumber(key: string, def?: number) {
    return parseNumber(processEnv[key]) ?? def;
}

function envJson(key: string): any | undefined;
function envJson(key: string, def: any): any;
function envJson(key: string, def?: any) {
    return parseJson(processEnv[key]) ?? def;
}

const required =
    <T>(fn: (key: string) => T | undefined) =>
    (key: string) => {
        const value = fn(key);
        if (value === undefined) {
            throw Error(`Required env var ${key} not set.`);
        }

        return value;
    };

envString.required = required(envString);
envBoolean.required = required(envBoolean);
envNumber.required = required(envNumber);
envJson.required = required(envJson);

export const env = Object.assign(envString, { boolean: envBoolean, number: envNumber, json: envJson });

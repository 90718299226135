import localforage from 'localforage';

import sleep from '@whiz-cart/node-shared/sleep';

export class SwSync {
    constructor(topic, update) {
        this.topic = topic;
        this.update = update;
        this.storage = this.initStorage(topic);
    }

    async initStorage(topic) {
        const storage = localforage.createInstance({ name: topic });

        const keys = await storage.keys();
        const maxKey = Math.max(0, ...keys);
        this.nextKey = maxKey + 1;

        return storage;
    }

    async queue(item) {
        const storage = await this.storage;
        const key = String(this.nextKey++);
        await storage.setItem(key, item);

        const swReg = await navigator?.serviceWorker?.ready;
        if (!swReg) {
            console.error('No service worker!');
            return;
        }
        swReg.sync.register(this.topic);
    }

    async process() {
        const storage = await this.storage;
        const keys = await storage.keys();
        if (keys.length === 0) return;

        const key = String(Math.min(...keys));
        const item = await storage.getItem(key);

        try {
            await this.update(item);
            storage.removeItem(key);
            console.debug('Sent:', item);
        } catch (error) {
            console.error('Could not send:', item, error);
            if (!error.response || error.response.status === 500) await sleep(10000);
            else storage.removeItem(key);
        }
        return this.process();
    }

    watch() {
        self.addEventListener('sync', (event) => {
            if (event.tag === this.topic) {
                event.waitUntil(this.process());
            }
        });
    }
}

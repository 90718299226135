import { z } from 'zod';

export const PaletteColor = z
    .object({
        main: z.string(),
        light: z.string(),
        dark: z.string(),
        contrastText: z.string(),
    })
    .partial();

export const SimplePaletteColor = z.object({
    main: z.string(),
    contrastText: z.string(),
});

export const Palette = z.object({
    primary: PaletteColor,
    secondary: PaletteColor,
    discount: SimplePaletteColor,
    free: SimplePaletteColor,
    cartBackground: PaletteColor,
    navigationBackground: PaletteColor,
});

export const BrandImages = z.object({
    // Welcome tutorial
    loginTutorial01: z.string().nullable(),
    loginTutorial02: z.string().nullable(),
    loginTutorial03: z.string().nullable(),

    // Logos
    logoBlack: z.string().nullable(),
    logoWhite: z.string().nullable(),
    logoColor: z.string().nullable(),
    logoCompany: z.string().nullable(),

    // Background
    cartBackground: z.string().nullable(),
});

export const BrandIcons = z.object({
    search: z.string().nullable(),
    promotion: z.string().nullable(),
    sosActive: z.string().nullable(),
    sosInactive: z.string().nullable(),
    lockActive: z.string().nullable(),
    lockInactive: z.string().nullable(),
    cart: z.string().nullable(),
    cartPlus: z.string().nullable(),
    navigation: z.string().nullable(),
    arrowLeftTutorial: z.string().nullable(),
    arrowRightTutorial: z.string().nullable(),
    arrowBack: z.string().nullable(),
    user: z.string().nullable(),
    location: z.string().nullable(),
    edit: z.string().nullable(),
    exit: z.string().nullable(),
    startShopping: z.string().nullable(),
    startPayment: z.string().nullable(),
    payAtRegister: z.string().nullable(),
    navigate: z.string().nullable(),
});

export const CustomerCard = z.object({
    id: z.string(),
    name: z.string().nullable(),
    image: z.string().nullable(),
    color: SimplePaletteColor,
    primary: z.boolean().default(false),
    pointsText: z.string().nullable().default(null),
});

export const Brand = z.object({
    _id: z.string(),
    palette: Palette.partial().default({}),
    images: BrandImages.partial().default({}),
    icons: BrandIcons.partial().default({}),
    customerCards: z.record(z.string(), CustomerCard).default({}),
    borderRadius: z.union([z.number(), z.string()]).optional(),
    headerDisplayTotal: z.boolean().optional(),
    headerUsePrimaryColor: z.boolean().optional(),
    priceLayoutAlt: z.boolean().optional(),
});

export type PaletteColor = z.infer<typeof PaletteColor>;
export type SimplePaletteColor = z.infer<typeof SimplePaletteColor>;
export type Palette = z.infer<typeof Palette>;
export type BrandImages = z.infer<typeof BrandImages>;
export type BrandIcons = z.infer<typeof BrandIcons>;
export type CustomerCard = z.infer<typeof CustomerCard>;
export type Brand = z.infer<typeof Brand>;

import _ from 'lodash';

const transform = <T extends (...args: any[]) => any>(fn: T) => {
    let lastInput: any;
    let lastValue: any;

    return function (this: any, ...args: Parameters<T>): ReturnType<T> {
        const { props, state, context } = this || {};
        const input = args.concat(props, state, context);
        if (_.isEqual(input, lastInput)) return lastValue;
        lastInput = input;
        lastValue = fn.bind(this)(...args);
        return lastValue;
    };
};

/** Decorate a function with it. Caches the last result. If called again with the same parameters (deep comparison), returns last result.  */

function memoize(): void;
function memoize<T extends (...args: any[]) => any>(fn: T): (...args: Parameters<T>) => ReturnType<T>;
function memoize<T extends (...args: any[]) => any>(fn?: T) {
    if (fn) return transform(fn);

    return (target: any, key: string, descriptor: PropertyDescriptor) => {
        descriptor.value = transform(descriptor.value);
    };
}

export default memoize;

import { Path, Value } from 'cross-state';
import { get, isEqual, mapValues } from 'lodash';
import { Action, AnyAction, Reducer, Unsubscribe, applyMiddleware, compose, createStore as reduxCreateStore } from 'redux';
import ReduxThunk from 'redux-thunk';

export interface ReducerWithState<S = any, A extends Action = AnyAction, R = any> extends Reducer<S, A> {
    (state: S | undefined, action: A, rootState: R): S;
}

export const createStore = <TReducers extends Record<string, ReducerWithState<any, any, any>>>(reducers: TReducers) => {
    type S = { [K in keyof TReducers]: ReturnType<TReducers[K]> };
    type A = { [K in keyof TReducers]: Parameters<TReducers[K]>[1] }[keyof TReducers];

    const combinedReducers = (state: S | undefined, action: A): S =>
        mapValues(reducers, (reducer, name) => reducer(state?.[name], action, state));

    const store = reduxCreateStore(
        combinedReducers,
        compose(
            applyMiddleware(ReduxThunk),
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : (f: any) => f,
        ),
    );

    /** Return a promise that will be resolved once the given property is set !== undefined. The property can be selected by string, e.g. 'config.scanner', or by function, e.g. state => state.config.scanner */
    function awaitState<T extends Path<S>>(prop: T): Promise<Value<S, T>>;
    function awaitState<T>(prop?: (state: S) => T): Promise<T>;
    function awaitState(prop?: any) {
        return new Promise((resolve) => {
            const check = () => {
                let v: any = store.getState();
                if (typeof prop === 'function') {
                    v = prop(v);
                } else if (typeof prop === 'string') {
                    v = prop.split('.').reduce((v, i) => v?.[i], v);
                }
                if (v !== undefined) {
                    cancel();
                    resolve(v);
                }
            };

            const cancel = store.subscribe(check);
            check();
        });
    }

    function subscribeState<T extends Path<S>>(map: T, callback: (state: Value<S, T>, prev: Value<S, T>) => void): Unsubscribe;
    function subscribeState<T>(map: (state: S) => T, callback: (state: T, prev: T) => void): Unsubscribe;
    function subscribeState(map: string | ((state: any) => any), callback: (state: any, prev: any) => void) {
        const _map = typeof map === 'string' ? (state: any) => get(state, map) : map;
        let lastState: any;
        const update = () => {
            const state = _map(store.getState());
            if (!isEqual(state, lastState)) {
                const prev = lastState;
                lastState = state;
                callback(state, prev);
            }
        };
        update();
        return store.subscribe(update);
    }

    const storeWithHelpers = Object.assign(store, {
        awaitState,
        subscribeState,
    });

    // Debug
    Object.assign(window, { store: storeWithHelpers });
    return storeWithHelpers;
};

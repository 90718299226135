import { z } from 'zod';

export type Timestamped = z.infer<typeof Timestamped>;
export type Timestamp = z.infer<typeof Timestamp>;

export const Timestamped = z.object({
    createdOn: z.coerce.date().nullish(),
    modifiedOn: z.coerce.date().nullish(),
    modifiedBy: z.string().nullish(),
    deletedOn: z.coerce.date().nullish(),
    syncedOn: z.coerce.date().nullish(),
    syncGuid: z.string().nullish(),
});

export const Timestamp = z.object({
    on: z.coerce.date(),
    by: z.string().nullish(),
});

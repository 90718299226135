import { MultiMap } from '../multiMap';

export enum ConnectionStatus {
    Disconnected = 'disconnected',
    Connecting = 'connecting',
    Connected = 'connected',
}

export abstract class Connection {
    protected subscriptions = new MultiMap<string, (payload: any, topic: string) => void>();
    protected statusSubscriptions = new Set<(status: ConnectionStatus) => void>();
    protected status = ConnectionStatus.Connecting;

    subscribe(topic: string, callback: (data: any, topic: string) => void) {
        if (this.subscriptions.get(topic).size === 0 && this.enableTopic) {
            this.enableTopic(topic);
        }
        this.subscriptions.add(topic, callback);

        return () => {
            if (this.subscriptions.delete(topic, callback) && this.subscriptions.get(topic).size === 0 && this.disableTopic) {
                this.disableTopic(topic);
            }
        };
    }

    subscribeStatus(callback: (status: ConnectionStatus) => void) {
        this.statusSubscriptions.add(callback);
        callback(this.status);
        return () => this.statusSubscriptions.delete(callback);
    }

    protected notify(subscribedTopic: string, topic: string, payload: any) {
        for (const callback of this.subscriptions.get(subscribedTopic)) callback(payload, topic);
    }

    protected notifyStatus(status: ConnectionStatus) {
        for (const callback of this.statusSubscriptions) callback(status);
    }

    abstract quit(): void;

    abstract publish(topic: string, payload: any): void;

    protected abstract enableTopic?(topic: string): void;
    protected abstract disableTopic?(topic: string): void;
}

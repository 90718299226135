import { createStore, type Store } from 'cross-state';

interface Config {
    storeGuidHook: () => string | undefined;
    lineItem: {
        displayDiscounts: boolean;
    };
    currency: string | undefined;
}

export const uiSharedConfig: Store<Config> = createStore<Config>({
    storeGuidHook: () => undefined,
    lineItem: {
        displayDiscounts: false,
    },
    currency: undefined,
});

export class URLUtils {
    origin: string;
    href: string;
    protocol: string;
    username: string;
    password: string;
    host: string;
    hostname: string;
    port: string;
    pathname: string;
    search: string;
    hash: string;

    constructor(url: string, baseURL?: string) {
        const m = String(url)
            .replace(/^\s+|\s+$/g, '')
            .match(/^([^:/?#]+:)?(?:\/\/(?:([^:@/?#]*)(?::([^:@/?#]*))?@)?(([^:/?#]*)(?::(\d*))?))?([^?#]*)(\?[^#]*)?(#[\s\S]*)?/);
        if (!m) {
            throw new RangeError();
        }
        let protocol = m[1] || '';
        let username = m[2] || '';
        let password = m[3] || '';
        let host = m[4] || '';
        let hostname = m[5] || '';
        let port = m[6] || '';
        let pathname = m[7] || '';
        let search = m[8] || '';
        const hash = m[9] || '';
        if (baseURL !== undefined) {
            const base = new URLUtils(baseURL);
            const flag = protocol === '' && host === '' && username === '';
            if (flag && pathname === '' && search === '') {
                search = base.search;
            }
            if (flag && pathname.charAt(0) !== '/') {
                pathname =
                    pathname !== ''
                        ? ((base.host !== '' || base.username !== '') && base.pathname === '' ? '/' : '') +
                          base.pathname.slice(0, base.pathname.lastIndexOf('/') + 1) +
                          pathname
                        : base.pathname;
            }

            // dot segments removal
            const output: string[] = [];
            pathname
                .replace(/^(\.\.?(\/|$))+/, '')
                .replace(/\/(\.(\/|$))+/g, '/')
                .replace(/\/\.\.$/, '/../')
                .replace(/\/?[^/]*/g, function (p) {
                    if (p === '/..') {
                        output.pop();
                    } else {
                        output.push(p);
                    }
                    return '';
                });
            pathname = output.join('').replace(/^\//, pathname.charAt(0) === '/' ? '/' : '');
            if (flag) {
                port = base.port;
                hostname = base.hostname;
                host = base.host;
                password = base.password;
                username = base.username;
            }
            if (protocol === '') {
                protocol = base.protocol;
            }
        }
        this.origin = protocol + (protocol !== '' || host !== '' ? '//' : '') + host;
        this.href =
            protocol +
            (protocol !== '' || host !== '' ? '//' : '') +
            (username !== '' ? username + (password !== '' ? ':' + password : '') + '@' : '') +
            host +
            pathname +
            search +
            hash;
        this.protocol = protocol;
        this.username = username;
        this.password = password;
        this.host = host;
        this.hostname = hostname;
        this.port = port;
        this.pathname = pathname;
        this.search = search;
        this.hash = hash;
    }

    static resolve(base: string, ...path: string[]) {
        return path.reduce((resolved, segment) => {
            const url = new URLUtils(segment, resolved.replace(/\/*$/, '/'));
            return url.pathname + url.search + url.hash;
        }, base);
    }
}

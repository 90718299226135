import { createTheme } from '@mui/material';
import { Brand, Palette, PaletteColor, SimplePaletteColor } from '@whiz-cart/node-shared/models/brand/brand';
import { defaultsDeep, get, mapValues, pick } from 'lodash';
import { DeepPartial } from 'redux';
import { defaultBrand } from './defaultBrand';
import { normalizeColor } from './normalizeColor';

const dummyTheme = createTheme();

export function resolveBrandColor<Key extends keyof Palette>(paletteKey: Key, paletteColor: Partial<PaletteColor>): Palette[Key] {
    const augmented = dummyTheme.palette.augmentColor({
        color: {
            ...paletteColor,
            main: paletteColor.main ?? '#ffffff',
        },
    });

    const normalized = mapValues(augmented, normalizeColor);

    if (Palette.shape[paletteKey] === SimplePaletteColor) {
        return pick(normalized, ['main', 'contrastText']) as Palette[Key];
    }

    return normalized;
}

export function resolveBrandColorPath(path: string, paletteColor: Partial<PaletteColor>) {
    const augmented = dummyTheme.palette.augmentColor({
        color: {
            ...paletteColor,
            main: paletteColor.main ?? '#ffffff',
        },
    });
    const normalized = mapValues(augmented, normalizeColor);

    const defaultValue = get(defaultBrand, path);
    try {
        PaletteColor.required().parse(defaultValue);
    } catch {
        return pick(normalized, ['main', 'contrastText']) as PaletteColor;
    }

    return normalized;
}

export function resolveBrandPalette(palette: DeepPartial<Palette>): Palette {
    return Object.fromEntries(
        Object.values(Palette.keyof().Values).map((paletteKey) => [paletteKey, resolveBrandColor(paletteKey, palette[paletteKey] ?? {})]),
    ) as Palette;
}

export function resolveBrand(brand: Brand): Brand {
    const palette = resolveBrandPalette(brand.palette);

    return defaultsDeep({}, brand, { palette }, defaultBrand);
}

import { z } from 'zod';

export const LocalazyCdn = z.object({
    projectUrl: z.string().nullish(),
    baseLocale: z.string().nullish(),
    timestamp: z.string().nullish(),
    files: z.record(
        z.object({
            file: z.string().nullish(),
            path: z.string().nullish(),
            library: z.string().nullish(),
            module: z.string().nullish(),
            buildType: z.string().nullish(),
            timestamp: z.string().nullish(),
            productFlavors: z.array(z.string()).nullish(),
            locales: z.array(
                z.object({
                    language: z.string(),
                    region: z.string(),
                    script: z.string(),
                    isRtl: z.boolean(),
                    name: z.string(),
                    localizedName: z.string(),
                    uri: z.string(),
                    timestamp: z.string(),
                }),
            ),
        }),
    ),
});

export type LocalazyCdn = z.infer<typeof LocalazyCdn>;

import { z } from 'zod';

export type AssignedLabel = z.infer<typeof AssignedLabel>;
export type ExcludedLabel = z.infer<typeof ExcludedLabel>;
export type ResolvedLabel = z.infer<typeof ResolvedLabel>;
export type ItemLabel = z.infer<typeof ItemLabel>;

export const AssignedLabel = z.object({
    labelId: z.string(),
    source: z.enum(['cgLongId', 'direct']).or(z.string() as z.ZodType<string & {}>),
    isExcluded: z.boolean().nullish(),
    modfiedBy: z.string().nullish(),
    modifiedOn: z.coerce.date().nullish(),
});

export const ExcludedLabel = z.object({
    labelId: z.string(),
    excludedOn: z.coerce.date().nullish(),
    excludedBy: z.string().nullish(),
});

export const ResolvedLabel = z
    .object({
        label: z.string(),
        labelId: z.string(),
        distance: z.coerce.number().nullish(),
        minDistance: z.coerce.number().nullish(),
        maxDistance: z.coerce.number().nullish(),
        attachedOn: z.coerce.date().nullish(),
    })
    .transform((item) => ({
        ...item,
        minDistance: item.minDistance ?? item.distance,
        maxDistance: item.maxDistance ?? item.distance,
        multiplier: 1 / ((item.minDistance ?? item.distance ?? 0) + 1) ** 2,
    }));

export const ItemLabel = z.object({
    id: z.string(),
    cgLongId: z.string().nullish(),
    assignedLabels: z.array(AssignedLabel),
    directExclusions: z.array(ExcludedLabel).nullish(),
    labels: z.array(ResolvedLabel),
    excludedLabels: z.array(ResolvedLabel).nullish(),
});

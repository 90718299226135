import { ConnectionStatus } from './connection';
import Bus from './bus';

export function bus_subscribeStatus(this: Bus, callback: (status: ConnectionStatus) => void) {
    let handle: (() => void) | undefined;

    const subscription = {
        start: () => {
            handle = this.channelIn?.subscribeStatus(callback);
        },
        stop: () => {
            handle?.();
        },
    };

    subscription.start();
    this.subscriptions.add(subscription);

    return () => {
        subscription.stop();
        this.subscriptions.delete(subscription);
    };
}

import disruptor_cheese from './disruptor_cheese.png';
import disruptor_drinks from './disruptor_drinks.png';
import disruptor_fish from './disruptor_fish.png';
import disruptor_nonFood from './disruptor_nonFood.png';
import disruptor_sausage from './disruptor_sausage.png';
import disruptor_sausageSelfService from './disruptor_sausageSelfService.png';

export default {
    'disruptor_cheese.png': disruptor_cheese,
    'disruptor_drinks.png': disruptor_drinks,
    'disruptor_fish.png': disruptor_fish,
    'disruptor_nonFood.png': disruptor_nonFood,
    'disruptor_sausage.png': disruptor_sausage,
    'disruptor_sausageSelfService.png': disruptor_sausageSelfService,
} as Record<string, string>;

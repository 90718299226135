import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import _ from 'lodash';
import { LogLevel, LogMessage, LogTransport } from './logger';

export interface AppinsightsWebLoggerOptions {
    logLevel?: LogLevel;
    history: any;
    key: string;
}

const severities: Record<Uppercase<LogLevel>, SeverityLevel> = {
    CRITICAL: SeverityLevel.Critical,
    ERROR: SeverityLevel.Error,
    WARNING: SeverityLevel.Warning,
    INFO: SeverityLevel.Information,
    DEBUG: SeverityLevel.Verbose,
    TRACE: SeverityLevel.Verbose,
};

export class AppinsightsWebLogger implements LogTransport {
    logLevel?: LogLevel;
    reactPlugin?: ReactPlugin;
    client?: ApplicationInsights;

    constructor({ history, key, logLevel }: AppinsightsWebLoggerOptions) {
        this.logLevel = logLevel;

        if (key) {
            this.reactPlugin = new ReactPlugin();
            this.client = new ApplicationInsights({
                config: {
                    instrumentationKey: key,
                    extensions: [this.reactPlugin],
                    extensionConfig: { [this.reactPlugin.identifier]: { history } },
                },
            });
            this.client.loadAppInsights();
            this.client.trackPageView();
        }
    }

    log(logMessage: LogMessage): void {
        if (!this.client) return;

        const trace = logMessage.message;
        const severityLevel = severities[logMessage.logLevel];
        const properties = _.omit(logMessage, ['message']);

        this.client.trackTrace({
            severityLevel,
            message: trace,
            properties,
        });

        if (logMessage.error) {
            this.client.trackException({
                severityLevel,
                exception: logMessage.error,
                properties,
            });
        }
    }
}

import { rgbToHex } from '@mui/material';

export const normalizeColor = (color: string) => {
    color = rgbToHex(color).slice(0, 7);

    const [, r, g, b] = color.match(/^#(.)(.)(.)$/) ?? [];
    if (r && g && b) {
        return `#${[r, r, g, g, b, b].join('')}`;
    }

    return color;
};

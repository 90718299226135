import { Box, Button, Collapse, Container } from '@mui/material';
import { errorStackOnly, errorToString } from '@whiz-cart/node-shared/errorToString';
import { CopyOnClick } from '@whiz-cart/ui-shared/copyOnClick/copyOnClick.component.tsx';
import { bound } from '@whiz-cart/ui-shared/decorators/bound.js';
import { PureComponent, type ReactNode } from 'react';
import { t } from '../translate';
import css from './errorBoundary.module.less';

export default class ErrorBoundary extends PureComponent<{ children?: ReactNode }, { error: unknown; showDetails: boolean }> {
    override state = { error: undefined as unknown, showDetails: false };

    constructor(props: any) {
        super(props);
    }

    static getDerivedStateFromError(error: any) {
        return { error };
    }

    override componentDidCatch(error: any) {
        console.error('Uncaugh error:', error);
    }

    @bound reload() {
        window.location.reload();
    }

    @bound back() {
        window.location.href = '/';
    }

    override render() {
        const { error, showDetails } = this.state;
        const { children } = this.props;

        if (error) {
            return (
                <div className={css.errorBoundary}>
                    <div className={css.message}>{t('error.uncaught')}</div>

                    <div className={css.buttons}>
                        <Button size="large" className={css.button} onClick={this.reload}>
                            {t('error.reload')}
                        </Button>

                        <Button size="large" className={css.button} onClick={this.back}>
                            {t('error.backToStart')}
                        </Button>
                    </div>

                    {error instanceof Error && (
                        <>
                            <Button
                                color="inherit"
                                variant="text"
                                onClick={() => this.setState((state) => ({ showDetails: !state.showDetails }))}
                            >
                                {t('error.showDetails')}
                            </Button>

                            <Collapse in={showDetails} className={css.details}>
                                <CopyOnClick value={error instanceof Error ? error.stack : errorToString(error)}>
                                    <Container>
                                        <Box
                                            sx={{
                                                fontWeight: 'normal',
                                                whiteSpace: 'pre-wrap',

                                                'p:not(:first-of-type)': {
                                                    ml: 2,
                                                    lineHeight: 1,
                                                },
                                            }}
                                        >
                                            {errorToString(error)
                                                .split('\n')
                                                .map((line, i) => (
                                                    <p key={i}>{line}&nbsp;</p>
                                                ))}
                                        </Box>

                                        {error.stack && (
                                            <Box
                                                sx={{
                                                    mt: '1em',
                                                }}
                                            >
                                                {errorStackOnly(error)}
                                            </Box>
                                        )}
                                    </Container>
                                </CopyOnClick>
                            </Collapse>
                        </>
                    )}
                </div>
            );
        } else return children;
    }
}

import { isPromise } from '@whiz-cart/node-shared/isPromise';
import { useState } from 'react';
import { useIsMounted } from './useIsMounted';

export function useProcessing<Args extends any[], Result>(
    action: (...args: Args) => Result,
): [run: (...args: Args) => Result | undefined, isRunning: boolean, error: unknown, resetError: () => void] {
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState<unknown>();
    const isMounted = useIsMounted();

    function process(...args: Args) {
        setError(undefined);

        let result;
        try {
            result = action(...args);
        } catch (e) {
            if (isMounted.current) {
                setError(e);
            }
        }

        if (isPromise(result) && isMounted.current) {
            setIsProcessing(true);

            result
                .catch((e) => {
                    if (isMounted.current) {
                        setError(e);
                    }
                })
                .finally(() => {
                    if (isMounted.current) {
                        setIsProcessing(false);
                    }
                });
        }

        return result;
    }

    function reset() {
        setError(undefined);
    }

    return [process, isProcessing, error, reset];
}

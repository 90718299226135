import { isPromise } from './isPromise';

export default function lazy<T>(factory: () => T, clearWhenRejected = false) {
    let instance: T | undefined;

    return () => {
        if (instance === undefined) {
            instance = factory();
        }

        if (clearWhenRejected && isPromise(instance)) {
            instance.catch(() => {
                instance = undefined;
            });
        }

        return instance;
    };
}

import { z } from 'zod';
import { ParsedDate } from '../parsed/parsedDate';
import { findEnumValues } from '../enumConverter';
import { Target } from '../masterData/target';
import { Asset } from '../erp/asset';
import { StoreWifiProfile } from '../wifi/storeWifiProfile';

export type MilestoneFieldGeneric = z.infer<typeof MilestoneFieldBase>;
export type MilestoneFieldSelect = z.infer<typeof MilestoneFieldSelect>;
export type MilestoneFieldOpeningHours = z.infer<typeof MilestoneFieldOpeningHours>;
export type MilestoneFieldGps = z.infer<typeof MilestoneFieldGps>;
export type MilestoneFieldAddress = z.infer<typeof MilestoneFieldAddress>;
export type MilestoneField = z.infer<typeof MilestoneField>;
export type NavigationStatus = z.infer<typeof NavigationStatus>;
export type MilestoneFieldNavigationStatus = z.infer<typeof MilestoneFieldNavigationStatus>;
export type MilestoneFieldChargingOptions = z.infer<typeof MilestoneFieldChargingOptions>;
export type MilestoneFieldWifiProfile = z.infer<typeof MilestoneFieldWifiProfile>;
export type MilestoneFieldVoucherPromotionList = z.infer<typeof MilestoneFieldVoucherPromotionList>;
export type MilestoneFieldContactList = z.infer<typeof MilestoneFieldContactList>;
export type MilestoneFieldPreferredLanguages = z.infer<typeof MilestoneFieldPreferredLanguages>;

export const MilestoneFieldBase = z.object({
    name: z.string(),
    readOnly: z.boolean().nullish(),
    hidden: z.boolean().nullish(),
    required: z.boolean().nullish(),
    maxLength: z.number().nullish(),
    minLength: z.number().nullish(),
    max: z.number().nullish(),
    min: z.number().nullish(),
    pattern: z.string().nullish(),
});

export const MilestoneFieldGeneric = MilestoneFieldBase.extend({
    type: z.string(),
    value: z.unknown().nullish(),
});

export const MilestoneFieldSelect = MilestoneFieldBase.extend({
    type: z.literal('select'),
    options: z.array(z.unknown()),
    value: z.unknown().nullish(),
});

const TIME_PATTERN = /^\d\d:\d\d(:\d\d)?$/;
const Time = z.string().refine((s) => TIME_PATTERN.test(s));
const TimeInterval = z.object({
    opens: Time,
    closes: Time,
});

export const MilestoneFieldOpeningHours = MilestoneFieldBase.extend({
    type: z.literal('openingHours'),
    value: z.record(z.enum(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']), TimeInterval).nullish(),
});

export const MilestoneFieldGps = MilestoneFieldBase.extend({
    type: z.literal('gps'),
    value: z
        .object({
            lon: z.number(),
            lat: z.number(),
        })
        .nullish(),
});

export const MilestoneFieldAddress = MilestoneFieldBase.extend({
    type: z.literal('address'),
    value: z
        .object({
            street: z.string(),
            zipCode: z.string(),
            city: z.string(),
            country: z.string(),
        })
        .nullish(),
});

export const MilestoneFieldChargingOptions = MilestoneFieldBase.extend({
    type: z.literal('chargingOptions'),
    value: z.array(Asset).catch([]),
});

export const MilestoneFieldWifiProfile = MilestoneFieldBase.extend({
    type: z.literal('wifiProfile'),
    value: StoreWifiProfile,
});

enum WeekDaysFlags {
    sunday = 1,
    monday = 2,
    tuesday = 4,
    wednesday = 8,
    thursday = 16,
    friday = 32,
    saturday = 64,
}

export const Responsability = z
    .object({
        rolloutPreparation: z.string().nullish(),
        support: z.string().nullish(),
        indoorService: z.string().nullish(),
        furtherDevelopment: z.string().nullish(),
    })
    .nullish();

export const maxDate = new Date('9999-12-31T23:59:59Z');

export const VoucherPromotion = z.object({
    voucherPromotionGuid: z.string().nullish(),
    id: z.string().nullish(),
    code: z.string().nullish(),
    title: z.string(),
    subtitle: z.string().nullish(),
    description: z.string(),
    localizationKey: z.string().nullish(),
    color: z.string().nullish(),
    isActive: z.boolean().nullish(),
    target: Target,
    isCustomNote: z.boolean().nullish(),
    customerNote: z.string().nullish(),
    responsability: Responsability,
    history: z.array(z.any()).nullish(),
    recurringEvent: z
        .object({
            recurringType: z.enum(['weekly', 'monthly', 'yearly', 'daily']),
            recurringInterval: z.number().transform((n) => {
                const enumValues = findEnumValues(n, WeekDaysFlags) as any as WeekDaysFlags[];
                const days = enumValues.map((v) => WeekDaysFlags[v]);
                return days;
            }),
            startDate: ParsedDate.nullish(),
            stopDate: ParsedDate.nullish(),
        })
        .nullish()
        .transform((v) => ({ ...v, repeatForever: v?.stopDate?.toISOString() === maxDate.toISOString() })),
    vouchers: z
        .array(
            z.object({
                minBasketAmount: z.number(),
                voucherAmount: z.number(),
            }),
        )
        .transform((vouchers) => {
            return vouchers.map((voucher) => ({
                minBasketAmount: voucher.minBasketAmount / 100,
                voucherAmount: voucher.voucherAmount / 100,
            }));
        })
        .catch([]),
});

export type Responsability = z.infer<typeof Responsability>;
export type VoucherPromotion = z.infer<typeof VoucherPromotion>;

export const MilestoneFieldVoucherPromotionList = MilestoneFieldBase.extend({
    type: z.literal('voucherPromotionList'),
    value: z
        .object({
            items: VoucherPromotion.array(),
            source: z.literal('store').or(z.literal('template')),
        })
        .nullish()
        .catch(undefined),
});

export const NavigationStatus = z.enum(['off', 'internal', 'active']);

export const MilestoneFieldNavigationStatus = MilestoneFieldBase.extend({
    type: z.literal('navigationStatus'),
    value: NavigationStatus.catch('off'),
});

export const MilestoneFieldContactList = MilestoneFieldBase.extend({
    type: z.literal('storeManagerEmail').or(z.string()),
    value: z
        .array(
            z.object({
                email: z.string(),
            }),
        )
        .catch([]),
});

export const MilestoneFieldPreferredLanguages = MilestoneFieldBase.extend({
    type: z.literal('preferredLanguages'),
    value: z.array(z.string()).catch([]),
});

export const MilestoneField = z.union([
    MilestoneFieldSelect,
    MilestoneFieldOpeningHours,
    MilestoneFieldGps,
    MilestoneFieldAddress,
    MilestoneFieldChargingOptions,
    MilestoneFieldWifiProfile,
    MilestoneFieldNavigationStatus,
    MilestoneFieldGeneric,
    MilestoneFieldContactList,
    MilestoneFieldPreferredLanguages,
]);

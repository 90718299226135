import { z } from 'zod';
import { OtherString } from '../otherString';

export type ChargingOptionType = z.infer<typeof ChargingOptionType>;
export type ChargingOptionConnector = z.infer<typeof ChargingOptionConnector>;
export type WellKnownProperties = z.infer<typeof WellKnownProperties>;
export type Properties = z.infer<typeof Properties>;

export const ChargingOptionType = z.enum(['chargingBox', 'chargingStation', 'mobileChargingStation', 'mobileChargingUnit']);

export const ChargingOptionConnector = z.enum(['rail', 'plugPremium', 'plugLight', 'plugPremiumRail']);

export const WellKnownProperties = z.object({
    cart_type: z.string().nullish().catch(undefined),
    cart_version: z.string().nullish().catch(undefined),
    cart_tariff: z.string().nullish().catch(undefined),
    cart_invoicedFrom: z.coerce.date().nullish().catch(undefined),

    chargingOption_type: ChargingOptionType.or(OtherString).nullish().catch(undefined),
    chargingOption_connector: ChargingOptionConnector.or(OtherString).nullish().catch(undefined),
    chargingOption_capacity: z.number().nullish().catch(undefined),
    chargingOption_switchCabinetCount: z.number().nullish().catch(undefined),
    chargingOption_invoicedFrom: z.coerce.date().nullish().catch(undefined),

    chargingController_chargerCount: z.number().nullish().catch(undefined),
});

export const Properties = WellKnownProperties.passthrough() as z.ZodType<WellKnownProperties & Record<string, unknown>>;

import errorStackParser from 'error-stack-parser';

const excludeFiles = [
    '/loggers/',
    '/diagnostic-channel-publishers',
];
// const exclude

const basePath = typeof process !== 'undefined' && typeof process.cwd !== 'undefined' ? process.cwd() : '$';

export function getCaller(skip = 1) {
    const stack = errorStackParser.parse(new Error());
    const line = stack.slice(skip).find((line) => !excludeFiles.some((x) => line.fileName?.includes(x)));

    return line
        ? [
              line.fileName?.replace(new RegExp(`^${basePath}`), '.'),
              line.lineNumber,
              line.columnNumber,
          ]
              .join(':')
              .replace(/^https?:\/\/[^/]*\//, '')
              .replace(/^\.?\/src\//, '')
              .replace(/^.*\/(nodeShared|@whiz-cart\/node-shared)\//, 'nodeShared/')
              .replace(/^.*\/(uiShared|@whiz-cart\/ui-shared)\//, 'uiShared/')
              .replace(/^\.?\/node_modules\//, '')
        : '';
}

import { z } from 'zod';

// Partial models from https://dev.azure.com/pentlandfirth/WhizCart/_git/azure_playground?path=/Edeka%20API/models/MarketDtoV4Model.cs
// onyl what is needed for ui

export type MarketDtoV4Model = z.infer<typeof MarketDtoV4Model>;

export const MarketMerchantDto = z.object({
    id: z.string().nullish(),
    name: z.string().nullish(),
});

export const MarketAdditionalDataDto = z.object({
    merchant: MarketMerchantDto.nullish(),
});

export const CityDto = z.object({
    name: z.string().nullish(),
    zipCode: z.string().nullish(),
});

export const AddressDto = z.object({
    city: CityDto.nullish(),
    street: z.string().nullish(),
});

export const ContactDetailsDto = z.object({
    address: AddressDto.nullish(),
});

export const DistributionChannelDto = z.object({
    abbreviation: z.string().nullish(),
    name: z.string().nullish(),
    type: z.string().nullish(),
});

export const MarketCoreDataDto = z.object({
    distributionChannel: DistributionChannelDto.nullish(),
    gln: z.string().nullish(),
    name: z.string().nullish(),
});

export const MarketDtoV4Model = z.object({
    additionalData: MarketAdditionalDataDto.nullish(),
    contact: ContactDetailsDto.nullish(),
    coreData: MarketCoreDataDto.nullish(),
});

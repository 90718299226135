import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { isLinkActive, NavLink } from '@whiz-cart/ui-shared/routing/navLink.component.tsx';
import c from 'classnames';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { appState } from '../appState';
import { t } from '../translate';
import { RouteLike } from './navigation';
import css from './navigation.module.less';

export type NavigationGroupProps = {
    routes: RouteLike[];
    groupName: string;
    hideNavigation: () => void;
    disableCollapse: boolean;
    highlightItem?: (item: RouteLike) => boolean | undefined;
};

const findActiveRoute = (routes: RouteLike[], path: string): RouteLike | undefined => {
    const active = routes.find((route) => {
        return isLinkActive({ pathname: path, to: route.link });
    });

    const activeChild = active?.children && findActiveRoute(active.children, path);

    return activeChild ?? active;
};

export default function NavigationGroup({ routes, groupName, hideNavigation, disableCollapse, highlightItem }: NavigationGroupProps) {
    const navigationGroupsCollapse = appState.useState('navigationGroupsCollapse') ?? {};
    const show = navigationGroupsCollapse[groupName];

    const url = useSelector((state: any) => state?.url);
    const activeRoute = findActiveRoute(routes, url.path);
    const hasActiveItem = !!activeRoute;
    const noCollapse = disableCollapse || hasActiveItem;

    return (
        <div>
            {groupName !== 'undefined' && (
                <ListItemButton
                    data-testid={`navigationGroup-${groupName.split('.')[1]}`}
                    key={groupName}
                    className={css.group}
                    onClick={async (e) => {
                        if (noCollapse) return;
                        const newValue = !show;
                        appState.update((state) => {
                            const result = { ...state.navigationGroupsCollapse };
                            result[groupName] = newValue;
                            state.navigationGroupsCollapse = result;
                        });
                        e.stopPropagation();
                    }}
                >
                    <ListItemText primary={t.unknown(`${groupName}`)} />
                    {noCollapse ? <></> : show ? <ChevronRightIcon /> : <ExpandMore />}
                </ListItemButton>
            )}
            <Collapse in={!show || noCollapse} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {routes.map((route) => (
                        <RouteLink
                            key={route.path}
                            route={route}
                            hideNavigation={hideNavigation}
                            activeRoute={activeRoute}
                            highlightItem={highlightItem}
                        />
                    ))}
                </List>
            </Collapse>
        </div>
    );
}

function RouteLink({
    route,
    hideNavigation,
    activeRoute,
    nested = 0,
    highlightItem,
}: {
    route: RouteLike;
    hideNavigation: () => void;
    activeRoute?: RouteLike;
    nested?: number;
    highlightItem?: (item: RouteLike) => boolean | undefined;
}) {
    const path = useSelector((state: any) => state.url.path);
    const isOpen = route.isMatchingSearch || isLinkActive({ pathname: path, to: route.link });
    const isActive = route.link === activeRoute?.link;
    const ref = useRef<HTMLAnchorElement>(null);
    const isHighlighted = highlightItem?.(route);

    useEffect(() => {
        if (ref.current) {
            if ((isActive && !isInViewport(ref.current)) || isHighlighted) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [isActive, isHighlighted]);

    return (
        <>
            <ListItemButton
                data-testid={`navigationItem-${route.link.replace(/^\/+|\/+$/g, '').replace(/\/+/g, '-')}`}
                component={NavLink}
                forceActive={isActive}
                exact={route.exact}
                to={route?.link}
                className={c(css.entry, { [css.highlighted]: isHighlighted, [css.disableTransition]: !!highlightItem })}
                ref={ref}
                activeClassName={css.active}
                onClick={() => {
                    hideNavigation();
                    route?.onClick?.();
                }}
                sx={{
                    pl: `${0.5 + Math.max(nested, 0)}em`,
                }}
            >
                {!nested ? (
                    <FontAwesomeIcon className={css.icon} fixedWidth icon={route.icon || faQuestion} />
                ) : (
                    <Box
                        sx={{
                            '--size': '0.625em',
                            ml: 'var(--size)',
                            mb: 'var(--size)',
                            width: 'var(--size)',
                            height: 'var(--size)',
                            border: 'solid white',
                            borderWidth: '0 0 2px 2px',
                        }}
                    />
                )}
                <ListItemText primary={route.label} className={css.entryText} />
            </ListItemButton>

            {!!route.children?.length && (
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {route.children.map((route) => (
                            <RouteLink
                                key={route.path}
                                route={route}
                                hideNavigation={hideNavigation}
                                activeRoute={activeRoute}
                                nested={nested + 1}
                                highlightItem={highlightItem}
                            />
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
}

function isInViewport(element: HTMLElement, offset = 0) {
    if (!element) return false;
    const top = element.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
}

export function stringifyLogObject(value: any, maxDepth = 3, maxLines = 10): string {
    const prefix = (n: number) => ' '.repeat(n * 2);

    const traverse = (value: any, depth = 0, visited = new Set<any>()): string => {
        if (typeof value === 'object' && value !== null) {
            if (visited.has(value)) return '[Circular]';
            visited.add(value);
        }

        if (Array.isArray(value)) {
            if (depth >= maxDepth) return '[Array]';

            return value.map((x) => (Array.isArray(x) ? '[Array]' : typeof x === 'object' && x !== null ? '[Object]' : x)).join(', ');
        }

        if (typeof value === 'object' && value !== null) {
            if (depth >= maxDepth) return '[Object]';

            return [
                depth > 0 ? `` : undefined,
                ...Object.entries(value).map(([key, value]) => {
                    return `${prefix(depth)}${key}: ${traverse(value, depth + 1, visited)}`;
                }),
            ]
                .filter((x) => x !== undefined)
                .join('\n');
        }

        return String(value);
    };

    let lines = traverse(value)?.split('\n');

    if (lines.length > maxLines) {
        lines = lines.slice(0, maxLines - 1).concat('...');
    }

    return lines.map((line) => prefix(1) + line).join('\n');
}

export function stringifyCircularJSON(value: any, replacer?: (this: any, key: string, value: any) => any, space?: string | number): string {
    const visited = new Set<any>();

    return JSON.stringify(
        value,
        function (key, value) {
            if (typeof value === 'object' && value !== null) {
                if (visited.has(value)) return '[Circular]';
                visited.add(value);
            }

            if (replacer) {
                return replacer.apply(this, [key, value]);
            }

            return value;
        },
        space,
    );
}

const decorateMethod = (
    { transform = (x) => x, bind }: { transform?: (...args: any[]) => any; bind?: boolean },
    target: any,
    name: string,
    descriptor: any,
) => {
    if (bind) {
        let fn = descriptor.value;
        let instances = new WeakMap();

        return {
            configurable: true,
            get() {
                let instance = instances.get(this);
                if (!instance) {
                    instances.set(this, (instance = transform(fn || descriptor.get.apply(this), target, name, descriptor).bind(this)));
                }
                return instance;
            },
            set(value: any) {
                fn = value;
                instances = new WeakMap();
            },
        };
    } else {
        let fn = transform(descriptor.value || descriptor.get(), target, name, descriptor);
        return {
            configurable: true,
            get() {
                return fn;
            },
            set(value: any) {
                fn = transform(value, target, name, descriptor);
            },
        };
    }
};

export const decorator =
    ({ method, component }: { method?: any; component?: any }) =>
    (target?: any, name?: string, descriptor?: any) =>
        name && descriptor ? decorateMethod(method, target, name, descriptor) : component?.transform(target);

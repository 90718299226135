import _ from 'lodash';
import { z } from 'zod';
import appStatus from '../appStatus';
import { errorToString } from '../errorToString';
import '../parseEnv';
import { ConsoleLogger, originalConsoleLogs } from './consoleLogger';
import { getCaller } from './getCaller';

export type LogLevel = z.infer<typeof LogLevels>;

export type Primitive = string | number | bigint | boolean | null | undefined;

export interface LogFunction {
    (this: Logger, ...message: Primitive[]): void;
    (this: Logger, ...message: [...message: Primitive[], structured: Record<string, unknown>]): void;
    (this: Logger, ...message: [...message: Primitive[], error: unknown]): void;
}

export type Logger = Record<LogLevel, LogFunction> & {
    /** Log transports to use. */
    transports: LogTransport[];

    /** Minimum log level to print.
     * @default 'debug' if NODE_ENV is 'development', 'info' else.
     */
    logLevel: LogLevel;

    context?: string;

    properties?: Record<string, unknown>;

    /** Create a new logger with the given context. */
    createContext(context: string, properties?: Record<string, unknown>): Logger;

    /** Log a message. */
    log(message: LogMessage): void;
};

export interface LogMessage extends Record<string, unknown> {
    time: string;
    logLevel: Uppercase<LogLevel>;
    thread: string;
    location: string;
    message: string;
    error?: Error;
    version: string;
    startTime: string;
    memory?: number;
}

export interface LogTransport {
    logLevel?: LogLevel;
    log(message: LogMessage, logger: Logger): void;
}

export const LogLevels = z.enum(['trace', 'debug', 'info', 'warning', 'error', 'critical']);

const isDev = typeof process !== 'undefined' && typeof process.env !== 'undefined' && process.env.NODE_ENV === 'development';
const pid = typeof process !== 'undefined' && typeof process.pid !== 'undefined' ? process.pid : 0;
const logLevel =
    typeof process !== 'undefined' && typeof process.env !== 'undefined' && process.env.LOG_LEVEL
        ? LogLevels.parse(process.env.LOG_LEVEL)
        : isDev
          ? 'debug'
          : 'info';

function createLogFunction(logLevel: LogLevel): LogFunction {
    return function (...args) {
        if (Object.values(LogLevels.Values).indexOf(logLevel) < Object.values(LogLevels.Values).indexOf(this.logLevel)) {
            return;
        }

        const app = appStatus();
        const logMessage: LogMessage = {
            time: new Date().toISOString(),
            logLevel: logLevel.toUpperCase() as Uppercase<LogLevel>,
            location: getCaller(2),
            thread: String(pid),
            message: '',
            version: app.appVersion,
            startTime: app.startTime,
            memory: app.memoryUsage,
        };
        const messageParts = new Array<string>();

        if (this.context) {
            logMessage.context = this.context;
            messageParts.push(`[${this.context}]`);
        }

        for (const arg of args) {
            if (arg instanceof Error) {
                logMessage.error = arg;
            } else if (typeof arg === 'object' && arg !== null) {
                const conflictingKeys = ['time', 'logLevel', 'thread', 'location', 'message', 'error', 'custom'];
                const noConflict: any = _.omit(arg, conflictingKeys);
                const withConflict = _.pick(arg, conflictingKeys);

                if (!_.isEmpty(withConflict)) {
                    noConflict.custom = withConflict;
                }

                Object.assign(logMessage, noConflict);
            } else {
                messageParts.push(arg === undefined ? '' : String(arg));
            }
        }

        Object.assign(logMessage, this.properties);

        if ((logMessage.logLevel === 'CRITICAL' || logMessage.logLevel === 'ERROR') && !logMessage.error) {
            const lastArg = args[args.length - 1];
            const errorMessage = errorToString(lastArg);
            logMessage.error = new Error(errorMessage);
        }

        logMessage.message = messageParts.join(' ') || logMessage.error?.message || '';

        log.call(this, logMessage);
    };
}

function log(this: Logger, message: LogMessage) {
    for (const transport of this.transports) {
        if (
            transport.logLevel &&
            Object.values(LogLevels.Values).indexOf(message.logLevel.toLowerCase() as LogLevel) <
                Object.values(LogLevels.Values).indexOf(transport.logLevel)
        ) {
            return;
        }

        transport.log(message, this);
    }
}

export function createLogger(context?: string, properties?: Record<string, unknown>): Logger {
    const logger = {
        critical: createLogFunction('critical'),
        error: createLogFunction('error'),
        warning: createLogFunction('warning'),
        info: createLogFunction('info'),
        debug: createLogFunction('debug'),
        trace: createLogFunction('trace'),

        transports: [new ConsoleLogger()],
        logLevel,
        context,
        properties,

        createContext(newContext: string, newProperties?: Record<string, unknown>) {
            return createLogger(this.context ? `${this.context}.${newContext}` : newContext, { ...this.properties, ...newProperties });
        },

        log,
    };

    for (const [key, value] of Object.entries(logger)) {
        if (typeof value === 'function') {
            (logger as any)[key] = value.bind(logger);
        }
    }

    return logger;
}

export const defaultLogger = createLogger();
export default defaultLogger;

export function patchConsole({ errorOnly }: { errorOnly?: boolean } = {}) {
    console.error = defaultLogger.error.bind(defaultLogger);

    if (!errorOnly) {
        console.warn = defaultLogger.warning.bind(defaultLogger);
        console.info = defaultLogger.info.bind(defaultLogger);
        console.debug = defaultLogger.debug.bind(defaultLogger);
        console.trace = defaultLogger.trace.bind(defaultLogger);
    }
}

export function restoreConsole() {
    console.error = originalConsoleLogs.error;
    console.warn = originalConsoleLogs.warning;
    console.info = originalConsoleLogs.info;
    console.debug = originalConsoleLogs.debug;
    console.trace = originalConsoleLogs.trace;
}

import { Coordinate } from '@whiz-cart/node-shared/models/navigation/coodinate';
import { service } from '@whiz-cart/node-shared/service/service';
import { createCache } from 'cross-state';
import icons from './icons';

export interface RawCommodityGroup {
    groupId: string | undefined;
    name?: string;
    metadata?: {
        displayName?: string;
        icon?: string;
        disableManualPricing?: boolean;
        defaultLocation?: { x: number; y: number };
        positions?: Coordinate[];
    };
}

export interface CommodityGroup {
    guid?: string | null | undefined;
    groupId: string | null | undefined;
    name: string;
    rawName: string;
    icon?: string;
    disableManualPricing?: boolean;
    location?: Coordinate;
    positions?: Coordinate[];
}

type Getter = (storeGuid: string) => { items: RawCommodityGroup[] } | Promise<{ items: RawCommodityGroup[] }>;

export const commodityGroupService = service(
    'commodityGroupService',
    class CommodityGroupService {
        all = createCache(
            async (storeGuid?: string) => {
                const getter = await this.getter;
                const { items } = await getter(storeGuid ?? '');

                return items
                    .filter((g) => g.groupId)
                    .map<CommodityGroup>((g) => ({
                        groupId: g.groupId,
                        name: g.metadata?.displayName ?? g.name ?? '',
                        rawName: g.name ?? '',
                        icon: g.metadata?.icon && this.icon(g.metadata.icon),
                        disableManualPricing: g.metadata?.disableManualPricing,
                        location: g.metadata?.defaultLocation,
                        positions: g.metadata?.positions,
                    }));
            },
            { invalidateAfter: 60_000 },
        );

        resolveGetter: ((getter: Getter) => void) | undefined;
        getter: Getter | Promise<Getter> = new Promise((resolve) => {
            this.resolveGetter = resolve;
        });
        groups: { [storeGuid: string]: CommodityGroup[] | Promise<CommodityGroup[]> } = {};

        icon(name: string): string {
            if (name in icons) {
                return icons[name as keyof typeof icons];
            }

            console.warn('Missing icon:', name);
            return icons.foto;
        }

        configure(getter: Getter) {
            this.getter = getter;
            this.groups = {};
            this.resolveGetter?.(getter);
            delete this.resolveGetter;
        }
    },
);

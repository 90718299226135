import { z } from 'zod';
import '../helpers';
import { AssignedLabel, ResolvedLabel } from '../label/itemLabel';
import { ProductNutritionInfo } from '../nutrition/nutritionTypes';
import { Timestamped } from '../timestamped';

export const SearchFlag = z.enum(['isPlu', 'isFruitOrVeggie', 'isBakery', 'isDrink', 'isBundle']);
export type SearchFlag = z.infer<typeof SearchFlag>;

export const FoodFlag = z.enum([
    'lactoseFree',
    'glutenFree',
    'organic',
    'vegan',
    'vegetarian',
    'diet',
    'regional',
    'local',
    'permSale',
    'gentech',
]);
export type FoodFlag = z.infer<typeof FoodFlag>;

export const OtherFlag = z.enum([
    'hasImage',
    'heavy',
    'light',
    'weightProduct',
    'singlePosTransferOnly',
    'noBonusPoints',
    'notDiscountable',
    'negative',
    'collectable',
]);
export type OtherFlag = z.infer<typeof OtherFlag>;

export const Flag = z.union([SearchFlag, FoodFlag, OtherFlag, z.string() as z.ZodType<string & {}>]);
export type Flag = z.infer<typeof Flag>;

export const Price = z.object({
    price: z.coerce.number(),
    priceint: z.number(),
    validFrom: z.coerce.date(),
    validTo: z.coerce.date(),
    /** 00 -> normal price, 01 -> action price */
    priceTC: z.string(),
    discount: z.number().nullish(),
});
export type Price = z.infer<typeof Price>;

export const AssignedShelf = z.object({
    shelf: z.number().nullish(),
    systemId: z.number().nullish(),
    x: z.coerce.number().nullish().ndefault(0),
    y: z.coerce.number().nullish().ndefault(0),
});

export type AssignedShelf = z.infer<typeof AssignedShelf>;

export const UiProduct = Timestamped.extend({
    _id: z.string().optional(),
    itemId: z.string(),
    rootItemId: z.string(),
    brand: z.string().nullish(),
    name: z.string(),
    storeGuid: z.string(),
    gtins: z.array(z.string()),
    gtin: z.string().nullish(),
    cgId: z.string(),
    cgLongId: z.string().nullish(),
    prices: z.array(Price),
    contents: z.string().nullish(),
    weight: z.number().nullish(),
    tags: z.array(z.string()).nullish(),
    cgIcon: z.string().nullish(),
    cgLocalKey: z.string().nullish(),
    flags: z.array(Flag).nullish(),
    deposit: z.number().nullish(),
    search: z.boolean().nullish(),
    active: z.boolean().nullish(),
    disableAddLocalKey: z.string().nullish(),
    capacity: z.number().nullish(),
    positions: z.array(AssignedShelf).nullish(),
    shelfNumbers: z.array(z.number()).nullish(),
    commodityGroupId: z.string().nullish(),
    commodityGroupLongId: z.string().nullish(),
    labels: ResolvedLabel.array().nullish(),
    assignedLabels: z.array(AssignedLabel).nullish(),
    score: z.coerce.number().nullish(),
    salesLimitQty: z.coerce.number().nullish(),
    age: z.coerce.number().nullish(),
    nutrition: ProductNutritionInfo.nullish(),
});
export type UiProduct = z.infer<typeof UiProduct>;

export const URL_CHANGED = 'URL_CHANGED';
export const REMOVE_FROM_BACK_HISTORY = 'REMOVE_FROM_BACK_HISTORY';

export interface UrlState {
    path: string;
    params: Record<string, any>;
    hash: Record<string, any>;
}
export type UrlAction = { type: typeof URL_CHANGED; payload: UrlState } | { type: typeof REMOVE_FROM_BACK_HISTORY; payload: number };

/** Processes a url change and add it to state */
export const urlChanged = (url: UrlState) => ({
    type: URL_CHANGED,
    payload: url,
});

/**
 * Removes the most recent history items up until historyIndex
 * @param historyIndex all history items up to this index will be cleared
 */
export const removeFromBackHistory = (historyIndex: number) => ({
    type: REMOVE_FROM_BACK_HISTORY,
    payload: historyIndex,
});

import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from 'classnames';
import React from 'react';
import css from './modal.module.less';

export interface ModalProps {
    blocking?: boolean;
    onClickBackground?: () => void;
    onClickClose?: () => void;
    children: React.ReactNode;
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
    'data-testid'?: string;
    divProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    closeColor?: string;
    insetCloseButton?: boolean;
}

export function Modal({
    blocking,
    onClickBackground,
    onClickClose,
    children,
    className,
    onClick = (e) => e.stopPropagation(),
    'data-testid': testId,
    divProps,
    closeColor,
    insetCloseButton,
}: ModalProps) {
    return (
        <div {...divProps} data-testid={testId} className={c(css.modal, { [css.blocking]: blocking })} onClick={onClickBackground}>
            <div className={c(css.content, className)} onClick={onClick}>
                {children}
                {onClickClose && (
                    <FontAwesomeIcon
                        color={`${closeColor || 'black'}`}
                        data-testid="modalCloseButton"
                        className={c(css.close, { [css.insetCloseButton]: insetCloseButton })}
                        icon={faTimes}
                        onClick={onClickClose}
                    />
                )}
            </div>
        </div>
    );
}

import { env } from './parseEnv';
import timeSpan from './timeSpan';

const startTime = new Date();

let appVersion: string | undefined;
export const setAppVersion = (v: string) => {
    appVersion = v;
};

let serviceName: string | undefined;
export const setServiceName = (s: string) => {
    serviceName = s;
};
export const getServiceName = () => serviceName;

export default (custom?: any) => ({
    appVersion: appVersion || env('BUILD_BUILDNUMBER', '0.0.0'),
    threadCount: 1,
    startTime: startTime.toISOString(),
    executionTime: timeSpan(startTime),
    memoryUsage: typeof process !== 'undefined' ? process.memoryUsage?.().rss : undefined,
    containerName: env('CONTAINER_NAME'),
    serviceName,
    custom,
});

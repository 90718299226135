export interface IterableBufferProducer<T> {
    push(item: T): void;
    complete(): void;
    throw(e: any): void;
}

export function iterableBuffer<T>(): [IterableBufferProducer<T>, AsyncIterable<T>] {
    const buffer: ['item' | 'completed' | 'error', any?][] = [];
    let resume: (() => void) | undefined;
    let isBeingConsumed = false;

    function push(item: T) {
        buffer.push(['item', item]);
        resume?.();
    }

    function complete() {
        buffer.push(['completed']);
        resume?.();
    }

    function _throw(e: any) {
        buffer.push(['error', e]);
        resume?.();
    }

    async function* consume() {
        if (isBeingConsumed) throw Error('Only one consumer allowed.');
        isBeingConsumed = true;

        for (;;) {
            if (buffer.length === 0) {
                await new Promise<void>((r) => {
                    resume = r;
                });
            }
            const [type, payload] = buffer.shift() || [];
            if (type === 'completed') return;
            else if (type === 'error') throw payload;
            else if (type === 'item') yield payload as T;
        }
    }

    return [{ push, complete, throw: _throw }, { [Symbol.asyncIterator]: consume }];
}

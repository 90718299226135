import { ZodError } from 'zod';
import { formatZodIssues } from './errors/formatZodError';
import { stringifyCircularJSON } from './loggers/stringifyLogObject';

export const errorToString = (e: unknown): string => {
    if (e instanceof WhizCartError) {
        return e.userMessage;
    }

    if (e instanceof ZodError) {
        return `Validation error:\n${formatZodIssues(e)}`;
    }

    if (e instanceof Error) {
        return e.message;
    }

    if (typeof e === 'object' && e !== null) {
        try {
            return stringifyCircularJSON(e);
        } catch {
            // ignore
        }
    }

    return String(e);
};

export const errorStackOnly = (e: unknown): string | undefined => {
    if (e instanceof Error) {
        return e.stack
            ?.split('\n')
            .filter((line) => line.match(/^\s*at /))
            .join('\n');
    }

    return;
};

export const errorCode = (e: unknown): string | number | undefined => {
    if (typeof e === 'object' && e !== null && 'code' in e && (typeof e.code === 'string' || typeof e.code === 'number')) {
        return e.code;
    }

    return;
};

export class WhizCartError extends Error {
    userMessage: string;
    code?: string | number;

    constructor(
        error: unknown,
        {
            message = errorToString(error),
            userMessage = message,
            code = errorCode(error),
        }: {
            message?: string;
            userMessage?: string;
            code?: string | number;
        } = {},
    ) {
        super(message, { cause: error instanceof Error ? error : undefined });
        this.userMessage = userMessage;
        this.code = code;
    }
}

import Bus, { BusDebug } from './bus';
import CPromise from '../cPromise';

export function bus_once<T = any>(
    this: Bus,
    topic: string,
    {
        correlationGuid,
        debug = this.options.debug,
        timeout = this.options.timeout,
    }: { correlationGuid?: string; debug?: BusDebug; timeout?: number } = {},
) {
    timeout = Math.max(timeout, 0) || Bus.DEFAULT_TIMEOUT;

    return new CPromise<T>((resolve, reject, onCancel) => {
        const cancelSubscription = this.subscribe(
            topic,
            (data, _topic, pkg) => {
                cancel();
                if (pkg?.error) reject(pkg.error);
                else resolve(data);
            },
            { correlationGuid, debug },
        );

        let timeoutId: ReturnType<typeof setTimeout> | undefined;
        if (timeout) {
            timeoutId = setTimeout(() => {
                cancel();
                reject(Object.assign(new Error(`${topic} timed out after ${timeout}ms`), { isTimeout: true }));
            }, timeout);
        }

        const cancel = () => {
            cancelSubscription();
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };

        onCancel(cancel);
    });
}

import { z } from 'zod';
import { Store } from '../store';
import { Milestone, MilestoneSlim } from './milestone';

export type StoreWithMilestones = z.infer<typeof StoreWithMilestones>;
export type StoreWithMilestonesSlim = z.infer<typeof StoreWithMilestonesSlim>;

export const StoreWithMilestones = z.object({
    store: Store,
    milestones: z.array(Milestone),
});

export const StoreWithMilestonesSlim = z.object({
    store: Store,
    milestones: z.array(MilestoneSlim),
});

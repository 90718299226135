import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import CPromise from '@whiz-cart/node-shared/cPromise';
import genGuid from '@whiz-cart/node-shared/guid';
import { service } from '@whiz-cart/node-shared/service/service';
import { createStore, type Store } from 'cross-state';
import _ from 'lodash';
import { isValidElement, type HTMLProps, type ReactElement, type ReactNode } from 'react';
import type { ButtonProps } from '../button/button.component';

export interface OverlayAction<TResult = void> extends Omit<ButtonProps, 'action'> {
    label?: ReactNode;
    action?: () => TResult | void;
    primary?: boolean;
}

export interface OverlayInput<TResult = void> {
    id?: string;
    icon?: ReactElement | 'error' | 'warning' | IconProp;
    title?: ReactNode;
    message?: ReactNode;
    timeout?: number;
    priority?: number;
    actions?: OverlayAction<TResult>[];
    variant?: 'default' | 'rounded' | 'banner' | 'raw';
    animationClassNames?: Record<string, any>;
    canClose?: boolean;
    messageIsCentered?: boolean;
    className?: string;
    containerClassName?: string;
    messageClassName?: string;
    'data-testid'?: string;
    divProps?: HTMLProps<HTMLDivElement>;
    onClose?: (result?: TResult) => void;
}

export interface Overlay<TResult = void> extends OverlayInput<TResult> {
    guid: string;
    parentGuid?: string;
    createdOn: Date;
    close(result?: TResult): void;
    update(input: Partial<Overlay<TResult>>): void;
    overlay<S = void>(input: OverlayInput<S>): Overlay<S>;
}

export type OverlayListener = (overlays: Overlay<any>[], isSuspended: boolean) => void;

export const overlayService = service(
    'overlayService',
    class OverlayService {
        state: Store<{
            overlays: Overlay<any>[];
            suspensions: Set<unknown>;
        }> = createStore<{
            overlays: Overlay<any>[];
            suspensions: Set<unknown>;
        }>({
            overlays: [],
            suspensions: new Set<unknown>(),
        });

        openOverlays = this.state.map(({ overlays, suspensions }) => {
            const [first] = _.orderBy(
                overlays.filter((o) => !o.parentGuid && (suspensions.size === 0 || o.priority === 0)),
                [(x) => x.priority ?? Infinity, 'createdOn'],
            );
            const current: Overlay<any>[] = [];
            if (first) addChildren(first);

            function addChildren(overlay: Overlay<any>) {
                if (!overlay) return;
                current.push(overlay);
                overlays.filter((o) => o.parentGuid === overlay.guid).forEach(addChildren);
            }

            return current;
        });

        overlay<TResult = void>(
            _input: OverlayInput<TResult> | ReactElement,
            parentGuid?: string,
        ): CPromise<TResult | undefined> & Overlay<TResult> {
            let input: OverlayInput<TResult>;
            if (isValidElement(_input)) {
                input = {
                    message: _input,
                };
            } else {
                input = _input as OverlayInput<TResult>;
            }

            const guid = genGuid();

            const promise = new CPromise<TResult | undefined>();

            const overlay: Overlay<TResult> = {
                ...input,

                guid,
                parentGuid,
                createdOn: new Date(),

                close(result) {
                    promise.resolve(result);
                    overlayService.state.set((state) => ({
                        ...state,
                        overlays: state.overlays.filter((x) => x.guid !== guid),
                    }));
                },

                update(input) {
                    overlayService.state.set((state) => ({
                        ...state,
                        overlays: state.overlays.map((x) => {
                            if (x.guid === guid) return { ...x, ...input };
                            return x;
                        }),
                    }));
                },

                overlay(input) {
                    return overlayService.overlay(input, guid);
                },
            };

            if (isValidElement(input)) overlay.message = input;
            else Object.assign(overlay, input);

            if (overlay.timeout) setTimeout(overlay.close, overlay.timeout);

            const siblings = overlayService.state.get().overlays.filter((x) => x.parentGuid === parentGuid);
            for (const sibling of siblings) if (sibling.priority === undefined) sibling.close();

            if (input.id !== undefined && siblings.some((x) => x.id === input.id)) overlay.close();
            else
                overlayService.state.set((state) => ({
                    ...state,
                    overlays: [...state.overlays, overlay],
                }));

            return Object.assign(promise, overlay);
        }

        hideAllOverlays() {
            for (const overlay of this.state.get().overlays) overlay.close();
        }

        suspendOverlays() {
            const ref = {};
            this.state.set((state) => ({
                ...state,
                suspensions: new Set([...state.suspensions, ref]),
            }));

            return () => {
                this.state.set((state) => {
                    const next = new Set(state.suspensions);
                    next.delete(ref);

                    return {
                        ...state,
                        suspensions: next,
                    };
                });
            };
        }
    },
);

// @ts-nocheck
import endpoint from '../util/endpoint';
import store from '../store';
import sleep from '@whiz-cart/node-shared/sleep';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const updateProduct = (...products) => ({
    type: UPDATE_PRODUCT,
    payload: products.map((product) => ({ gtin: product.gtin, product: { t: new Date(), ...product } })),
});
export const throwUploadError = (error) => ({ type: UPLOAD_ERROR, payload: error });
export const clearError = () => ({ type: CLEAR_ERROR });

const requests = {};
export const fetchProduct = (gtin) => async (dispatch) => {
    if (requests[gtin]) return;
    requests[gtin] = true;

    do {
        try {
            const storeGuid = await store.awaitState('url.params.storeGuid');
            let response = await endpoint('getProductByBarcode', { storeGuid, barcode: gtin }).get();
            if (response) dispatch(updateProduct(response));
            else {
                response = await endpoint('getUnknownProductByBarcode', { storeGuid, barcode: gtin }).get();
                dispatch(updateProduct({ ...response, gtin, unknown: true }));
            }

            delete requests[gtin];
        } catch (e) {
            console.error(`Could not fetch product ${gtin}. Trying again.`, e);
            await sleep(10000);
        }
    } while (requests[gtin]);
};

export const clearProducts = () => ({ type: CLEAR_PRODUCTS });

export const postProductImage =
    ({ gtin, formData, rotation }) =>
    async (dispatch) => {
        try {
            const storeGuid = await store.awaitState('url.params.storeGuid');
            // images uploaded with this endpoint appear to only affect MHD/BBD page
            const response = await endpoint('uploadProductImage', { storeGuid, barcode: gtin }).post(formData, { params: { rotation } });
            dispatch(updateProduct({ gtin, imageUrl: response.file }));
        } catch (e) {
            console.error(`Could not upload product ${gtin} image`, e);
        }
    };

export const postUnknownProduct =
    ({ gtin, productName, mhdGroup }) =>
    async (dispatch) => {
        try {
            const storeGuid = await store.awaitState('url.params.storeGuid');
            await endpoint('uploadUnknownProduct', { storeGuid, barcode: gtin }).post({ name: productName, mhdGroup });
            dispatch(updateProduct({ gtin, name: productName, mhdGroup }));
        } catch {
            dispatch(throwUploadError('Update nicht möglich.'));
            setTimeout(() => {
                dispatch(clearError());
            }, 3500);
        }
    };

enum paymentStatus {
    PendingVerification = 0,
    Created = 1,
    Completed = 2,
    CustomerCancelled = 3,
    EmployeeCancelled = 4,
    PaymentProviderDecline = 5,
    EmployeeReset = 6,
    NewPaymentRequestCreated = 8,
    SystemCancelled = 9,
}

export default paymentStatus;

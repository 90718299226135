import CPromise from '@whiz-cart/node-shared/cPromise';
import sleep from '@whiz-cart/node-shared/sleep';

const RETRIES = 3;
const ONE_MINUTE = 60 * 1000;
const TTL = ONE_MINUTE;

export interface Resource {
    width?: number;
    height?: number;
    type: string;
}

const cache = new Map<string, { t: number; value: Resource } | { t: number; error: true }>();

setInterval(() => {
    const threshold = Date.now() - TTL;
    for (const [key, { t }] of cache.entries()) {
        if (t < threshold) cache.delete(key);
    }
}, ONE_MINUTE);

export function safeLoad(src?: string, retries = RETRIES) {
    return new CPromise<Resource>(async (resolve, reject, onCancel) => {
        if (!src) return reject();
        const cacheEntry = cache.get(src);
        if (cacheEntry && cacheEntry.t >= Date.now() - TTL) {
            if ('error' in cacheEntry) return reject();
            return resolve(cacheEntry.value);
        }

        let loading: CPromise<Resource> | undefined;
        let canceled = false;
        onCancel(() => {
            loading?.cancel();
            canceled = true;
        });

        for (let attempt = 0; attempt <= retries; attempt++) {
            try {
                if (canceled) return reject();
                loading = load(src);

                cache.set(src, { t: Date.now(), value: await loading });
                return resolve(await loading);
            } catch {
                if (loading?.isCanceled) {
                    return reject();
                }
                // else wait and try again
                if (attempt < retries) await sleep(5000);
            }
        }
        cache.set(src, { t: Date.now(), error: true });
        reject();
    });
}

function load(src: string) {
    return new CPromise<Resource>((resolve, reject, onCancel) => {
        const isSVG = !!src.match(/^data:image\/svg\+xml;base64,|\.svg$/);

        const img = new Image();
        img.onload = () => {
            resolve({ width: img.width, height: img.height, type: isSVG ? 'svg' : 'img' });
            cancel();
        };
        img.onerror = reject;
        img.src = src;

        const cancel = () => {
            img.src = '';
            img.onload = null;
            img.onerror = null;
            clearTimeout(timeout);
        };
        const timeout = setTimeout(() => reject(cancel()), 10000);
        onCancel(cancel);
    });
}

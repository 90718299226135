import { URL_CHANGED, UrlState, UrlAction, REMOVE_FROM_BACK_HISTORY } from './url.action';

/** Url reducer. */
export const urlReducer = (
    state: UrlState & { history: UrlState[] } = { path: '', params: {}, hash: {}, history: [] },
    action: UrlAction,
) => {
    switch (action.type) {
        case URL_CHANGED:
            return {
                ...action.payload,
                history: [action.payload, ...state.history].slice(0, 100),
            };

        case REMOVE_FROM_BACK_HISTORY: {
            const index = action.payload;
            const newHistory = state.history.slice(index);
            return {
                ...state,
                history: newHistory,
            };
        }
        default:
            return state;
    }
};

import c from 'classnames';
import { useStore } from 'cross-state/react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Overlay } from './overlay.component';
import css from './overlay.module.less';
import { overlayService } from './overlay.service';
export function OverlayContainer() {
    const overlays = useStore(overlayService.openOverlays);
    const containerClassNames = overlays.map((o) => o.containerClassName).filter(Boolean);

    return (
        <TransitionGroup className={c(css.overlayContainer, ...containerClassNames)} data-testid="overlayContainer">
            {overlays.map((overlay) => (
                <CSSTransition key={overlay.guid} classNames={{ ...css, ...overlay.animationClassNames }} timeout={300}>
                    <Overlay {...overlay} />
                </CSSTransition>
            ))}
        </TransitionGroup>
    );
}

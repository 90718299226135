// @ts-nocheck
import { GET_CONFIGSETTINGS, UPDATE_CONFIGSETTINGS, GET_GROUPCONFIGSETTINGS } from './configSetting.action';

export default (state = {}, { type, payload }) => {
    switch (type) {
        case GET_CONFIGSETTINGS:
            return { ...state, data: payload };
        case GET_GROUPCONFIGSETTINGS:
            return { ...state, global: payload };
        case UPDATE_CONFIGSETTINGS: {
            const newState = { ...state };
            const group = newState.data.find((group) => group.groupName === payload.groupName);
            const config = group.configs.find((config) => config.key === payload.configs.key);
            config.value = payload.configs.value;
            return newState;
        }
        default:
            return state;
    }
};

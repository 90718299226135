// @ts-nocheck
export default (
    state = {
        isLoading: false,
        activeItems: [],
        currentCustomer: [],
        customerHistory: [],
        hasError: false,
        sort: false,
    },
    action,
) => {
    switch (action.type) {
        case 'GET_CUSTOMER':
            return {
                ...state,
                activeItems: action.payload,
                sort: true,
            };
        case 'SET_CUSTOMER_START':
            return {
                ...state,
                isLoading: true,
            };
        case 'SET_CUSTOMER_TAG_SUCCEED': {
            const currentCustomer = action.payload;
            return {
                ...state,
                isLoading: false,
                currentCustomer,
                customerHistory: currentCustomer.history.unshift(action.newHistory),
                hasError: false,
                sort: false,
            };
        }

        case 'SET_CUSTOMER_RISK_SUCCEED': {
            const currentCustomer = action.payload;
            currentCustomer.history.unshift(action.newHistory);
            return {
                ...state,
                isLoading: false,
                currentCustomer,
                customerHistory: currentCustomer.history,
                hasError: false,
                sort: false,
            };
        }

        case 'DELETE_CUSTOMER_TAG': {
            const currentCustomer = {
                ...state.currentCustomer,
                status: {
                    tags: state.currentCustomer.status.tags.filter((a) => a !== action.tag),
                    risk: state.currentCustomer.status.risk,
                    lastLogin: state.currentCustomer.status.lastLogin,
                },
            };

            return {
                ...state,
                isLoading: false,
                currentCustomer,
                customerHistory: state.currentCustomer.history.unshift(action.history),
                sort: false,
            };
        }

        case 'CUSTOMER':
            return {
                ...state,
                isLoading: false,
                currentCustomer: action.payload,
            };

        case 'CUSTOMER_ERROR':
            return {
                ...state,
                isLoading: false,
                hasError: action.payload,
            };
        case 'TOGGLE_SORT':
            return {
                ...state,
                sort: action.payload,
                currentCustomer: {
                    ...state.currentCustomer,
                    history: action.history,
                },
            };

        case 'REMOVE_CUSTOMER':
            return {
                currentCustomer: null,
            };

        default:
            return state;
    }
};

import { ZodError, ZodType } from 'zod';

type NestedError = { _errors: string[] } | { [key: string]: NestedError };

export function formatZodIssues(e: ZodError) {
    const issues: string[] = [];

    const traverse = (obj: NestedError, path: string[] = []): void => {
        for (const [key, value] of Object.entries(obj)) {
            if (Array.isArray(value) && value.length > 0) {
                issues.push(`  ${path.join('.')}: ${value.join(', ')}`);
            } else {
                traverse(value, path.concat(key));
            }
        }
    };

    traverse(e.format());
    return issues.join('\n');
}

export function formatZodError(name: string, e: ZodError) {
    return new Error(`Parsing ${name} failed:\n${formatZodIssues(e)}`, { cause: e });
}

export function parseAndFormatError<T>(name: string, parser: ZodType<T>, data: unknown) {
    try {
        return parser.parse(data);
    } catch (e) {
        if (e instanceof ZodError) {
            throw formatZodError(name, e);
        }

        throw e;
    }
}

import z from 'zod';

export const NutritionInfoModel = z.object({
    kcal: z.number(),
    fat: z.number(),
    carb: z.number(),
    protein: z.number(),
    kj: z.number().optional(),
    fatSaturated: z.number().optional(),
    carbSugar: z.number().optional(),
    fibre: z.number().optional(),
    salt: z.number().optional(),
});

export type NutritionInfoModel = z.infer<typeof NutritionInfoModel>;

export const SingleNutritionInfo = z.object({
    norm: z
        .object({
            size: z.string().nullish(),
            gda: z.coerce.number(),
            value: z.coerce.number(),
        })
        .nullish(),
});

export const ProductNutritionInfo = z.object({
    kcal: SingleNutritionInfo.nullish(),
    kj: SingleNutritionInfo.nullish(),
    fat: SingleNutritionInfo.nullish(),
    fatSaturated: SingleNutritionInfo.nullish(),
    carb: SingleNutritionInfo.nullish(),
    carbSugar: SingleNutritionInfo.nullish(),
    fibre: SingleNutritionInfo.nullish(),
    protein: SingleNutritionInfo.nullish(),
    salt: SingleNutritionInfo.nullish(),
});

export type ProductNutritionInfo = z.infer<typeof ProductNutritionInfo>;

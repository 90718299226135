import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from 'classnames';
import _ from 'lodash';
import { cloneElement, isValidElement, useState, type ReactNode } from 'react';
import { Button } from '../button/button.component';
import { Modal } from '../modal/modal.component';
import css from './overlay.module.less';
import { type Overlay as OverlayModel } from './overlay.service';

const ErrorIcon = <FontAwesomeIcon className={css.error} fixedWidth icon={faExclamationTriangle} />;
const WarningIcon = <FontAwesomeIcon className={css.warning} fixedWidth icon={faExclamationTriangle} />;

export function Overlay<T>(props: OverlayModel<T>) {
    const {
        icon,
        title,
        message,
        actions,
        variant = 'default',
        canClose,
        onClose,
        close,
        messageIsCentered = false,
        className,
        messageClassName,
        'data-testid': testId,
        divProps,
    } = props;

    const [inProgress, setInProgress] = useState<ReactNode>();

    function handleClose(result?: T) {
        onClose?.();
        close?.(result);
    }

    async function execute(label: ReactNode, action: (() => T | void) | undefined) {
        let result;

        try {
            setInProgress(label);
            result = await action?.();
        } finally {
            setInProgress(undefined);
            handleClose(result as T | undefined);
        }
    }

    return (
        <Modal
            data-testid={testId}
            divProps={divProps}
            blocking
            className={c(css[variant], className)}
            onClickClose={canClose ? () => handleClose() : undefined}
            onClickBackground={canClose ? () => handleClose() : undefined}
            insetCloseButton={variant === 'rounded'}
        >
            <>
                {(icon || title) && (
                    <div data-testid="overlayTitle" className={css.header}>
                        {icon && (
                            <div className={css.icon}>
                                {icon === 'error' ? (
                                    ErrorIcon
                                ) : icon === 'warning' ? (
                                    WarningIcon
                                ) : isValidElement(icon) ? (
                                    icon
                                ) : (
                                    <FontAwesomeIcon fixedWidth icon={icon as IconProp} />
                                )}
                            </div>
                        )}
                        {title && <div className={css.title}>{title}</div>}
                    </div>
                )}
                {message && (
                    <div
                        data-testid="overlayMessage"
                        className={c(css.message, messageClassName, messageIsCentered ? css.centeredMessage : '')}
                    >
                        {isValidElement(message) && typeof message.type !== 'string' ? cloneElement(message, _.omit(props, 'id')) : message}
                    </div>
                )}
                {actions && (
                    <div className={css.actions}>
                        {_.map(actions, ({ label, action, className, ...props }, index) => (
                            <Button
                                data-testid={label === 'Ok' ? 'dismissButton' : `overlayAction-${index}`}
                                {...props}
                                key={index}
                                rounded={variant === 'rounded'}
                                big
                                className={c(className, css.action)}
                                onClick={() => execute(label, action)}
                                inactive={inProgress !== undefined}
                                spinner={inProgress === (label ?? null)}
                            >
                                {label}
                            </Button>
                        ))}
                    </div>
                )}
            </>
        </Modal>
    );
}

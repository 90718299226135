import chalk from 'chalk';
import { filterTruthy } from '../helpers/filterTruthy';
import type { LogLevel, LogMessage, LogTransport } from './logger';
import { stringifyCircularJSON, stringifyLogObject } from './stringifyLogObject';

export interface ConsoleLoggerOptions {
    logLevel?: LogLevel;

    /** See log formats https://dev.azure.com/pentlandfirth/WhizCart/_wiki/wikis/EasyShopper.wiki/1711/WhizCart-Log-Format */
    logFormat: 'short' | 'long';

    /** If 'full', append the whole log line as json.\
     * If 'customPropertiesOnly', append only the custom properties as json.
     * @default customPropertiesOnly
     */
    jsonFormat: 'off' | 'full' | 'deduplicated';

    /** If false, print the log as standard log line. Else print only the message and json. */
    browserMode: boolean;

    /** If true, print errors multiline instead of as json.
     * @default true, iff NODE_ENV is 'development'.
     */
    devMode: boolean;
}

export const originalConsoleLogs = {
    critical: console.error,
    error: console.error,
    warning: console.warn,
    info: console.info,
    debug: console.debug,
    trace: console.debug,
};

const isDev = typeof process !== 'undefined' && typeof process.env !== 'undefined' && process.env.NODE_ENV === 'development';
const logLevelLength = 'WARNING'.length;

export class ConsoleLogger implements LogTransport {
    public logLevel?: LogLevel;
    public options: ConsoleLoggerOptions;

    constructor(options?: Partial<ConsoleLoggerOptions>) {
        this.logLevel = options?.logLevel;

        this.options = {
            logFormat: options?.logFormat ?? 'short',
            jsonFormat: options?.jsonFormat ?? ((options?.devMode ?? isDev) ? 'deduplicated' : 'full'),
            browserMode: options?.browserMode ?? typeof (globalThis as any).window !== 'undefined',
            devMode: options?.devMode ?? isDev,
        };
    }

    log(logMessage: LogMessage): void {
        const { logFormat, jsonFormat, devMode, browserMode } = this.options;
        const json: Partial<LogMessage> & Record<string, unknown> = jsonFormat !== 'off' ? { ...logMessage } : {};

        if (jsonFormat === 'deduplicated' || browserMode) {
            delete json.time;
            delete json.logLevel;
            delete json.message;
            delete json.context;

            if (logFormat === 'long' || browserMode) {
                delete json.thread;
                delete json.location;
            }
        }

        if (devMode || browserMode) {
            delete json.error;
        } else if (json.error) {
            json.error = {
                name: json.error.name,
                message: json.error.message,
                stack: json.error.stack,
            };
        }

        const logLine = browserMode
            ? logMessage.message
            : (
                  [
                      [logMessage.time, [chalk.dim]],
                      [logMessage.logLevel.padEnd(logLevelLength, ' '), [chalk.bold]],
                      logFormat === 'long' && [logMessage.thread, [chalk.dim]],
                      logFormat === 'long' && [logMessage.location, [chalk.dim]],
                      [logMessage.message, []],
                      Object.keys(json).length > 0 ? [stringifyCircularJSON(json), [chalk.dim]] : undefined,
                  ] as ([string, (typeof chalk.bold)[] | undefined] | undefined)[]
              )
                  .filter(filterTruthy)
                  .map(([text, color]) => colorText(text, color))
                  .map((field) => field.replace(/\|/g, '_'))
                  .join(' | ');

        const logFunction = originalConsoleLogs[logMessage.logLevel.toLowerCase() as Lowercase<LogLevel>];

        if (browserMode) {
            const args = [logLine, logMessage.error ?? (Object.keys(json).length > 0 ? json : undefined)].filter(Boolean);
            logFunction(...args);
        } else {
            logFunction(colorText(logLine.replace(/\n/g, ''), lineColors[logMessage.logLevel]));

            if (logMessage.error && devMode) {
                const properties: Record<string, unknown> = { ...logMessage.error };
                delete properties.name;
                delete properties.message;
                delete properties.stack;

                const [first = '', ...rest] = (logMessage.error.stack ?? '').split('\n');
                const stack = [
                    colorText(first, [
                        pad,
                        logMessage.logLevel === 'CRITICAL' || logMessage.logLevel === 'ERROR' ? chalk.bgRed : chalk.bgYellow,
                        chalk.bold,
                    ]),
                    ...rest.map((line) => line.replace(/\(.*\)\s*$/, (x) => colorText(x, [chalk.dim]))),
                ]
                    .map((x) => `  ${x}`)
                    .join('\n');
                const props = stringifyLogObject(properties);

                logFunction(['', stack, props ? '' : undefined, props || undefined, ''].filter((x) => x !== undefined).join('\n'));
            }
        }
    }
}

const pad = (s: string | number) => ` ${s} `;

const lineColors: Record<string, (typeof chalk.bold)[]> = {
    CRITICAL: [chalk.red, chalk.bold],
    ERROR: [chalk.red],
    WARNING: [chalk.yellow],
    INFO: [],
    DEBUG: [chalk.dim],
    TRACE: [chalk.dim],
};

function colorText(message: string, color: ((s: string) => string)[] = []) {
    if (!chalk.supportsColor) {
        return message;
    }

    for (const c of color) {
        message = c(message);
    }

    return message;
}

import { useStore } from 'cross-state/react';
import { forwardRef } from 'react';
import { NavLink as Base, NavLinkProps as BaseProps, matchPath } from 'react-router-dom';
import { urlService } from '../url/url.service';

export interface NavLinkProps extends Omit<BaseProps, 'to'> {
    to: string | Record<string, any> | Parameters<(typeof urlService)['calcUrl']>;
    forceActive?: boolean;
}

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
    {
        [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
    }[Keys];

type isLinkActiveProps = { pathname: string; to?: NavLinkProps['to'] | undefined; calculatedTo?: string } & Pick<
    NavLinkProps,
    'exact' | 'strict'
>;
type isLinkActivePropsToOrCalculatedTo = RequireAtLeastOne<isLinkActiveProps, 'to' | 'calculatedTo'>;

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(({ to, forceActive, ...props }, ref) => {
    const calculatedTo = useStore(urlService.state, () => getCalculatedTo(to));
    useStore(urlService.hashBase);

    return (
        <Base
            {...props}
            innerRef={ref}
            to={calculatedTo}
            isActive={(match, location) =>
                forceActive ?? isLinkActive({ pathname: location.pathname, calculatedTo, exact: props.exact, strict: props.strict })
            }
            onClick={props.onClick || ((e) => e.stopPropagation())}
        />
    );
});

function getCalculatedTo(to: NavLinkProps['to']) {
    if (typeof to === 'string') {
        return urlService.calcUrl(to);
    } else if (!Array.isArray(to)) {
        return urlService.calcUrl(to);
    } else {
        return urlService.calcUrl(...(to as any));
    }
}

export function isLinkActive({ pathname, calculatedTo: calculatedToProp, to, exact, strict }: isLinkActivePropsToOrCalculatedTo) {
    const calculatedTo = calculatedToProp ?? getCalculatedTo(to ?? '');
    return !!matchPath(pathname, { path: calculatedTo.replace(/[?#].*$/, ''), exact, strict });
}

import { errorToString } from '@whiz-cart/node-shared/errorToString';
import bwipjs from 'bwip-js';
import c from 'classnames';
import React, { useMemo } from 'react';
import { CopyOnClick } from '../copyOnClick/copyOnClick.component';
import { useEqRef } from '../hooks/useEqRef';
import { Img } from '../img/img.component';
import { getTranslations } from '../translate';
import css from './code.module.less';

type Props = Partial<bwipjs.ToBufferOptions> & {
    style?: React.CSSProperties;
    className?: string;
    debug?: boolean;
    copyOnClick?: boolean;
    type?: string;
    eclevel?: string;
};

export function Code({ style, className, debug, copyOnClick, type = 'ean13', text = '', ...bwipProps }: Props) {
    const { content, error } = useMemo(() => {
        try {
            const canvas = document.createElement('canvas');
            Object.assign(canvas.style, style);

            const content = bwipjs
                .toCanvas(canvas, {
                    // More options are documented here: https://www.npmjs.com/package/bwip-js
                    ...bwipProps,
                    bcid: bwipProps.bcid ?? type ?? 'ean13',
                    text,
                    scale: bwipProps.scale ?? 10,
                    includetext: bwipProps.includetext ?? true,
                    textxalign: bwipProps.textxalign ?? 'center',
                })
                .toDataURL('image/png');

            return { content };
        } catch (e) {
            console.error(`Error when rendering code "${text}":`, e);
            return { error: e };
        }
    }, [useEqRef({ style, type, text, ...bwipProps })]);

    return !error ? (
        copyOnClick ? (
            <CopyOnClick className={css.copyContainer} value={text}>
                <Img data-testid="code" alt={text} src={content} className={className} style={style} />
            </CopyOnClick>
        ) : (
            <Img data-testid="code" alt={text} src={content} className={className} style={style} />
        )
    ) : (
        <div data-testid="codeError" className={c(css.error, className)}>
            <div className={css.message}>{getTranslations().t('error.code', { code: JSON.stringify(text) })}</div>
            {debug && <div className={css.stack}>{errorToString(error)}</div>}
        </div>
    );
}

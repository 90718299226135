import Bus, { BusDebug } from './bus';
import BaseModel from '../baseModel';
import logger from '../loggers/logger';
import { errorToString } from '../errorToString';

export function bus_serve(
    this: Bus,
    topic: string,
    handler: (data: any, topic: string, baseModel?: BaseModel) => any,
    { debug }: { debug?: BusDebug } = {},
) {
    return this.subscribe(
        `${topic}.request`,
        async (data, currentTopic, pkg) => {
            const { correlationGuid } = pkg || {};
            const properties = pkg?.properties?.source ? { destination: pkg?.properties.source } : undefined;

            try {
                const response = await handler(data, currentTopic, pkg);
                this.publish(`${topic}.response`, response, { correlationGuid, debug, properties });
            } catch (e) {
                logger.error(`${topic} failed:`, e);
                this.publish(`${topic}.response`, null, { error: errorToString(e), correlationGuid, debug, properties });
            }
        },
        { debug },
    );
}

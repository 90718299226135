import { z } from 'zod';

export type StoreWifiCredentials = z.infer<typeof StoreWifiCredentials>;
export type StoreWifiProfile = z.infer<typeof StoreWifiProfile>;

export const StoreWifiCredentials = z.object({
    ssid: z.string(),
    password: z.string(),
});

export const StoreWifiProfile = z.object({
    name: z.string(),
    cart: StoreWifiCredentials.nullish(),
    market: StoreWifiCredentials.nullish(),
    setup: StoreWifiCredentials.nullish(),
});

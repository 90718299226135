import React, { isValidElement } from 'react';
import T from 'prop-types';
import Select, { createFilter } from 'react-select';
import c from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import css from './input.module.less';
import { getTranslations } from '../translate';

const filterConfig = {
    stringify: (option) =>
        option.data?.filterBy ?? [option.label, option.value].filter((x) => typeof x !== 'object' && x !== null).join(' '),
};

/**
 * Regular {@link JSX.IntrinsicElements.input} when given a single value, otherwise {@link Select}
 * **values:** String or object, e.g. *{ value: string, label: string, isDeEmphasized: boolean? }*
 * @returns {React.Component}
 */
export const Input = ({
    value,
    onChange,
    values,
    autoFocus,
    className,
    placeholder,
    validationError,
    validationErrorClassName,
    changed,
    changedClassName,
    classNamePrefix,
    type,
    autoComplete,
    children,
    clearable,
    onClear,
    onKeyDown,
    onBlur,
    testId,
}) => {
    if (values) {
        values = values.map((x) => (typeof x === 'object' ? x : { value: x, label: x }));
        const map = (value) => values.find((x) => x.value === value) || null;
        value = value instanceof Array ? value.map(map) : map(value);
        if (!onChange) value = value instanceof Array ? value.map((x) => x?.label) : value?.label;
    }

    const trans = getTranslations().useTranslator();

    const customStyle = {
        option: (styles, { data }) => {
            return {
                ...styles,
                opacity: data.isDeEmphasized ? 0.4 : 1,
            };
        },
    };

    let content;
    if (!onChange) {
        content = value;
    } else if (values) {
        content = (
            <Select
                styles={customStyle}
                noOptionsMessage={() => trans('input.noOptionsMessage')}
                value={value}
                onChange={(value) => onChange(value instanceof Array ? value.map((x) => x.value) : value?.value)}
                options={values}
                isMulti={value instanceof Array}
                autoFocus={autoFocus}
                placeholder={placeholder ?? trans('input.select')}
                classNamePrefix={classNamePrefix}
                filterOption={createFilter(filterConfig)}
            />
        );
    } else {
        content = (
            <input
                data-testid={`${testId}-input`}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                autoFocus={autoFocus}
                placeholder={placeholder ?? trans('input.select')}
                type={type}
                autoComplete={autoComplete}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
            />
        );
    }

    return (
        <div
            className={c(css.inputContainer, className, {
                [css.validationError]: validationError,
                [validationErrorClassName]: validationError,
                [changedClassName]: changed,
            })}
            data-testid={testId}
        >
            {content}
            {children}
            {clearable && (
                <FontAwesomeIcon
                    className={css.clear}
                    icon={faTimes}
                    onClick={() => {
                        onChange?.(value instanceof Array ? [] : '');
                        onClear?.();
                    }}
                />
            )}
            {validationError && (typeof validationError === 'string' || isValidElement(validationError)) && (
                <div className={css.validationErrorLabel}>{validationError}</div>
            )}
        </div>
    );
};

Input.propTypes = {
    value: T.oneOfType([T.string, T.arrayOf(T.string)]),
    onChange: T.func,
    values: T.arrayOf(T.oneOfType([T.shape({ value: T.oneOfType([T.string, T.number]), label: T.any }), T.string, T.number])),
    autoFocus: T.bool,
    className: T.string,
    placeholder: T.string,
    validationError: T.oneOfType([T.node, T.bool]),
    validationErrorClassName: T.string,
    changed: T.bool,
    changedClassName: T.string,
    classNamePrefix: T.string,
    type: T.string,
    autoComplete: T.string,
    clearable: T.bool,
    onClear: T.func,
    onKeyDown: T.func,
    onBlur: T.func,
    children: T.node,
    testId: T.string,
};

const emptySet = new (class extends Set<any> {
    override add(): this {
        throw Error('Cannot add to immutable set!');
    }
})();

export class MultiMap<K, V> extends Map<K, Set<V>> {
    override get(key: K) {
        return super.get(key) || (emptySet as Set<V>);
    }

    add(key: K, ...values: V[]) {
        let entry = super.get(key);
        if (!entry) {
            entry = new Set();
            super.set(key, entry);
        }
        for (const value of values) {
            entry.add(value);
        }
    }

    override delete(key: K, ...values: V[]) {
        if (values.length === 0) return super.delete(key);

        let deleted = false;
        const entry = super.get(key);
        if (entry) {
            for (const value of values) if (entry.delete(value)) deleted = true;
            if (entry.size === 0) super.delete(key);
        }
        return deleted;
    }
}

import alkoholfreie_getraenke from './alkoholfreie_getraenke.svg';
import andere from './andere.svg';
import auto_zubehoer_und_pflege from './auto_zubehoer_und_pflege.svg';
import babykost from './babykost.svg';
import bier from './bier.svg';
import blumen_pflanzen from './blumen_pflanzen.svg';
import blumenerde_torf from './blumenerde_torf.svg';
import bottle from './bottle.svg';
import brot_kuchen_sb from './brot_kuchen_sb.svg';
import brot_und_kuchen_in_bedienung from './brot_und_kuchen_in_bedienung.svg';
import buecher_zeitungen from './buecher_zeitungen.svg';
import camping_grillzubehoer from './camping_grillzubehoer.svg';
import crate from './crate.svg';
import diverse_nonfood from './diverse_nonfood.svg';
import drogerieartikel from './drogerieartikel.svg';
import elektro_gluehlampen from './elektro_gluehlampen.svg';
import fahrrad_zubehoer from './fahrrad_zubehoer.svg';
import feingebaeck from './feingebaeck.svg';
import feinkost from './feinkost.svg';
import fertiggerichte from './fertiggerichte.svg';
import fette_eier from './fette_eier.svg';
import fischkonserven from './fischkonserven.svg';
import foto from './foto.svg';
import frischepack_kaese from './frischepack_kaese.svg';
import frischepack_wurst from './frischepack_wurst.svg';
import frischfleisch from './frischfleisch.svg';
import gefluegel_wild_frisch from './gefluegel_wild_frisch.svg';
import gemuesekonserven from './gemuesekonserven.svg';
import geschenkartikel from './geschenkartikel.svg';
import glas_porzellan from './glas_porzellan.svg';
import hausbedarf_buersen_besen from './hausbedarf_buersen_besen.svg';
import heimwerkerbedarf from './heimwerkerbedarf.svg';
import holz_kleinmoebel from './holz_kleinmoebel.svg';
import hygiene from './hygiene.svg';
import kaeseshop from './kaeseshop.svg';
import kaffee_tee_kakao from './kaffee_tee_kakao.svg';
import konfituere_honig from './konfituere_honig.svg';
import kosmetik from './kosmetik.svg';
import kurzwaren_heimtextilien from './kurzwaren_heimtextilien.svg';
import lampen from './lampen.svg';
import lederwaren from './lederwaren.svg';
import leergut from './leergut.svg';
import leergut_zwangspfand from './leergut_zwangspfand.svg';
import margarine from './margarine.svg';
import mehl_salz_zucker from './mehl_salz_zucker.svg';
import milchkonserven from './milchkonserven.svg';
import molkereiprodukte from './molkereiprodukte.svg';
import naehrmittel from './naehrmittel.svg';
import oberbekleidung from './oberbekleidung.svg';
import obst_und_gemuese from './obst_und_gemuese.svg';
import obstkonserven from './obstkonserven.svg';
import others from './others.svg';
import papierwaren from './papierwaren.svg';
import partybedarf from './partybedarf.svg';
import plastik from './plastik.svg';
import porzellan_diverse_geschenke from './porzellan_diverse_geschenke.svg';
import pralinen from './pralinen.svg';
import reform_und_diaetkost from './reform_und_diaetkost.svg';
import reinigungsmittel from './reinigungsmittel.svg';
import rundfunk_fernsehen from './rundfunk_fernsehen.svg';
import saemereien from './saemereien.svg';
import salate_bedienung from './salate_bedienung.svg';
import salate_fisch_fk from './salate_fisch_fk.svg';
import sauerkonserven from './sauerkonserven.svg';
import sb_fleisch from './sb_fleisch.svg';
import schokolade from './schokolade.svg';
import schuhe from './schuhe.svg';
import seife from './seife.svg';
import speiseeis from './speiseeis.svg';
import speisezutaten from './speisezutaten.svg';
import spielwaren from './spielwaren.svg';
import spirituosen from './spirituosen.svg';
import sport_und_berufskleidung from './sport_und_berufskleidung.svg';
import spuelmittel from './spuelmittel.svg';
import strumpfhosen_feinstruempfe from './strumpfhosen_feinstruempfe.svg';
import suesswaren from './suesswaren.svg';
import tabakwaren from './tabakwaren.svg';
import teigwaren from './teigwaren.svg';
import tiefkuehlkost from './tiefkuehlkost.svg';
import tierbedarf from './tierbedarf.svg';
import tiernahrung from './tiernahrung.svg';
import tontraeger from './tontraeger.svg';
import waesche_struempfe from './waesche_struempfe.svg';
import waschmittel from './waschmittel.svg';
import wein_sekt from './wein_sekt.svg';
import wuerzmittel from './wuerzmittel.svg';
import wurst_in_selbstbedienung from './wurst_in_selbstbedienung.svg';
import wurst_und_fleischkonserven from './wurst_und_fleischkonserven.svg';
import wurstwaren_in_bedienung from './wurstwaren_in_bedienung.svg';
import zahnpflege from './zahnpflege.svg';
import zoo_zoozubehoer from './zoo_zoozubehoer.svg';

export default {
    alkoholfreie_getraenke,
    andere,
    auto_zubehoer_und_pflege,
    babykost,
    bier,
    blumenerde_torf,
    blumen_pflanzen,
    bottle,
    brot_kuchen_sb,
    brot_und_kuchen_in_bedienung,
    buecher_zeitungen,
    camping_grillzubehoer,
    crate,
    diverse_nonfood,
    drogerieartikel,
    elektro_gluehlampen,
    fahrrad_zubehoer,
    feingebaeck,
    feinkost,
    fertiggerichte,
    fette_eier,
    fischkonserven,
    foto,
    frischepack_kaese,
    frischepack_wurst,
    frischfleisch,
    gefluegel_wild_frisch,
    gemuesekonserven,
    geschenkartikel,
    glas_porzellan,
    hausbedarf_buersen_besen,
    heimwerkerbedarf,
    holz_kleinmoebel,
    hygiene,
    kaeseshop,
    kaffee_tee_kakao,
    konfituere_honig,
    kosmetik,
    kurzwaren_heimtextilien,
    lampen,
    lederwaren,
    leergut,
    leergut_zwangspfand,
    margarine,
    mehl_salz_zucker,
    milchkonserven,
    molkereiprodukte,
    naehrmittel,
    oberbekleidung,
    obstkonserven,
    obst_und_gemuese,
    others,
    papierwaren,
    partybedarf,
    plastik,
    porzellan_diverse_geschenke,
    pralinen,
    reform_und_diaetkost,
    reinigungsmittel,
    rundfunk_fernsehen,
    saemereien,
    salate_bedienung,
    salate_fisch_fk,
    sauerkonserven,
    sb_fleisch,
    schokolade,
    schuhe,
    seife,
    speiseeis,
    speisezutaten,
    spielwaren,
    spirituosen,
    sport_und_berufskleidung,
    spuelmittel,
    strumpfhosen_feinstruempfe,
    suesswaren,
    tabakwaren,
    teigwaren,
    tiefkuehlkost,
    tierbedarf,
    tiernahrung,
    tontraeger,
    waesche_struempfe,
    waschmittel,
    wein_sekt,
    wuerzmittel,
    wurst_in_selbstbedienung,
    wurst_und_fleischkonserven,
    wurstwaren_in_bedienung,
    zahnpflege,
    zoo_zoozubehoer,
};

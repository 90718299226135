import { service } from '@whiz-cart/node-shared/service/service';
import { urlService } from '@whiz-cart/ui-shared/url/url.service';
import _ from 'lodash';
import { Action } from 'schummar-state/react';
import { authService } from '../auth/auth.service';
import endpoint from '../util/endpoint';
import { StoreRoles, Subscription, TenantRoles, User } from './user';
import { Dict, t } from '@translate';
import { errorToString } from '@whiz-cart/node-shared/errorToString';
import { ReactNode } from 'react';
import { HookTranslator, InlineTranslator } from 'schummar-translate/react';
import { PagedRequest } from '@whiz-cart/node-shared/models/request/pagedRequest';

export type ScopeType = 'store' | 'tenant' | 'global';

export class AccountControllerError extends Error {
    contextValue;
    status;

    constructor(e: any) {
        super(errorToString(e));
        const { contextValue, status } = (e?.data as { accounts: any; contextValue: string; status: number }) ?? {};
        this.cause = e;
        this.name = 'AccountControllerError';
        this.contextValue = contextValue;
        this.status = status;
    }

    getTranslatedMessage(_t: HookTranslator<Dict>): string;
    getTranslatedMessage(): ReactNode;
    getTranslatedMessage(_t: HookTranslator<Dict> | InlineTranslator<Dict> = t) {
        return _t.unknown(`profile.errors.${this.status}`, { contextValue: this.contextValue }, { fallback: errorToString(this.cause) });
    }
}

const transformRoles = ({
    globalRoles,
    storeRoles,
    tenantRoles,
    ...user
}: {
    globalRoles?: any[];
    storeRoles?: StoreRoles[];
    tenantRoles?: TenantRoles[];
}) => {
    const roles: any = {};
    if (globalRoles && globalRoles?.length > 0) {
        roles['global'] = { scopeType: 'global', scope: 'global', roles: globalRoles };
    }
    if (storeRoles) {
        storeRoles.forEach((val: any) => {
            roles[val.storeGuid] = { scopeType: 'store', scope: val.storeGuid, roles: val.roles };
        });
    }
    if (tenantRoles) {
        tenantRoles.forEach((val: any) => {
            roles[val.tenant] = { scopeType: 'tenant', scope: val.tenant, roles: val.roles };
        });
    }
    return {
        ...user,
        scopeRoles: roles,
    } as User;
};

export default service(
    'userService',
    class UserService {
        async list(
            query: string,
            skip: number,
            limit: number,
            sort: Array<{ sortBy: string; order: number }>,
            filters: Array<{
                field: string;
                value: string;
            }>,
            storeGuid?: string,
        ) {
            try {
                const sortParams = sort.map((s) => `${s.sortBy},${s.order}`);
                let filterParams = filters?.length
                    ? filters.filter((f) => f.field !== 'storeNameQuery' && f.field !== 'roles').map((f) => `${f.field},${f.value}`)
                    : [];

                const storeNameQuery = filters?.find((f) => f.field === 'storeNameQuery')?.value;
                const roleFilter = filters?.find((f) => f.field === 'roles');

                if (roleFilter?.value?.length) {
                    filterParams = [
                        ...filterParams,
                        `[${['globalRoles', 'tenantRoles.roles', 'storeRoles.roles'].join(', ')}],
                         [${Array.isArray(roleFilter.value) ? roleFilter.value.join(', ') : roleFilter.value}]`,
                    ];
                }

                const params = {
                    query,
                    storeGuid,
                    sort: sortParams,
                    filter: filterParams,
                    storeNameQuery,
                };
                const response: PagedRequest = await endpoint('storeManager.listAccounts', { skip: `${skip}`, limit: `${limit}` }).get({
                    params,
                });
                const { totalItemCount, items } = response;
                return {
                    count: totalItemCount,
                    items: items.map(transformRoles),
                };
            } catch (e) {
                throw new AccountControllerError(e);
            }
        }

        user = new Action(async (accountGuid: string) => {
            try {
                const response = await endpoint('storeManager.getAccount', { accountGuid }).get();

                if (!response) {
                    return null;
                }

                const user = transformRoles(response as any);
                return User.parse(user);
            } catch (e) {
                throw new AccountControllerError(e);
            }
        });

        async create(user: any) {
            try {
                const response: any = await endpoint('storeManager.createAccount').post(user);
                this.user.setCache(response.accountGuid, transformRoles(response));
                return response;
            } catch (e) {
                throw new AccountControllerError(e);
            }
        }

        async updateRoles(accountGuid: string, scopeType: ScopeType, scope: string, roles: string[]) {
            try {
                switch (scopeType) {
                    case 'store':
                        return await endpoint('storeManager.updateAccountStoreRoles', { storeGuid: scope, accountGuid }).post(roles);
                    case 'tenant':
                        return await endpoint('storeManager.updateAccountTenantRoles', { tenant: scope, accountGuid }).post(roles);
                    case 'global':
                        return await endpoint('storeManager.updateAccountGlobalRoles', { accountGuid }).post(roles);
                }
            } catch (e) {
                throw new AccountControllerError(e);
            }
        }

        async update(accountGuid: string, update: any) {
            try {
                const response: any = await endpoint('storeManager.updateAccount', { accountGuid }).post(update);
                this.user.setCache(accountGuid, transformRoles(response));
                return response;
            } catch (e: any) {
                throw new AccountControllerError(e);
            }
        }

        async delete(accountGuid: string) {
            try {
                await endpoint('storeManager.deleteAccount', { accountGuid }).delete();
                this.user.clearCache(accountGuid);
            } catch (e: any) {
                throw new AccountControllerError(e);
            }
        }

        async resetPassword(accountGuid: string, password: string) {
            try {
                await endpoint('storeManager.resetPassword').post({ accountGuid, password });
            } catch (e: any) {
                throw new AccountControllerError(e);
            }
        }

        async activate({ token, userName, password, type }: { token: string; userName: string; password: string; type: string }) {
            const claims = await authService.getLoginClaims();
            const response: any = await endpoint('storeManager.setPasswordWithEmailToken').post(
                {
                    token,
                    userName,
                    password,
                    accountEmailType: type,
                    claims,
                },
                { accessToken: false },
            );
            await authService.acceptLogin(response);
            urlService.pushUrl('/', { token: undefined, type: undefined, userName: undefined });
        }

        async forgotPassword(email: string) {
            await endpoint('storeManager.forgotPassword').post({ email }, { accessToken: false });
            urlService.pushUrl('/');
        }

        async getSubscriptions(storeGuid: string) {
            return endpoint('storeManager.getSubscriptions', { storeGuid }).get();
        }

        subscriptions = new Action(async (storeGuid?: string) => {
            if (!storeGuid) {
                return [];
            }
            const result = await endpoint('storeManager.getSubscriptions', { storeGuid }).get();
            try {
                return Subscription.array().parse(result);
            } catch (error) {
                console.error('error', error);
                return [];
            }
        });
    },
);

import c from 'classnames';
import _ from 'lodash';
import objectHash from 'object-hash';
import React, { ReactNode, useEffect, useState } from 'react';
import css from './img.module.less';
import { ImgSrc, useImgSrc } from './imgSrc';
import { Resource, safeLoad } from './safeLoad';
import LoadingIndicator from '../loading/loadingIndicator';

interface ResourceRef {
    src: string;
    resource?: Resource;
    error?: any;
    loadTime?: number;
}

function updateArray(index: number, replacement: ResourceRef) {
    return (arr: ResourceRef[]) => [...arr.slice(0, index), replacement, ...arr.slice(index + 1)];
}

export interface ImgProps
    extends Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'onError' | 'src'> {
    children?: ReactNode;
    src: ImgSrc | ImgSrc[];
    retries?: number;
    errorFallback?: React.ReactNode;
    onSuccess?: (index: number, resource: Resource) => void;
    onError?: (index: number, error: any) => void;
    fadeIn?: boolean | boolean[];
    fallbackClassName?: string;
    indexClassName?: { [index: number]: string };
    alt?: string;
    showFallback?: boolean;
}

export function Img({
    src,
    retries,
    children,
    errorFallback,
    onSuccess,
    onError,
    fadeIn,
    fallbackClassName = 'fallbackClassName',
    indexClassName,
    alt,
    showFallback = false,
    ...htmlProps
}: ImgProps) {
    const resolveImgSrc = useImgSrc();
    const [resources, setResources] = useState<ResourceRef[]>([]);
    const srcArr = _.castArray(src).map(resolveImgSrc).filter(Boolean) as string[];

    useEffect(() => {
        setResources(srcArr.map((src) => ({ src })));

        const requests = srcArr.map((src, index) => {
            const start = Number(new Date());
            const request = safeLoad(src, retries);

            (async () => {
                try {
                    const resource = await request;
                    if (request.isCanceled) return;
                    setResources(updateArray(index, { src, resource, loadTime: Number(new Date()) - start }));
                    onSuccess?.(index, resource);
                } catch (error) {
                    if (request.isCanceled) return;
                    setResources(updateArray(index, { src, error, loadTime: Number(new Date()) - start }));
                    onError?.(index, error);
                }
            })();

            return request;
        });

        return () => requests.forEach((x) => x.cancel());
    }, [objectHash(srcArr)]);

    const index = resources.findIndex((x) => x.resource);
    const active = resources[index];
    const pending = resources.some((img) => !img.resource && !img.error);
    const shouldFadeIn = (typeof fadeIn === 'boolean' ? fadeIn : fadeIn?.[index]) && (active?.loadTime ?? 0) > 100;

    if (!active) {
        return (
            <div {...htmlProps} className={c(css.container, htmlProps.className)}>
                {children ||
                    (pending ? <LoadingIndicator noMargin size="1rem" data-testid="img-spinner" className={css.loading} /> : errorFallback)}
            </div>
        );
    }

    return (
        <img
            key={active.src}
            alt={alt}
            {...htmlProps}
            src={active.src}
            className={c(
                css.img,
                htmlProps.className,
                {
                    [css.fadeIn]: shouldFadeIn,
                    [fallbackClassName]: index > 0 || showFallback,
                },
                indexClassName?.[index],
            )}
        />
    );
}

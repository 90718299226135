import Bus, { BusDebug } from './bus';
import BaseModel, { isBaseModel } from '../baseModel';

export function bus_subscribe(
    this: Bus,
    topic: string,
    callback: (data: any, topic: string, baseModel?: BaseModel) => void,
    { correlationGuid, debug = this.options.debug }: { correlationGuid?: string; debug?: BusDebug } = {},
) {
    let handle: (() => void) | undefined;

    const _callback = (pkg: any, currentTopic = topic) => {
        if (correlationGuid && pkg.correlationGuid !== correlationGuid) return;
        if (!pkg) throw Error('Payload missing');

        if (isBaseModel(pkg)) {
            debug?.(`Received ${currentTopic}`, {
                ...pkg.data,
                get baseModel() {
                    return pkg;
                },
            });

            callback(pkg.data, currentTopic, pkg);
        } else {
            debug?.(`Received ${currentTopic}`, pkg);
            callback(pkg, currentTopic);
        }
    };

    const subscription = {
        start: () => {
            handle = this.channelIn?.subscribe(topic, _callback);
        },
        stop: () => {
            handle?.();
        },
    };

    subscription.start();
    this.subscriptions.add(subscription);

    return () => {
        subscription.stop();
        this.subscriptions.delete(subscription);
    };
}

import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export function useDebouncedValue<T>(value: T, ms = 1000) {
    const [debounced, setDebounced] = useState(value);
    const update = useCallback(debounce(setDebounced, ms), [ms]);

    useEffect(() => update(value), [value]);
    useEffect(() => () => update.cancel(), []);
    return debounced;
}

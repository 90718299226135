import { z } from 'zod';
import { MilestoneField } from './milestoneField';
import { ParsedDate } from '../parsed/parsedDate';

export type MilestoneStatus = z.infer<typeof MilestoneStatus>;
export type MilestoneRunType = z.infer<typeof MilestoneRunType>;

export type MilestoneHistoryEntry = z.infer<typeof MilestoneHistoryEntry>;
export type MilestoneBase = z.infer<typeof MilestoneBase>;
export type MilestoneTypeAction = z.infer<typeof MilestoneTypeAction>;
export type MilestoneTypeInput = z.infer<typeof MilestoneTypeInput>;
export type MilestoneTypeCheck = z.infer<typeof MilestoneTypeCheck>;
export type Milestone = z.infer<typeof Milestone>;
export type MilestoneSlim = z.infer<typeof MilestoneSlim>;

export const MilestoneStatus = z.enum(['ok', 'notOk', 'running', 'scheduled', 'unknown']);

export const MilestoneRunType = z.enum(['auto', 'dependencyChanged', 'manual']);

export const MilestoneHistoryEntry = z.object({
    guid: z.string(),
    status: MilestoneStatus,
    ts: ParsedDate.nullish(),
    runType: MilestoneRunType.nullish(),
    changedBy: z.string().nullish(),
    message: z.string().nullish(),
    metadata: z.record(z.unknown()).nullish(),
    error: z.string().nullish(),
});

export const MilestoneBase = z.object({
    storeGuid: z.string(),
    milestoneId: z.string(),
    milestoneGroup: z.string(),

    createdOn: z.string(),
    modifiedOn: z.string(),

    status: MilestoneStatus,
    history: z.array(MilestoneHistoryEntry),
    index: z.number(),

    dependencies: z.array(z.string()).nullish(),
    createdBy: z.string().nullish(),
    modifiedBy: z.string().nullish(),
    completedOn: z.string().nullish(),
    completedBy: z.string().nullish(),
    daysBeforeGolive: z.number().nullish(),
});

export const MilestoneTypeAction = MilestoneBase.extend({
    type: z.literal('action'),
});

export const MilestoneTypeCheck = MilestoneBase.extend({
    type: z.literal('check'),
});

export const MilestoneTypeInput = MilestoneBase.extend({
    type: z.literal('input'),
    fields: z.array(MilestoneField),
    errors: z.array(z.object({ field: z.string(), message: z.string() })).nullish(),
});

export const Milestone = z.discriminatedUnion('type', [MilestoneTypeAction, MilestoneTypeCheck, MilestoneTypeInput]);

export const MilestoneSlim = z.object({
    storeGuid: z.string(),
    milestoneId: z.string(),
    createdOn: z.string(),
    modifiedOn: z.string(),
    status: MilestoneStatus,

    createdBy: z.string().nullish(),
    modifiedBy: z.string().nullish(),
    completedOn: z.string().nullish(),
    completedBy: z.string().nullish(),
});

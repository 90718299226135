import React, { useState, useRef, useEffect } from 'react';
import c, { Argument } from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './button.module.less';
import LoadingIndicator from '../loading/loadingIndicator';
import _ from 'lodash';

export type ButtonProps = {
    primary?: boolean;
    danger?: boolean;
    light?: boolean;
    big?: boolean;
    inactive?: boolean;
    left?: boolean;
    progress?: number;
    spinner?: boolean;
    icon?: { iconName: any } | React.ReactNode;
    centerIcon?: boolean;
    rounded?: boolean;
    action?: () => void | Promise<void>;
    'data-testid'?: string;
    classNames?: Record<string, Argument>;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

const toElement = (x: any, def?: React.ReactNode) => {
    if (x === undefined) return;
    if (typeof x === 'function') x = x();
    if (x === true) x = def;
    else if (x.iconName) x = <FontAwesomeIcon fixedWidth icon={x} />;
    return x;
};

export function Button({
    primary,
    danger,
    light,
    big,
    inactive,
    left,
    className,
    classNames = {},
    children,
    progress,
    spinner,
    icon,
    centerIcon,
    rounded,
    action,
    'data-testid': testId,
    ...otherProps
}: ButtonProps) {
    const isMounted = useRef(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const _css = _.mergeWith({}, css, classNames, (a, b) => c(a, b));
    _css.item = c(_css.item, className);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const onClick = !action
        ? undefined
        : async () => {
              try {
                  setIsProcessing(true);
                  await action();
              } finally {
                  if (isMounted.current) setIsProcessing(false);
              }
          };

    const _className = c(
        primary ? _css.primary : light ? _css.light : _css.secondary,
        {
            [_css.danger]: danger,
            [_css.big]: big,
            [_css.inactive]: inactive,
            [_css.processing]: isProcessing,
            [_css.left]: left,
        },
        _css.item,
    );
    const _icon =
        toElement(spinner ?? isProcessing, <LoadingIndicator size={'1rem'} className={_css.loading} center={false} noMargin />) ||
        toElement(icon);

    return (
        <div
            data-testid={testId}
            data-primary={primary === true ? 'true' : 'false'}
            className={c(_className, {
                [_css.hasIcon]: _icon,
                [_css.hasContent]: children,
                [_css.centerIcon]: centerIcon,
                [_css.rounded]: rounded,
            })}
            onClick={onClick}
            {...otherProps}
        >
            {progress !== undefined && <div className={_css.progress} style={{ width: `${progress * 100}%` }} />}
            {_icon && <div className={_css.icon}>{_icon}</div>}
            {children && <div className={_css.content}>{children}</div>}
        </div>
    );
}

import { get, orderBy, set } from 'lodash';

import sleep from '@whiz-cart/node-shared/sleep';
import Queue from '@whiz-cart/node-shared/queue';

export const SYNC_UPDATE_ITEM = 'SYNC_UPDATE_ITEM';
export const SYNC_ADD_TO_QUEUE = 'SYNC_ADD_TO_QUEUE';
export const SYNC_REMOVE_FROM_QUEUE = 'SYNC_REMOVE_FROM_QUEUE';
export const SYNC_CLEAR = 'SYNC_CLEAR';
export const SYNC_INIT = 'SYNC_INIT';

export class Sync {
    // Pubic methods
    constructor({
        name, // Name of this sync instance, must be the same as the reducer's
        connection, // Connection object with functions: on(messageHandler, reconnectHandler), off(), get(), update(item)
        dispatch,
        deviceId,
        id = 'id', // id property name
        updatedBy = 'updatedBy', // updatedBy property name
        updatedOn = 'updatedOn', // updatedOn property name
        merge = (local) => local, // Merge incoming update, if it would otherwise be ignored?
    }) {
        if (typeof name !== 'string' || typeof connection !== 'object' || typeof dispatch !== 'function') {
            throw Error('Missing/wrong params!');
        }

        Object.assign(this, {
            name,
            connection,
            dispatch,
            deviceId: typeof deviceId === 'function' ? deviceId : () => deviceId,
            id,
            updatedBy,
            updatedOn,
            merge,
        });

        connection.on(
            (item) => dispatch(this.receiveItem(item)),
            () => dispatch(this.restore()),
        );
        dispatch(this.init());
    }

    // Public

    /** Update an item locally. It will be put into redux state immediately and queued for delivery also. */
    updateItem(item) {
        return (dispatch, getState) => {
            if (this.isShutDown) throw Error(`Sync ${this.name} has been shutdown!`);

            const state = getState()[this.name];
            const isQueueEmpty = state.queue.length === 0;

            item = { ...item };
            set(item, this.updatedBy, undefined);
            dispatch({ type: SYNC_UPDATE_ITEM, name: this.name, payload: { id: get(item, this.id), item } });
            dispatch({ type: SYNC_ADD_TO_QUEUE, name: this.name, payload: item });
            if (isQueueEmpty) dispatch(this.pushQueue());
            console.debug('Updated item locally:', item);
        };
    }

    /** Remove all data. */
    clear() {
        this.dispatch({ type: SYNC_CLEAR, name: this.name });
        console.debug('Cleared sync data.');
    }

    /** Remove all data and load new from remote */
    reset() {
        this.dispatch({ type: SYNC_CLEAR, name: this.name });
        this.dispatch(this.restore());
        console.debug('Cleared sync data. Reloading...');
    }

    /** Stop receiving updates. Neither locally nor from remote. */
    shutdown() {
        this.isShutDown = true;
        this.connection.off();
        console.debug('Shut down sync.');
    }

    // Private

    init() {
        return async (dispatch, getState) => {
            while (!getState()[this.name]._persist?.rehydrated) await sleep(1000);
            dispatch(this.restore());
            dispatch(this.pushQueue());
        };
    }

    receiveItem(incoming) {
        return (dispatch, getState) => {
            if (this.isShutDown) return;

            const state = getState()[this.name];
            const local = state.items[get(incoming, this.id)];

            const isEcho = local && !get(local, this.updatedBy) && get(incoming, this.updatedBy) === this.deviceId();
            const isNewer = !get(local, this.updatedOn) || new Date(get(local, this.updatedOn)) < new Date(get(incoming, this.updatedOn));

            let newItem;
            let action;
            if (isNewer && !isEcho) {
                newItem = incoming;
                action = 'Applied';
            } else if (isNewer) {
                newItem = { ...this.merge(local, incoming) };
                set(newItem, this.updatedOn, incoming.updatedOn);
                action = 'Merged';
            } else action = 'Ignored';

            if (newItem) {
                this.dispatch({
                    type: SYNC_UPDATE_ITEM,
                    name: this.name,
                    payload: { id: get(incoming, this.id), updatedOn: get(incoming, this.updatedOn), item: newItem },
                });
            }

            console.debug(`${action} incoming item (isNewer=${isNewer}, isEcho=${isEcho}):`, {
                local,
                incoming,
            });
        };
    }

    pushQueue() {
        return async (dispatch, getState) => {
            const item = getState()[this.name].queue[0];
            if (item) {
                try {
                    await this.connection.update(item);
                } catch (error) {
                    console.error('Error when pushing queue item', error);
                    if (!error.response || error.response.status === 500) {
                        await sleep(1000);
                        return dispatch(this.pushQueue());
                    }
                }
                dispatch({ type: SYNC_REMOVE_FROM_QUEUE, name: this.name });
                dispatch(this.pushQueue());
            }
        };
    }

    restore() {
        return async (dispatch, getState) => {
            const { updatedOn } = getState()[this.name];
            try {
                const items = orderBy(await this.connection.get(updatedOn), (item) => get(item, this.updatedOn));
                const q = new Queue();
                items.forEach((item) => {
                    q.schedule(() => dispatch(this.receiveItem(item)));
                });
                await q.last;
                dispatch({ type: SYNC_INIT, name: this.name });
            } catch (error) {
                console.error('Could not fetch updates', error);
            }
        };
    }
}

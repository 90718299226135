import genGuid from './guid';
import _ from 'lodash';

export default interface BaseModel<T = any> {
    guid: string;
    correlationGuid: string;
    ts: string;

    data?: T;
    error?: any;
    properties?: { [key: string]: any };

    isChunked?: boolean;
    chunk?: number;
    chunksLength?: number;
    isLastChunk?: boolean;
}

export function isBaseModel(obj: any): obj is BaseModel {
    return typeof obj.guid === 'string' && typeof obj.correlationGuid === 'string' && typeof obj.ts === 'string';
}

export const relatedModel = (
    { correlationGuid, properties }: { correlationGuid: string; properties: { [key: string]: any } },
    data: any,
) => ({
    guid: genGuid(),
    ts: new Date().toISOString(),
    correlationGuid: correlationGuid,
    data,
    properties: _.omit(properties, 'queueHops'),
});
